import {Requests as req} from '../../utils/requests';
import { Constants } from '../../config/constants';
import { Alert } from '../../utils/alert';
import { Auth } from '../../utils/auth';
import history from '../../utils/history';

const login = (data) => {
    return async dispatch => {
        req.postRequest({ url: Constants.AUTH + 'login', auth: 'basic', body: data}, (cb) => {
            Auth.setCookies(
                Constants.STORAGE_ACCESS_TOKEN,
                cb.access, (callback) => Auth.setCookies(
                    Constants.STORAGE_REFRESH_TOKEN,
                    cb.refresh, (callback) => Auth.setCookies(
                        Constants.STORAGE_USER_INFO,
                        cb.user, (callback) => {
                            setTimeout(() => {
                                history.push('#/dashboard');
                                window.location.reload(false);
                            }, 1000);
                        }
                    )
                )
            )
            Auth.setCookies(
                Constants.STORAGE_ACCESS_TOKEN,
                cb.access, (callback) => Auth.setCookies(
                    Constants.STORAGE_REFRESH_TOKEN,
                    cb.refresh, (callback) => Auth.setCookies(
                        Constants.STORAGE_USER_INFO,
                        cb.user, (callback) => Auth.setCookies(
                            Constants.STORAGE_USER_SCOPES,
                            cb.scopes, (callback) => {
                                setTimeout(() => {
                                    history.push('#/dashboard');
                                    window.location.reload(false);
                                }, 1500);
                            }
                        )
                    )
                )
            )
        })
    };
};

const logOut = () => {
    return async dispatch => {
        try {
            Alert.info({title: 'Logging out..'});
            req.postRequest({ url: Constants.AUTH + 'logout', auth: 'basic', body: {accessToken: (Auth.getAccessToken()).token}}, (cb) => {
                Auth.removeCookies(
                    Constants.STORAGE_ACCESS_TOKEN, (callback) => Auth.removeCookies(
                        Constants.STORAGE_REFRESH_TOKEN, (callback) => Auth.removeCookies(
                            Constants.STORAGE_USER_INFO, (callback) => {
                                setTimeout(() => {
                                    window.location.reload(false);
                                }, 1500);
                            }
                        )
                    )
                )
            });
        } catch (err) {
            console.log(err)
        }
    };
};

const changePassword = (data, action) => {
    return async dispatch => {
        await req.putRequest({
            url: `${Constants.AUTH}change-password`,
            auth: 'bearer',
            body: data
        }, async (cb) => {
            if (action) action()
        })
    };
};

const renew = (action) => {
    return async dispatch => {
        req.postRequest({ url: Constants.AUTH + 'renew', auth: 'basic', body: {refresh: (Auth.getRefreshToken()).token}}, (cb) => {
            Auth.setCookies(
                Constants.STORAGE_ACCESS_TOKEN,
                cb.access, (callback) => Auth.setCookies(
                    Constants.STORAGE_REFRESH_TOKEN,
                    cb.refresh, (callback) => Auth.setCookies(
                        Constants.STORAGE_USER_INFO,
                        cb.user, (callback) => action()
                    )
                )
            )
        })
    };
};

export { login, logOut, changePassword, renew };
