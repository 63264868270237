const actions = {
    FETCH_ROLES_PERMISSIONS : 'FETCH_ROLES_PERMISSIONS',
    RESET_ROLES_PERMISSIONS : 'RESET_ROLES_PERMISSIONS',

    fetchRolesPermissionsAction: (data) => {
        return {
            type: actions.FETCH_ROLES_PERMISSIONS,
            data: data
        };
    },
    resetRolesPermissionsAction: () => {
        return {
            type: actions.FETCH_ROLES_PERMISSIONS,
            data: null
        };
    },
};

export default actions;
