import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../components/layout/Section";
import RepresentativeList from "./list";
import {fetchEmailLogs, fetchCancelEmailLogs} from "../../../redux/patient-management/representatives/actionCreator";

const EmailLogsData = () => {
    const emailLogs = useSelector(state => state.srSupportive.logs);
    const cancelLogs = useSelector(state => state.srSupportive.cancelLogs);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchEmailLogs());
            await dispatch(fetchCancelEmailLogs());
        };
        fetchData().then(r => {
        });
    }, []);

    return (
        <Section>
            <RepresentativeList
                state={state}
                setState={setState}
                isLoading={'isLoading'}
                emailLogs={emailLogs}
                cancelLogs={cancelLogs}
            />
        </Section>
    )
}

export default EmailLogsData;