import utilitiesReducer from './utilities/reducers';
import patientReducer from "./patient-management/patients/reducers";
import userReducer from "./userManagement/users/reducers";
import roleReducer from "./userManagement/roles/reducers";
import facilityReducer from "./patient-management/facilities/reducers";
import procedureReducer from "./patient-management/procedures/reducers";
import scheduleReducer from "./schedule-management/schedules/reducers";
import surgeonReducer from "./patient-management/surgeons/reducers";
import representativeReducer from "./patient-management/representatives/reducers";
import ActivityReducer from "./activity/reducers";
import ServiceReducer from "./service/reducers";

import {combineReducers} from "redux"

const rootReducer = combineReducers({
    utilities: utilitiesReducer,
    pmPatents: patientReducer,
    umUsers: userReducer,
    umRoles: roleReducer,
    pmFacilities: facilityReducer,
    pmSurgeons: surgeonReducer,
    pmProcedures: procedureReducer,
    smSchedules: scheduleReducer,
    srSupportive: representativeReducer,
    pmActivities: ActivityReducer,
    pmServices: ServiceReducer,
});

export default rootReducer;