import React, {useState} from 'react';
import {Button, Modal, Input, Col, Dropdown, Layout, Menu, Row} from 'antd';
import {UserOutlined, LogoutOutlined, RadarChartOutlined} from '@ant-design/icons';
import {useDispatch} from "react-redux";
import {logOut, changePassword} from "../../redux/authentication/actionCreator";
import {Auth} from "../../utils/auth";
import {Link} from "react-router-dom";
import {Form} from 'antd';
const {Header: HeaderSec} = Layout;

const Header = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({ visible: false, modalType: 'primary', colorModal: false });
    const [form] = Form.useForm();
    const userInfo = Auth.getUserInfo()

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    function handleMenuClick(e) {
        dispatch(logOut())
    }

    const inactivityTime = function () {
        let time;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;

        function log() {
            dispatch(logOut())
            //location.href = 'logout.html'
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(log, 1800000)
            // 1000 milliseconds = 1 second
        }
    };

    window.onload = function() {
        inactivityTime();
    }

    const handleSubmit = async (data) => {
        if (userInfo._id) {
            const postData = {currentPassword: data.currentPassword, password: data.password, _id: userInfo._id}
            await dispatch(changePassword(postData, handleCancel()))
        }
    };

    const showModal = async type => {
        form.resetFields();
        setState({
            visible: true,
            modalType: type,
        });
    };

    const handleCancel = () => {
        form.resetFields();
        setState({
            visible: false,
            colorModal: false,
        });
    };

    const validateMessages = {
        required: '${label} is required!',
        lenCheck: '${label} ${min} num is required!',
        types: {
            email: '${label} is not validate email!',
            number: '${label} is not a validate number!',
        },
        number: {
            length: '${label} must be ${min} number',
        },
    };

    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<LogoutOutlined/>}>
                <Link to="#"onClick={handleMenuClick}>
                   Logout
                </Link>
            </Menu.Item>
            <Menu.Item key="2"  icon={<RadarChartOutlined/>}>
                <Link to="#" onClick={() => showModal('primary')}>
                    Change Password
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
            <HeaderSec className="site-layout-background" >
                <Row style={{float: 'right', paddingRight: '72px'}} >
                    <Col span={12}>
                        <Button style={{width: "50px", height: "50px"}} className="btn-rtl">
                            {/*<small><b> LVNI <br/> Server </b></small>*/}
                            {/*<small><b> Demo <br/> Server </b></small>*/}
                        </Button>
                    </Col>
                    <Col span={4} offset={8}>
                        <Dropdown.Button overlay={menu} icon={<UserOutlined/>}>
                            {userInfo?.username? userInfo.username : ""}
                        </Dropdown.Button>
                    </Col>
                </Row>
                <Col md={12}>
                    <Modal
                        type={state.modalType}
                        title="Password Form"
                        visible={state.visible}
                        onCancel={() => handleCancel()}
                        footer={[
                            <Button
                                form="passForm"
                                key="submit"
                                htmlType="submit"
                                type="primary"
                                size="medium">
                                Submit
                            </Button>
                        ]}
                    >
                        <Form
                            {...layout}
                            name="Change Password"
                            form={form}
                            id="passForm"
                            validateMessages={validateMessages}
                            onFinish={handleSubmit}>
                            <Col md={22}>
                                <br/>
                                <Form.Item
                                    name="currentPassword"
                                    rules={[{ required: true }]}
                                    label="Current Password">
                                    <Input.Password placeholder="Current Password"/>
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true }]}
                                    label="New Password">
                                    <Input.Password placeholder="New Password"/>
                                </Form.Item>
                            </Col>
                        </Form>
                    </Modal>
                </Col>
            </HeaderSec>
    )
}

export default Header;