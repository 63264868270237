import React, { useState, useRef } from 'react';
import {useDispatch} from "react-redux";
import { PlusOutlined } from '@ant-design/icons';
import {Button, Col, Form, Input, Modal, Row, Select, Divider, Space} from "antd";
import {Constants} from "../../../config/constants";

const { Option } = Select;
let index = 0;

const ProcedureForm = ({form, state, procedure, addProcedure, updateProcedure, isLoading, handleOk}) => {
    const dispatch = useDispatch()
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}}
    const validateMessages = {
        required: '${label} is required!',
    };

    const [items, setItems] = useState([
        'ACDF',
        'ARTHROPLASTY_(ARTIFICIAL_DISC TDR)',
        'BRAIN_BX',
        'CARP',
        'CARPAL_TUNNEL_RELEASE',
        'CHIARI_MALFORMATION',
        'CORPECTOMY',
        'CRANIOTOMY',
        'CRANI',
        'DBS',
        'DEEP_BRAIN_STIMULATOR',
        'DISC',
        'KYPHO',
        'LUMBAR_DISCECTOMY',
        'LAMINECTOMY_LAMINOTOMY',
        'LP',
        'NEUROFORAM',
        'OCCIPITAL',
        'PITUITARY',
        'PLIF',
        'POST_CERV_FUSION',
        'PUMP',
        'REMOVAL',
        'SPINAL_CORD_STIMULATOR',
        'SURAL_NERVE_BIOPSY',
        'TLIF',
        'TRIGEM',
        'VATS_EDU',
        'VNS',
        'VP',
        'WOUND',
        'XLIF_ALIF'
    ]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const handleSubmit = async (data) => {
        if (procedure?.Procedure?._id) {
            Object.assign(data, {_id: procedure.Procedure._id});
            await dispatch(updateProcedure(data, handleOk, form.setFields))
        } else
            await dispatch(addProcedure(data, handleOk, form.setFields));
    };

    const footerButtons = [
        <Button
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="small">
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];
    return (
        <>
            <Row md={12}>
                <Modal
                    type={state.modalType}
                    title="Procedure Form"
                    visible={state.visible}
                    onCancel={() => handleOk()}
                    footer={footerButtons}
                    width={1200}
                >
                    <Form
                        {...layout}
                        name={'procedureForm'}
                        form={form}
                        id={'myForm'}
                        validateMessages={validateMessages}
                        onFinish={handleSubmit}>
                        <Row md={22}>
                            <Col md={11}>
                                <br/>
                                <Form.Item
                                    name="surgery"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.surgery ? procedure.Procedure.surgery : null}
                                    label="Surgery">
                                    <Input placeholder="surgery"/>
                                </Form.Item>
                                <Form.Item
                                    name="patientPackets"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.patientPackets ? procedure.Procedure.patientPackets : null}
                                    label="PATIENT_PACKETS"
                                >
                                    <Select
                                        placeholder="PATIENT_PACKETS"
                                        // dropdownRender={(menu) => (
                                        //     <>
                                        //         {menu}
                                        //         <Divider
                                        //             style={{
                                        //                 margin: '8px 0',
                                        //             }}
                                        //         />
                                        //         <Space
                                        //             style={{
                                        //                 padding: '0 8px 4px',
                                        //             }}
                                        //         >
                                        //             <Input
                                        //                 placeholder="Please enter item"
                                        //                 ref={inputRef}
                                        //                 value={name}
                                        //                 onChange={onNameChange}
                                        //             />
                                        //             <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                        //                 Add item
                                        //             </Button>
                                        //         </Space>
                                        //     </>
                                        // )}
                                    >
                                        {items.map((item) => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="icd10Codes"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.icd10Codes ? procedure.Procedure.icd10Codes : []}
                                    label="ICD_10_Codes"
                                >
                                    <Select mode="tags">
                                        {Constants.ICD_10_CODES.map((code, ci) => (
                                            <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="cptCodes"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.cptCodes ? procedure.Procedure.cptCodes : []}
                                    label="CPT Codes"
                                >
                                    <Select mode="tags">
                                        {Constants.CPT_CODES.map((code, ci) => (
                                            <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="caseTimeHours"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.caseTimeHours ? procedure.Procedure.caseTimeHours : null}
                                    label="Case Time Hours">
                                    <Input placeholder="Case Time Hours"/>
                                </Form.Item>
                                <Form.Item
                                    name="inOut"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.inOut ? procedure.Procedure.inOut : ""}
                                    label="In / Out"
                                >
                                    <Select>
                                        <Select.Option value="">Select</Select.Option>
                                        <Select.Option value="In">In</Select.Option>
                                        <Select.Option value="Out">Out</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="diagnosis"
                                    rules={[{required: false}]}
                                    initialValue={procedure?.Procedure?.diagnosis ? procedure.Procedure.diagnosis : null}
                                    label="Diagnosis">
                                    <Input placeholder="Diagnosis"/>
                                </Form.Item>
                                <Form.Item
                                    name="preopImaging"
                                    rules={[{required: true}]}
                                    initialValue={procedure?.Procedure?.preopImaging ? procedure.Procedure.preopImaging : []}
                                    label="Preop Imaging"
                                >
                                    <Select mode="tags">
                                        {Constants.PREP_IMAGING.map((code, ci) => (
                                            <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="ImagingNeededPriorToSurgery"
                                    initialValue={!!procedure?.Procedure?.ImagingNeededPriorToSurgery}
                                    label="Imaging Needed"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="dbsRep"
                                    initialValue={!!procedure?.Procedure?.dbsRep}
                                    label="DBS Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="scsOnRep"
                                    initialValue={!!procedure?.Procedure?.scsOnRep}
                                    label="SCS on Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="gliadelRep"
                                    initialValue={!!procedure?.Procedure?.gliadelRep}
                                    label="Gliadel Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="shuntRep"
                                    initialValue={!!procedure?.Procedure?.shuntRep}
                                    label="Shunt Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="neuroMonitoringRep"
                                    initialValue={!!procedure?.Procedure?.neuroMonitoringRep}
                                    label="Neuro Monitoring Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={11}>
                                <br/>
                                <Form.Item
                                    name="neuroNavigationRep"
                                    initialValue={!!procedure?.Procedure?.neuroNavigationRep}
                                    label="Neuro Navigation Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="spineInstrumentRep"
                                    initialValue={!!procedure?.Procedure?.spineInstrumentRep}
                                    label="Spine Instrument Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="operatingMicroscope"
                                    initialValue={!!procedure?.Procedure?.operatingMicroscope}
                                    label="Operating Microscope"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="fluoroscopy"
                                    initialValue={!!procedure?.Procedure?.fluoroscopy}
                                    label="Fluoroscopy"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="ultrasound"
                                    initialValue={!!procedure?.Procedure?.ultrasound}
                                    label="Ultrasound"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="ultrasonicAspirator"
                                    initialValue={!!procedure?.Procedure?.ultrasonicAspirator}
                                    label="Ultrasonic Aspirator"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="braceRep"
                                    initialValue={!!procedure?.Procedure?.braceRep}
                                    label="Brace Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="boneGrowthSimRep"
                                    initialValue={!!procedure?.Procedure?.boneGrowthSimRep}
                                    label="Bone Growth Sim Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="postOpMedsRep"
                                    initialValue={!!procedure?.Procedure?.postOpMedsRep}
                                    label="Post Op Meds Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="urineDrugTestingRep"
                                    initialValue={!!procedure?.Procedure?.urineDrugTestingRep}
                                    label="Urine Drug Testing Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="homesHealthRep"
                                    initialValue={!!procedure?.Procedure?.homesHealthRep}
                                    label="Homes Health Rep"
                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="coSurgeon"
                                    initialValue={!!procedure?.Procedure?.coSurgeon}
                                    label="Co Surgeon Rep"

                                >
                                    <Select>
                                        <Select.Option value={true}><span className="color-the-success">Yes</span></Select.Option>
                                        <Select.Option value={false}>No</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Row>
        </>
    )
}

export default ProcedureForm;
