import actions from "./actions";

const {
    FETCH_PATIENTS,
    FETCH_PATIENT,
    CHECK_PATIENT,
    RESET_PATIENT,
    FETCH_STATES,
    FETCH_CITIES,
    FETCH_ZIPCODES,
    RESET_CODES,
    FETCH_INSURED_CITIES,
    FETCH_INSURED_ZIPCODES,
} = actions;

const initState = {
    patients: [],
    patient: {
        firstName: null,
        lastName: null,
        phone: null,
        insurance: null
    },
    checkPatient: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
        dob: null,
        gender: null,
        ethnicity: null,
        emergencyContactName: null,
        emergencyContactPhone: null,
        state: null,
        city: null,
        zip: null,
        address: null,
        referredBy: null,
        contactPreferences: null,
        patientMaritalStatus: null,
        patientEmploymentStatus: null,
        patientJobTitle: null,
        patientEmployer: null,
    },
    states: [],
    cities: [],
    zipCodes: {
        abbreviation: null,
        city: null,
        state: null,
        zip: null,
    },
    insuredCities: [],
    insuredZipCodes: {
        abbreviation: null,
        city: null,
        state: null,
        zip: null,
    }
};

const PatientReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PATIENTS:
            return {
                ...state,
                patients: data
            };
        case RESET_PATIENT:
            return {
                ...state,
                patient: initState.patient,
                checkPatient: initState.checkPatient,
            };
        case FETCH_PATIENT:
            return {
                ...state,
                patient: data,
            };
        case CHECK_PATIENT:
            return {
                ...state,
                checkPatient: data,
            };
        case FETCH_STATES:
            return {
                ...state,
                states: data
            };
        case FETCH_CITIES:
            return {
                ...state,
                cities: data
            };
        case FETCH_ZIPCODES:
            return {
                ...state,
                zipCodes: data
            };
        case FETCH_INSURED_CITIES:
            return {
                ...state,
                insuredCities: data
            };
        case FETCH_INSURED_ZIPCODES:
            return {
                ...state,
                insuredZipCodes: data
            };
        case RESET_CODES:
            return {
                ...state,
                insuredZipCodes: initState.zipCodes,
            };
        default:
            return state;
    }
}

export default PatientReducer;
