import { Constants } from "../../config/constants";
import { Requests as req } from "../../utils/requests";
import actions from './actions';

const { fetchActivitiesAction, resetActivityFormAction, fetchActivityAction } = actions;

export const fetchActivities = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.ACTIVITIES,
            auth: 'bearer',
            queries
        }, (cb) => dispatch(fetchActivitiesAction(cb)))
    };
};

export const fetchActivity = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.ACTIVITY,
            auth: 'bearer',
            queries
        }, (cb) => dispatch(fetchActivityAction(cb)))
    };
};

export const resetActivityForm = () => dispatch => dispatch(resetActivityFormAction());

