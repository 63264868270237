import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Modal, Select} from "antd";

const FacilityForm = ({form, state, facility, addFacility, updateFacility, isLoading, handleOk}) => {
    const dispatch = useDispatch()
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}}

    const validateMessages = {
        required: '${label} is required!',
    };

    const handleSubmit = async (data) => {
        if (facility?._id) {
            Object.assign(data, {_id: facility._id});
            await dispatch(updateFacility(data, handleOk, form.setFields))
        } else
            await dispatch(addFacility(data, handleOk, form.setFields));
    };

    const footerButtons = [
        <Button
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="small">
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];

    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Facility Form"
                visible={state.visible}
                onCancel={() => handleOk()}
                footer={footerButtons}
            >
                <Form
                    {...layout}
                    name={'facilityForm'}
                    form={form}
                    id={'myForm'}
                    validateMessages={validateMessages}
                    onFinish={handleSubmit}>
                    <Col md={22}>
                        <br/>
                        <Form.Item
                            name="name"
                            rules={[{required: true}]}
                            initialValue={facility?.name ? facility.name : null}
                            label="Facility Name">
                            <Input placeholder="name"/>
                        </Form.Item>
                        <Form.Item
                            name="location"
                            initialValue={facility?.location ? facility.location : null}
                            rules={[{required: true}]}
                            label="Location">
                            <Input placeholder="Location"/>
                        </Form.Item>
                        <Form.Item
                            name="description"
                            initialValue={facility?.description ? facility.description : null}
                            rules={[{required: true}]}
                            label="Description">
                            <Input.TextArea placeholder="Description"/>
                        </Form.Item>
                        <Form.Item
                            name="code"
                            rules={[{required: true}]}
                            initialValue={facility?.code ? facility.code : null}
                            label="Order-From"
                        >
                            <Select>
                                <Select.Option value="">Select Order-Form</Select.Option>
                                <Select.Option value="MVH">Mountain View Hospital</Select.Option>
                                <Select.Option value="NVH">North Vista Hospital</Select.Option>
                                <Select.Option value="CH">Centennial Hills</Select.Option>
                                <Select.Option value="DS">Desert Springs</Select.Option>
                                <Select.Option value="HEN">Henderson</Select.Option>
                                <Select.Option value="SV">Spring Valley</Select.Option>
                                <Select.Option value="SUM">Summerlin</Select.Option>
                                <Select.Option value="VHSH">Valley Health </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            initialValue={facility?.status ? facility.status : ''}
                            rules={[{required: true}]}
                            label="Status">
                            <Select>
                                <Select.Option value="">Status</Select.Option>
                                <Select.Option value="active">Active</Select.Option>
                                <Select.Option value="inactive">Inactive</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="label"
                            rules={[{required: true}]}
                            initialValue={facility?.label ? facility.label : ''}
                            label="Label"
                        >
                            <Select>
                                <Select.Option value="">Select Label</Select.Option>
                                <Select.Option value="red">Red</Select.Option>
                                <Select.Option value="indigo">Indigo</Select.Option>
                                <Select.Option value="green">Green</Select.Option>
                                <Select.Option value="blue">Blue</Select.Option>
                                <Select.Option value="gray">Gray</Select.Option>
                                <Select.Option value="purple">Purple</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </Modal>

        </Col>

    )
}

export default FacilityForm;