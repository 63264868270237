import actions from "./actions";

const {FETCH_SURGEONS, FETCH_SURGEON, RESET_SURGEON} = actions;
const initState = {
    surgeons: [],
    surgeon: {
        _id: null,
        name: null,
        status: null,
        description: null
    }
};

const SurgeonReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SURGEONS:
            return {
                ...state,
                surgeons: data
            }
        case RESET_SURGEON:
            return {
                ...state,
                surgeon: initState.surgeon,
            };
        case FETCH_SURGEON:
            return {
                ...state,
                surgeon: data,
            };
        default:
            return state;
    }
}

export default SurgeonReducer;
