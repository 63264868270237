import actions from './actions';
import {Requests as req} from "../../../utils/requests";
import { Constants } from '../../../config/constants';

const { fetchRolesPermissionsAction, resetRolesPermissionsAction } = actions;

export const fetchRolesPermissions = (roleId, {state, setState}) => {
    return async dispatch => await req.getRequest({
        url: Constants.USER_MANAGEMENT + 'roles-permissions/' + roleId,
        auth: 'bearer'
    }, (cb) => {
        // dispatch(resetRolesPermissionsAction());
        // dispatch(fetchRolesPermissionsAction(cb));
        let arr = [];
        cb.forEach((permission) => arr = [...arr, ...permission.checked]);
        setState({ ...state, checked: arr, permissions: cb, roleId});
    });
};

export const saveRolesPermissions = ({roleId, permissions}) => {
    return async dispatch => await req.putRequest({
        url: Constants.USER_MANAGEMENT + 'roles-permissions',
        body: {roleId, permissions},
        auth: 'bearer'
    }, (cb) => dispatch(resetRolesPermissionsAction()));
};
