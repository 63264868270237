const actions = {
    FETCH_PROCEDURES: 'FETCH_PROCEDURES',
    FETCH_PROCEDURE: 'FETCH_PROCEDURE',
    RESET_PROCEDURE: 'RESET_PROCEDURE',

    fetchProceduresAction: (data) => {
        return {
            type: actions.FETCH_PROCEDURES,
            data: data
        };
    },
    resetProcedureFormAction: () => {
        return {
            type: actions.RESET_PROCEDURE,
            data: null
        };
    },
    fetchProcedureAction: (data) => {
        return {
            type: actions.FETCH_PROCEDURE,
            data: data
        };
    },
}

export default actions;
