import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../components/layout/Section";
import {Form} from "antd";
import {
    addProcedure,
    deleteProcedure,
    fetchProcedure,
    fetchProcedures,
    resetProcedureForm,
    updateProcedure
} from "../../../redux/patient-management/procedures/actionCreator";
import ProcedureList from "./list";
import ProcedureForm from "./form";

const ProcedureData = () => {
    const dispatch = useDispatch();
    const procedures = useSelector(state => state.pmProcedures.procedures);
    const procedure = useSelector(state => state.pmProcedures.procedure);
    const [form] = Form.useForm();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchProcedures({page: 1, perPage: 100}));
        };
        fetchData().then(r => null);
    }, []);

    const getProcedureInfo = (_id) => dispatch(fetchProcedure(_id, showModalEdit));
    const showModal = async type => {
        dispatch(resetProcedureForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };

    const handleOk = async () => {
        await dispatch(resetProcedureForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };

    return (
        <Section>
            <ProcedureList
                state={state}
                setState={setState}
                showModal={showModal}
                isLoading={'isLoading'}
                procedures={procedures}
                getProcedureInfo={getProcedureInfo}
                deleteProcedure={deleteProcedure}
            />

            <ProcedureForm
                state={state}
                form={form}
                procedure={procedure}
                addProcedure={addProcedure}
                updateProcedure={updateProcedure}
                handleOk={handleOk}
            />
        </Section>
    )
}

export default ProcedureData;
