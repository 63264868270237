import {Constants} from "../../../config/constants";
import {Requests as req} from "../../../utils/requests";
import actions from './actions';
import {Alert} from "../../../utils/alert";
import {resetUserForm} from "../../userManagement/users/actionCreator";

const {
    fetchPatientsAction,
    fetchPatientAction,
    checkPatientAction,
    resetPatientFormAction,
    resetZipCodesFormAction,
    fetchStatesAction,
    fetchCitiesAction,
    fetchZipCodesAction,
    fetchInsuredCitiesAction,
    fetchInsuredZipCodesAction,
} = actions;

export const addPatientInCSV = (data) => {
    return async dispatch => {
        await req.postRequest({url: Constants.PATIENTS_CSV, auth: 'bearer', body: data,}, async (cb) => {
            await dispatch(fetchPatients({page: 1, perPage: 30}));
        })
    };
};

export const addPatient = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({url: Constants.PATIENTS, auth: 'bearer', body: data, validate: validate}, async (cb) => {
            await dispatch(fetchPatients({page: 1, perPage: 30}));
            await dispatch(action);
        })
    };
};

export const fetchPatients = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.PATIENTS,
            auth: 'bearer',
            queries
        }, (cb) => dispatch(fetchPatientsAction(cb)))
    };
};

export const updatePatient = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({url: Constants.PATIENTS + data._id, auth: 'bearer', body: data, validate: validate}, async (cb) => {
            await dispatch(fetchPatients({page: 1, perPage: 30}));
            await dispatch(action);
        })
    };
};

export const resetPatientForm = () => dispatch => dispatch(resetPatientFormAction());
export const resetZipCodesForm = () => dispatch => dispatch(resetZipCodesFormAction());

export const fetchPatient = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PATIENTS + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchPatientAction(cb));
            if (cb?.city?.state?._id) {
                await dispatch (getStateInfo(cb?.city?.state?._id, action))
            }
            await dispatch(action);
        })
    };
};

export const getPatientInfo = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PATIENTS + _id, auth: 'bearer'}, async (cb) => {
            await action(cb)
        })
    };
};
export const getThePatientInfo = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PATIENTS + _id, auth: 'bearer'}, async (cb) => {
            await action(cb)
            if (cb) dispatch(fetchPatientAction(cb))
        })
    };
};

export const checkPatientInfo = (values) => {
    return async dispatch => {
        await req.getRequest({url: Constants.CHECK_PATIENTS, auth: 'bearer', queries: values}, async (cb) => {
            // if (cb) dispatch(checkPatientAction(cb))
            if (cb !== 404) Alert.warning({title: "This patient already exists"})
        })

    };
};

export const getChartIdInfo = (data) => {
    return async dispatch => {
        await resetUserForm();
        await req.getRequest({url: Constants.CHART_ID_CHECK, queries: data, auth: 'bearer'}, async (cb) => {
            // await dispatch(fetchPatientAction(cb));
            if (cb !== 404) Alert.warning({title: "Chart ID already exists"})
        })
    };
};


export const deletePatient = (id) => {
    return async dispatch => {
        await req.deleteRequest({url: Constants.PATIENTS + id, auth: 'bearer'}, (cb) => {
            dispatch(fetchPatients({page: 1, perPage: 30}));
        })
    };
};

export const fetchStates = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.COUNTRIES,
            auth: 'bearer'
        }, (cb) => dispatch(fetchStatesAction(cb)))
    };
};


export const getStateInfo = (_id) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.COUNTRIES + _id,
            auth: 'bearer'
        }, async (cb) => dispatch(fetchCitiesAction(cb)))
    };
};

export const getInsuredStateInfo = (_id) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.COUNTRIES + _id,
            auth: 'bearer'
        }, async (cb) => dispatch(fetchInsuredCitiesAction(cb)))
    };
};

export const getCityInfo = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.COUNTRIES + _id, auth: 'bearer'}, async (cb) => {
            await action(cb)
        })
    };
};

export const getInsuredCityInfo = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.COUNTRIES + _id, auth: 'bearer'}, async (cb) => {
            await action(cb)
        })
    };
};

export const getCityInfoWithZipCodes = (_id) => {
    return async dispatch => {
        if (_id)
            await req.getRequest({
                url: Constants.COUNTRY + _id,
                auth: 'bearer'
            }, async (cb) => dispatch(fetchZipCodesAction(cb)))
    };
};

export const getInsuredCityInfoWithZipCodes = (_id) => {
    return async dispatch => {
        if (_id)
            await req.getRequest({
                url: Constants.COUNTRY + _id,
                auth: 'bearer'
            }, async (cb) => dispatch(fetchInsuredZipCodesAction(cb)))
    };
};