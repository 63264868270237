import React, {Fragment} from "react"
import {Modal, Image, Typography, Tag, Row, Badge, Button, Space} from "antd";
import {Constants} from "../../config/constants";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import Text from "antd/es/typography/Text";
import comment from "../../static/images/bubbles.png";
import {DownloadOutlined} from "@ant-design/icons";
const ViewData = ({viewState, service, viewHandleOk}) => {

    const footerButtons = [
        <Tag color="#cd201f">
            Cancelled
        </Tag>
    ];

    const openFileInNewTab = (fileName) => {
        // Split the file name based on underscore '_'
        const fileNameParts = fileName.split('_');

        // Use array slice to get the desired part (excluding the first part)
        const trimmedFileName = fileNameParts.slice(1).join('_');

        const fileUrl = Constants.BASE_ENDPOINT + `images/${fileName}`;
        window.open(fileUrl, '_blank');

        console.log("Trimmed File Name:", trimmedFileName);
    };


    const getTrimmedFileName = (fileName) => {
        // Split the file name based on underscore '_'
        const fileNameParts = fileName.split('_');

        // Use array slice to get the desired part (excluding the first part)
        const trimmedFileName = fileNameParts.slice(1).join('_');

        return trimmedFileName;
    };

    return (
        <Fragment>
            <Modal
                title={
                <Row>
                    <Tag className="m-2">
                        Ticket ID : {service.ticketId}
                        {
                            service.lifeStatus === "inactive" ?  <Badge size="default" dot="dot" /> : ""
                        }
                    </Tag>
                    <Tag className="m-2 ml-5">Priority:
                        <Tag className="ml-5 mr-0" color={service?.priority === "high" ? "#f50" : service?.priority === "medium" ? "#108ee9" : "#87d068"}>
                            {service.priority}
                            {
                                service.lifeStatus === "inactive" ?  <Badge size="default" dot="dot" /> : ""
                            }
                        </Tag>
                    </Tag>
                    <Tag className="m-2 ml-5">Status:
                        <Tag className="ml-5 mr-0" color={service?.status === "pending" ? "red" : service?.status === "processing" ? "cyan" : "green"}>
                            {service.status}
                            {
                                service.lifeStatus === "inactive" ?  <Badge size="default" dot="dot" /> : ""
                            }
                        </Tag>
                    </Tag>
                </Row>
            }
                width={800}
                visible={viewState.visible}
                footer={service.lifeStatus === "inactive" ? footerButtons : false}
                onCancel={() => viewHandleOk()}
            >
                <Space className="m-3" direction="vertical">
                    {service?.files?.map((item, i) => (
                        <div key={i} className="p-2">
                            {item.endsWith('.pdf') ? (
                                <Button type="link" className="btn-outline-primary" onClick={() => openFileInNewTab(item)}>
                                    <DownloadOutlined title=" Download PDF" />
                                    {getTrimmedFileName(item)}
                                </Button>
                            ) : (
                                // <img src={Constants.BASE_ENDPOINT + `images/${item}`} alt={item} />
                                <Image.PreviewGroup
                                    preview={{
                                        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                    }}
                                >
                                    <Image className="p-2" width={200} src={Constants.BASE_ENDPOINT + `images/${item}`} />
                                </Image.PreviewGroup>
                            )}
                        </div>
                    ))}
                </Space>

                <br/>
                <br/>
                <Typography>
                    <Title mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} className="p-2" style={{textDecoration: "underline"}} level={3}>{service?.title ? service.title : ""}</Title>
                    <Paragraph mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} className="pl-4 pr-4 pb-4">
                        {service?.description ? service.description : ""}
                    </Paragraph>
                </Typography>
                {
                    service?.comments ? (
                        <Space className="m-3" direction="vertical">
                            <Text className="image-view-comment" style={{lineHeight: "0.5", fontSize: "12px"}}
                                  title="Feedback"
                                  type="">
                                <img src={comment} alt=""/>
                                {service?.comments}
                            </Text>
                        </Space>
                    ) : ""
                }

            </Modal>
        </Fragment>
    )
}

export default ViewData;