import React, {Fragment, useEffect, useState} from 'react';
import logoText from '../../static/images/logo-text.png';
import mainLogo from "../../static/images/main_logo.png"
import {
    AlignLeftOutlined,
    AlignRightOutlined,
    DesktopOutlined, ProfileFilled,
    ScheduleOutlined,
    SlackOutlined,
    UserOutlined
} from "@ant-design/icons";
import {Layout, Menu} from "antd";
import {NavLink, useLocation} from "react-router-dom";
import {Scope} from "../../services/scopeService";
import {useDispatch} from "react-redux";
import {Auth} from "../../utils/auth";
import {resetServerFilteredScheduleForm} from "../../redux/schedule-management/schedules/actionCreator";
import {resetFilteredPatients} from "../../redux/utilities/actionCreator";
const {Sider} = Layout;
const {SubMenu} = Menu;

const SideBarStyle = {
    height: '100vh',
    position: 'fixed',
    left: 0,
    zIndex: 998,
    borderRight: '1px solid #f0f0f0'
};

const SideBar = () => {
    const [collapse, setCollapse] = useState(window.innerWidth < 1500)
    const screenWidth = window.innerWidth;
    const dispatch = useDispatch();
    const getUsers = Auth.getUserInfo()
    const router = useLocation()

    return (
        <Sider
            breakpoint={"lg"}
            collapsedWidth={screenWidth < 700 ? "0" : "65"}
            className="site-layout-background"
            collapsed={collapse}
            width={250}
            style={screenWidth < 700 ? SideBarStyle : null}
            onCollapse={() => setCollapse(!collapse)}
        >
            <div className="logo">
                <div onClick={() => setCollapse(!collapse)} style={{cursor: "pointer"}}>
                    {collapse ? <AlignLeftOutlined/> : <AlignRightOutlined/>}
                    {!collapse ?
                        <Fragment>
                            <img src={logoText} alt="logo" style={{height: 26, marginTop: -4}}/>
                            <img src={mainLogo} alt="logo" style={{height: 27, marginTop: -26, marginLeft: 110}}/>
                        </Fragment>
                        : null}
                </div>
            </div>
            <Menu theme="light" mode="inline" style={{padding: 10}}>
                {Scope.checkScopes(['dashboard_index']) && (
                    <Menu.Item key="dashboard" icon={<DesktopOutlined/>}>
                        <NavLink onClick={async () => router?.pathname !== '/dashboard' ?  await dispatch(resetServerFilteredScheduleForm()) : ""} to={`/dashboard`} className={router?.pathname === '/dashboard' ? "navBox" : ""}> Dashboard </NavLink>
                    </Menu.Item>
                )}
                {
                    getUsers && getUsers?.surgeon?._id ? (
                        <Fragment>
                            {Scope.checkScopes(['records_surgeons_profile']) && (
                                <Menu.Item key="profile" icon={<ProfileFilled />}>
                                    <NavLink to={`/profile`} className={router?.pathname === '/profile' ? "navBox" : ""}> Profile </NavLink>
                                </Menu.Item>
                            )}
                        </Fragment>
                    ) : null
                }
                {Scope.checkScopes(['records_scheduleSurgery_index']) && (
                    <Menu.Item key="schedules" icon={<ScheduleOutlined />}>
                        <NavLink onClick={async () => router?.pathname !== '/schedules' ?  await dispatch(resetServerFilteredScheduleForm()) : ""} to={`/schedules`} className={router?.pathname === '/schedules' ? "navBox" : ""}> Schedule Surgery </NavLink>
                    </Menu.Item>
                )}
                <SubMenu key="schedule-surgery" icon={<SlackOutlined />} title="Records">
                    {Scope.checkScopes(['records_facilities_index']) && (
                        <Menu.Item key="facilities">
                            <NavLink to={`/facilities`} className={router?.pathname === '/facilities' ? "navBox" : ""}> Facilities </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_surgeons_index']) && (
                        <Menu.Item key="surgeons">
                            <NavLink to={`/surgeons`} className={router?.pathname === '/surgeons' ? "navBox" : ""}> Surgeons </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_procedures_index']) && (
                        <Menu.Item key="Procedures">
                            <NavLink to={`/Procedures`} className={router?.pathname === '/Procedures' ? "navBox" : ""}> Procedures </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_patientInfo_index']) && (
                        <Menu.Item key="patients">
                            <NavLink onClick={async () => router?.pathname !== '/patients' ?  await dispatch(resetFilteredPatients()) : ""} to={`/patients`} className={router?.pathname === '/patients' ? "navBox" : ""}> Patient Info</NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_representatives_index']) && (
                        <Menu.Item key="supportive">
                            <NavLink to={`/supportive`} className={router?.pathname === '/supportive' ? "navBox" : ""}> Representatives</NavLink>
                        </Menu.Item>
                    )}

                </SubMenu>
                {Scope.checkScopes(['administration_users_index']) && (
                <SubMenu key="user-management" icon={<UserOutlined/>} title="Administration">
                    {Scope.checkScopes(['administration_roles_index']) && (
                        <Menu.Item key="roles">
                            <NavLink to={`/roles`} className={router?.pathname === '/roles' ? "navBox" : ""}> Roles </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['administration_permissions_index']) && (
                        <Menu.Item key="permissions">
                            <NavLink to={`/permissions`} className={router?.pathname === '/permissions' ? "navBox" : ""}> Permissions </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['administration_users_index']) && (
                        <Menu.Item key="users">
                            <NavLink to={`/users`} className={router?.pathname === '/users' ? "navBox" : ""}> User Management </NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_activities_index']) && (
                        <Menu.Item key="activities">
                            <NavLink to={`/activities`} className={router?.pathname === '/activities' ? "navBox" : ""}> Activities</NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_emailLogs_index']) && (
                        <Menu.Item key="email">
                            <NavLink to={`/email`} className={router?.pathname === '/email' ? "navBox" : ""}> Email Logs</NavLink>
                        </Menu.Item>
                    )}
                    {Scope.checkScopes(['records_services_index']) && (
                        <Menu.Item key="services">
                            <NavLink to={`/services`} className={router?.pathname === '/services' ? "navBox" : ""}> Support & Services</NavLink>
                        </Menu.Item>
                    )}
                </SubMenu>
                )}
            </Menu>
        </Sider>
    )
}

export default SideBar;
