import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../components/layout/Section";
import FacilityForm from "./form";
import FacilityList from "./list";
import {Form} from "antd";
import {
    addFacility,
    deleteFacility,
    fetchFacilities,
    fetchFacility,
    updateFacility,
    resetFacilityForm
} from "../../../redux/patient-management/facilities/actionCreator";

const FacilityData = () => {
    const facilities = useSelector(state => state.pmFacilities.facilities);
    const facility = useSelector(state => state.pmFacilities.facility);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchFacilities());
        };
        fetchData().then(r => {
        });
    }, []);

    const getFacilityInfo = (_id) => dispatch(fetchFacility(_id, showModalEdit));

    const showModal = async type => {
        dispatch(resetFacilityForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };


    const handleOk = async () => {
        await dispatch(resetFacilityForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };
    return (
        <Section>
            <FacilityList
                state={state}
                setState={setState}
                showModal={showModal}
                isLoading={'isLoading'}
                facilities={facilities}
                getFacilityInfo={getFacilityInfo}
                deleteFacility={(deleteFacility)}
            />
            <FacilityForm
                state={state}
                form={form}
                facility={facility}
                addFacility={addFacility}
                updateFacility={updateFacility}
                handleOk={handleOk}
            />
        </Section>
    )
}

export default FacilityData;