import React, {useEffect, useState} from "react";
import {Button, Modal, Space, Card, Divider, Timeline, Row, Tag} from "antd";
import moment from "moment";
const View = (
    {
        showModalState,
        activities,
        isLoading,
        handleOk
    }
) => {
    const [log, setLog] = useState([]);

    useEffect(() => {
        const arr = [];
        activities.forEach((activity) => {
            activity.fields.forEach((field) => {
                if ((arr.find((item) => item.field === field.name)) === undefined && field.name !== "_id") arr.push({field: field.name, changes: []})
            })
        })

        activities.forEach((activity) => {
            activity.fields.forEach((field) => {
                const data = arr.find((item) => item.field === field.name);

                // if (data && (data.changes.find((item) => item.value === field.value)) === undefined) {
                //     data.changes.push({...field, user: activity.createdBy, time: activity.createdAt})
                // }
                const color = data?.changes?.find((item) => item?.value !== field?.value) === undefined ? null : "color"
                if (data && (data.changes.find((item) => item.value === field.value)) === undefined) {
                    data.changes.push({...field, user: activity.createdBy, time: activity.createdAt, color:color })
                }
            })
        })

        setLog(arr)
    }, [activities])


    const footerButtons = [
        <Button
            key="button-1"
            htmlType="button"
            type="primary"
            disabled={isLoading}
            size="small"
        >
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];

    return (
        <Modal
            type={"primary"}
            title="View Records"
            visible={showModalState.showModal}
            onCancel={handleOk}
            footer={footerButtons}
            width={1200}
            height={1000}
        >
            {/*<table style={{border: "1px solid"}}>*/}
            {/*    <tbody>*/}
            {/*    {log.map((data, di) => (*/}
            {/*        <tr key={di}>*/}
            {/*            <td style={{border: "1px solid"}}>{data.field}</td>*/}
            {/*            {data.changes.map((change, ci) => (*/}
            {/*                <td*/}
            {/*                    key={ci}*/}
            {/*                    style={{*/}
            {/*                        border: "1px solid",*/}
            {/*                        textDecoration: ci !== (data.changes.length - 1) ? "line-through" : "none"*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    {change.value}*/}
            {/*                    <br/>*/}
            {/*                    <cite>by {`${change.user.personal.firstName} ${change.user.personal.lastName}`} on {change.time}</cite>*/}
            {/*                </td>*/}
            {/*            ))}*/}
            {/*        </tr>*/}
            {/*    ))}*/}
            {/*    </tbody>*/}
            {/*</table>*/}

            {log.map((data, di) => (
                <Timeline.Item color="orange" className="ml-5 mt-4 mb-2 mr-2">
                    <Row>
                        <b className="mr-10">{data.field.toUpperCase()}</b>
                        <Row>
                        {data.changes.map((change, ci) => (
                            <Space size={[0, 8]} wrap>
                                <Card bordered={true} size="small"  className="mr-2 btn-toolbar pr-1" color="#87d068">
                                    <span style={{fontSize: "small"}}>{change.value}</span>
                                    <br/>
                                    <span style={ change?.color ? {fontSize: "smaller", color: "red"} : {fontSize: "smaller"}}>
                                        <cite>by <b>{`${change.user.personal.firstName} ${change.user.personal.lastName}`}</b> on { moment(change.time).format("MM-DD-YYYY hh:mm:ss A")}</cite>
                                    </span>
                                </Card>
                                {/*<Tag color="#87d068">#87d068</Tag>*/}
                                {/*<Tag color="#108ee9">#108ee9</Tag>*/}
                            </Space>
                        ))}
                        </Row>
                    </Row>
                    <Divider />
                </Timeline.Item>

                ))}
        </Modal>
    )
}

export default View;
