const actions = {
    FETCH_SERVICES: 'FETCH_SERVICES',
    FETCH_SERVICE: 'FETCH_SERVICE',
    RESET_SERVICE: 'RESET_SERVICE',

    fetchServicesAction: (data) => {
        return {
            type: actions.FETCH_SERVICES,
            data: data
        };
    },
    resetServiceFormAction: () => {
        return {
            type: actions.RESET_SERVICE,
            data: null
        };
    },
    fetchServiceAction: (data) => {
        return {
            type: actions.FETCH_SERVICE,
            data: data
        };
    },
}

export default actions;
