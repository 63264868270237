import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MainSurgeonForm from "./main-surgeon-form";
import CoSurgeonFrom from "./co-surgeon-from";

const SurgeonForm = ({
     form,
     usersAsSurgeons,
     state,
     coState,
     surgeon,
     getEmailInfo,
     addSurgeon,
     updateSurgeon,
     isLoading,
     coHandleOk,
     handleOk,
 }) => {
    const dispatch = useDispatch();

    const handleSubmit = async (data) => {
        if (surgeon?._id) {
            Object.assign(data, {_id: surgeon._id});
            await dispatch(updateSurgeon(data, handleOk, form.setFields))
        } else
            await dispatch(addSurgeon(data, handleOk, form.setFields));
    };

    const coHandleSubmit = async (data) => {
        if (surgeon?._id) {
            Object.assign(data, {_id: surgeon._id});
            await dispatch(updateSurgeon(data, coHandleOk, form.setFields))
        } else
            await dispatch(addSurgeon(data, coHandleOk, form.setFields));
    };

    const onSurgeonEmailChange = async (_id) => {
        if (_id) {
            form.setFieldsValue ({
                firstName: null,
                lastName: null,
                phone: null,
                address: null,
                coEmail: null,
            })
            await dispatch(getEmailInfo(_id,form.setFields, (data) => {
                    form.setFieldsValue({
                        firstName: data?.personal?.firstName ? data.personal.firstName : null,
                        lastName: data?.personal?.lastName ? data.personal.lastName : null,
                        phone: data?.personal?.phone ? data.personal.phone : null,
                        address: data?.personal?.address ? data.personal.address : null
                    })
                })
            )
        }
    }

    return (
       state.visible ? (
           <MainSurgeonForm
               state={state}
               form={form}
               surgeon={surgeon}
               usersAsSurgeons={usersAsSurgeons}
               onSurgeonEmailChange={onSurgeonEmailChange}
               isLoading={isLoading}
               handleOk={handleOk}
               handleSubmit={handleSubmit}
           />
       ) : coState.visible ? (
           <CoSurgeonFrom
               coState={coState}
               surgeon={surgeon}
               isLoading={isLoading}
               coHandleOk={coHandleOk}
               coHandleSubmit={coHandleSubmit}
           />
       ) : null
    );
};

export default SurgeonForm;