import {PageHeader, Table, Button, Row, Spin} from "antd";
import React from "react";
import FeatherIcon from "feather-icons-react";
import FontAwesome from "react-fontawesome";
import {Alert} from "../../../utils/alert";
import {Scope} from "../../../services/scopeService";

const RoleList = ({showModal, isLoading, roles, getRoles, roleInfo, deleteRole}) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (key) => <>
                {Scope.checkScopes(['administration_roles_update']) && (
                    <FontAwesome
                        onClick={() => roleInfo(key)}
                        name={"edit"}
                        className="color-info"
                        size="2x"
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer" }}
                    />
                )}
                {Scope.checkScopes(['administration_roles_delete']) && (
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deleteRole(key)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer" }}
                    />
                )}
            </>
        },
    ];

    const headerButtons = [
        <div key="1" className="page-header-actions">
            {Scope.checkScopes(['administration_roles_create']) && (
                <Button size="small" type="primary" onClick={() => showModal('primary')}>
                    <Row>
                        <FeatherIcon icon="plus" size={14}/>
                        Add New
                    </Row>
                </Button>
            )}
        </div>,
    ];

    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Roles"
                subTitle={`List of all ${roles.total} roles.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                <Table
                    rowKey="_id"
                    bordered={false}
                    className="table-responsive"
                    loading={!roles.data?.length ? <Spin  className="example"/> : ""}
                    dataSource={roles.data}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
}

export default RoleList;
