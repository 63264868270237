import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Col, Button, Form, Modal, Select, DatePicker, Space} from "antd";
import {resetServerFilteredScheduleForm} from "../../../redux/schedule-management/schedules/actionCreator";
const { RangePicker } = DatePicker;
const SurgeonFilter = (
    {
        filter,
        filterState,
        setFilterState,
        getTheSurgeon,
        setTheSurgeon,
        filters,
        setFilters,
        surgeons,
        coSurgeons,
        procedures,
        facilities,
        patients,
        fetchFilteredPatients,
        isLoading
    }
) => {

    const dispatch = useDispatch();
    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 }}
    const validateMessages = { required: '${label} is required!' };
    const [getDateValue, setDate] = useState([])

    const handleSubmit = async (data) => {
        Object.assign(data, {daysValue: getDateValue});
        await setFilters({...filters, ...data})
        await setFilterState({...filterState, filterVisible: false})
    };

    const resetFilters = async () => {
        await setFilters({...filters, surgeonId: "", active: "", scheduleStatus: "", daysValue: [], coSurgeonId: "", procedure: [], facilityId: "", patientId: ""});
        await setDate([])
        await filter.resetFields();
        await setTheSurgeon({...getTheSurgeon, data: {}})
        await dispatch(resetServerFilteredScheduleForm())
        // await setFilters({...filters, surgeonId: "", scheduleStatus: "", daysValue: []})
    }

    const footerButtons = [
        <Button
            key="reset-button"
            htmlType="button"
            type="secondary"
            size="medium"
            onClick={resetFilters}
        >
            {isLoading ? 'Loading...' : 'Clear'}
        </Button>,
        <Button
            form="reportFilterForm"
            key="submit-button"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="medium"
        >
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];

    const onChange = (value, dateString) => {
        setDate(dateString)
    };

    return (
        <Col md={12}>
            <Modal
                type={"primary"}
                width={550}
                title="Report Filter"
                visible={filterState.filterVisible}
                onCancel={() => setFilterState({...filterState, filterVisible: false})}
                footer={footerButtons}
            >
                <Form
                    {...layout}
                    name={'reportFilter'}
                    form={filter}
                    id={'reportFilterForm'}
                    className="my-form"
                    validateMessages={validateMessages}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="procedure"
                        rules={[{required: false}]}
                        label="Surgeries"
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select Procedure"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {procedures ? procedures.map((procedure, pi) => (
                                <Select.Option
                                    key={pi}
                                    value={procedure._id}
                                >
                                    {procedure.surgery}
                                </Select.Option>
                            )) : ""}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="facilityId"
                        rules={[{required: false}]}
                        label="Facility"
                    >
                        <Select
                            showSearch
                            placeholder="Select a Facility"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {facilities ? facilities.map((facility, fi) => (
                                <Select.Option
                                    key={fi}
                                    value={facility._id}
                                >
                                    {facility.name}
                                </Select.Option>
                            )) : ""}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="surgeonId"
                        rules={[{required: false}]}
                        label="Surgeon"
                    >
                        <Select>
                            {surgeons?.map((surgeon, si) => (
                                <Select.Option key={si} value={surgeon._id}>{surgeon.firstName} {surgeon.lastName}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="coSurgeonId"
                        rules={[{required: false}]}
                        label="Co Surgeons"
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            placeholder="Select Surgeons"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {coSurgeons ? coSurgeons.map((surgeon, pi) => (
                                <Select.Option
                                    key={pi}
                                    value={surgeon._id}
                                >
                                    {surgeon.firstName} {surgeon.lastName}
                                </Select.Option>
                            )) : ""}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="patientId"
                        label="Patient"
                    >
                        <Select
                            showSearch
                            placeholder="FirstName / LastName / Chart ID / Phone / Email"
                            loading={isLoading}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                            onSearch={(event) => event?.length && event.length > 2 ? dispatch(fetchFilteredPatients({name: event})) : null}
                        >
                            {patients?.map((patient, si) => (
                                <Select.Option key={si} value={patient._id}>
                                    {patient?.firstName + " " + patient?.lastName + " - " + " " + " Chart ID: " + (patient?.chartId ? patient?.chartId : "not available")}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="active"
                        rules={[{ required: false }]}
                        label="Schedule Status">
                        <Select>
                            <Select.Option value="inactive">up-to-date</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="scheduleStatus"
                        rules={[{ required: false }]}
                        label="Cancelled Status">
                        <Select>
                            <Select.Option value="inactive">Cancelled</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="daysValue"
                        rules={[{ required: false }]}
                        label="Select Date">
                        <Space direction="vertical" size={12}>
                            <RangePicker
                                onChange={onChange}
                            />
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    )
}

export default SurgeonFilter;
