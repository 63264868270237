import {Constants} from "../../../config/constants";
import {Requests as req} from "../../../utils/requests";
import actions from './actions';
import {fetchProcedures as fetchUtilitiesProcedures} from "../../utilities/actionCreator"

const {fetchProceduresAction, fetchProcedureAction, resetProcedureFormAction} = actions;


export const addProcedure = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({url: Constants.PROCEDURES, auth: 'bearer', body: data, validate}, async (cb) => {
            await dispatch(fetchProcedures());
            await dispatch(action);
        })
    };
};

export const fetchProcedures = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.PROCEDURES,
            auth: 'bearer'
        }, (cb) => dispatch(fetchProceduresAction(cb)))
    };
};

export const fetchProceduresForSchedulers = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.PROCEDURES_FOR_SCHEDULERS,
            auth: 'bearer'
        }, (cb) => dispatch(fetchProceduresAction(cb)))
    };
};


export const updateProcedure = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.PROCEDURES + data._id,
            auth: 'bearer',
            body: data,
            validate
        }, async (cb) => {
            await dispatch(fetchProcedures());
            await dispatch(action);
        })
    };
};

export const resetProcedureForm = () => dispatch => dispatch(resetProcedureFormAction());

export const fetchProcedure = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PROCEDURES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchProcedureAction(cb));
            await dispatch(action);
        })
    };
};

export const fetchProcedureForm = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PROCEDURES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchProcedureAction(cb));
            await action(cb)
        })
    };
};

export const fetchProcedureFormForSchedulers = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PROCEDURES_FOR_SCHEDULERS + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchProcedureAction(cb));
            await action(cb)
        })
    };
};


export const fetchRepresentativeProcedureForm = (_id) => {
    return async dispatch => {
        await req.getRequest({url: Constants.PROCEDURES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchProcedureAction(cb));
        })
    };
};

export const deleteProcedure = (id) => {
    return async dispatch => {
        await req.deleteRequest({url: Constants.PROCEDURES + id, auth: 'bearer'}, (cb) => dispatch(fetchProcedures()))
    };
};
