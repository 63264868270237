const actions = {
    FETCH_FACILITIES: 'FETCH_FACILITIES',
    FETCH_FACILITY: 'FETCH_FACILITY',
    RESET_FACILITY: 'RESET_FACILITY',

    fetchFacilitiesAction: (data) => {
        return {
            type: actions.FETCH_FACILITIES,
            data: data
        };
    },
    resetFacilityFormAction: () => {
        return {
            type: actions.RESET_FACILITY,
            data: null
        };
    },
    fetchFacilityAction: (data) => {
        return {
            type: actions.FETCH_FACILITY,
            data: data
        };
    },
}

export default actions;
