import React, {Fragment, useRef, useState} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {PageHeader, Table, Tag, Tabs, Row, Input, Space, Button, Spin} from "antd";
import moment from "moment";
import Highlighter from "react-highlight-words";
import Text from "antd/es/typography/Text";

const EmailLogsList = ({emailLogs, cancelLogs}) => {
    const output = emailLogs.reduce((a,v) => ((a[v.date] = a[v.date] || []).push(v), a), []);
    const result = Object.entries(output);

    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //

    const columns = [
        {
            title: 'Scheduling Date',
            dataIndex: 'scheduleDate',
            key: 'scheduleDate',
            ...getColumnSearchProps('scheduleDate'),
        },
        {
            title: 'Scheduling Period',
            render: (emailLog) => (
                <span>
                    {` ${emailLog?.startTime ? moment(emailLog.startTime, ["HH.mm"]).format("hh:mm a") + " - " + moment(emailLog.endTime, ["HH.mm"]).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'Sent Emails',
            ...getColumnSearchProps('emails'),
            width: "18%",
            render: (emailLogs) => (
               <> {emailLogs.emails.map((tag ,i) => <><Text code>{tag}</Text></>)} </>
            )
        },
        {
            title: 'Procedure',
            dataIndex: 'surgery',
            key: 'surgery',
            ...getColumnSearchProps('surgery'),
        },
        {
            title: 'Location',
            dataIndex: 'facility',
            key: 'facility',
            ...getColumnSearchProps('facility'),
        },
        {
            title: 'Patient',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Sending Date',
            dataIndex: 'dateString',
            key: 'dateString',
            ...getColumnSearchProps('dateString'),
        },
        {
            title: 'Sending Time',
            render: (emailLog) => (
                <span>
                    {` ${emailLog?.createdAt ? moment(emailLog.createdAt).format("hh:mm A") : ""}`}
                </span>
            )
        },
        {
            title: 'Email Sender',
            dataIndex: 'userEmail',
            key: 'userEmail',
        },
    ];
    const screenWidth = window.innerWidth;

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Sent Emails" key="1">
                    <PageHeader
                        ghost={false}
                        // title="List of Sent Emails"
                        subTitle={`List of sent emails.`}
                        onBack={() => window.history.back()}
                    >
                    </PageHeader>
                    <Table
                        pagination={{pageSize: 20, showSizeChanger: false}}
                        rowKey="_id"
                        bordered={false}
                        className="table-responsive"
                        loading={!emailLogs?.length ? <Spin  className="example"/> : ""}
                        dataSource={emailLogs ? emailLogs : ""}
                        columns={columns}
                        responsive={true}
                        scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Cancelled Emails" key="2">
                    <PageHeader
                        ghost={false}
                        // title="List of Cancelled mails"
                        subTitle={`List of cancelled emails.`}
                        onBack={() => window.history.back()}
                    >
                    </PageHeader>
                    <Table
                        pagination={{pageSize: 20, showSizeChanger: false}}
                        rowKey="_id"
                        bordered={false}
                        className="table-responsive"
                        loading={!cancelLogs?.length ? <Spin  className="example"/> : ""}
                        dataSource={cancelLogs ? cancelLogs : ""}
                        columns={columns}
                        responsive={true}
                        scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
}

export default EmailLogsList;