import actions from './actions';

const {
    FETCH_UTILITIES_ROLES,
    FETCH_UTILITIES_USER,
    FETCH_UTILITIES_USERS,
    FETCH_UTILITIES_PROCEDURES,
    FETCH_UTILITIES_SURGEONS,
    FETCH_UTILITIES_PATIENTS,
    FETCH_UTILITIES_FACILITIES,
    FETCH_UTILITIES_EMAILS,
    FETCH_UTILITIES_CANCEL_EMAILS,
    FETCH_UTILITIES_CO_SURGEONS,
    RESET_FILTERED_PATIENTS
} = actions;

const initState = {
    roles: [],
    user: {_id: null},
    users: [],
    procedures: [],
    surgeons: [],
    coSurgeons: [],
    patients: [],
    facilities: [],
    emails: [],
    cancelEmails:[],
};

const UtilitiesReducer = (state = initState, action) => {
    const { type, data } = action;
    switch (type) {
        case FETCH_UTILITIES_ROLES:
            return {
                ...state,
                roles: data,
            };
        case FETCH_UTILITIES_USER:
            return {
                ...state,
                user: data,
            };
        case FETCH_UTILITIES_USERS:
            return {
                ...state,
                users: data,
            };
        case FETCH_UTILITIES_PROCEDURES:
            return {
                ...state,
                procedures: data,
            };
        case FETCH_UTILITIES_SURGEONS:
            return {
                ...state,
                surgeons: data,
            };
        case FETCH_UTILITIES_CO_SURGEONS:
            return {
                ...state,
                coSurgeons: data,
            };
        case   FETCH_UTILITIES_PATIENTS:
            return {
                ...state,
                patients: data,
            };
        case   FETCH_UTILITIES_FACILITIES:
            return {
                ...state,
                facilities: data,
            };
        case   FETCH_UTILITIES_EMAILS:
            return {
                ...state,
                emails: data,
            };
        case   FETCH_UTILITIES_CANCEL_EMAILS:
            return {
                ...state,
                cancelEmails: data,
            };
        case RESET_FILTERED_PATIENTS:
            return {
                ...state,
                patients: initState.patients,
            };
        default:
            return state;
    }
};
export default UtilitiesReducer;
