const actions = {

    FETCH_UTILITIES_ROLES       : 'FETCH_UTILITIES_ROLES',
    FETCH_UTILITIES_USER        : 'FETCH_UTILITIES_USER',
    FETCH_UTILITIES_USERS       : 'FETCH_UTILITIES_USERS',
    FETCH_UTILITIES_PROCEDURES    : 'FETCH_UTILITIES_PROCEDURES',
    FETCH_UTILITIES_SURGEONS    : 'FETCH_UTILITIES_SURGEONS',
    FETCH_UTILITIES_PATIENTS    : 'FETCH_UTILITIES_PATIENTS',
    FETCH_UTILITIES_FACILITIES    : 'FETCH_UTILITIES_FACILITIES',
    FETCH_UTILITIES_EMAILS    : 'FETCH_UTILITIES_EMAILS',
    FETCH_UTILITIES_CANCEL_EMAILS    : 'FETCH_UTILITIES_CANCEL_EMAILS',
    FETCH_UTILITIES_CO_SURGEONS    : 'FETCH_UTILITIES_CO_SURGEONS',
    RESET_FILTERED_PATIENTS     : "RESET_FILTERED_PATIENTS",

    fetchRolesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_ROLES,
            data: data
        };
    },
    fetchUserAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USER,
            data: data
        };
    },
    fetchUsersAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_USERS,
            data: data
        };
    },
    fetchProceduresAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PROCEDURES,
            data: data
        };
    },
    fetchSurgeonsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_SURGEONS,
            data: data
        };
    },
    fetchCoSurgeonsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CO_SURGEONS,
            data: data
        };
    },
    fetchPatientsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_PATIENTS,
            data: data
        };
    },
    fetchFacilitiesAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_FACILITIES,
            data: data
        };
    },
    fetchEmailsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_EMAILS,
            data: data
        };
    },
    resetFilteredPatientFormAction: () => {
        return {
            type: actions.RESET_FILTERED_PATIENTS,
            data: null
        };
    },
    fetchCancelEmailsAction: (data) => {
        return {
            type: actions.FETCH_UTILITIES_CANCEL_EMAILS,
            data: data
        };
    },
};

export default actions;
