import actions from "./actions";
import moment from "moment/moment";

const {
    FETCH_SCHEDULE,
    FETCH_SURGEON_SCHEDULES,
    FETCH_CANCELLED_SURGEON_SCHEDULES,
    FETCH_All_SURGEON_SCHEDULES,
    FETCH_SCHEDULES,
    FETCH_WEEKLY_SCHEDULES,
    FETCH_WEEKLY_ACTIVE_SCHEDULES,
    RESET_SCHEDULE,
    RESET_SERVER_SITE_FILTER_SCHEDULE,
    FETCH_SERVER_SITE_FILTER_SCHEDULES,
    FETCH_AVAILABILITIES,
} = actions;
const initState = {
    schedules: [],
    availabilities: [],
    activeSchedules : [],
    weeklySchedules: [],
    surgeonSchedules: [],
    surgeonCancelledSchedules:[],
    surgeonAllSchedules:[],
    serverFilteredSchedules: [],
    schedule: {
        _id: null,
        approvalPhone: null,
        authorization: null,
        consent: null,
        date: null,
        details: null,
        facility: null,
        patient: null,
        time: null,

    }
};

const today = moment().format("YYYY-MM-DD")
const ScheduleReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SURGEON_SCHEDULES:
            return {
                ...state,
                surgeonSchedules: data
            }
        case FETCH_AVAILABILITIES:
            return {
                ...state,
                availabilities: data
            }
        case FETCH_CANCELLED_SURGEON_SCHEDULES:
            return {
                ...state,
                surgeonCancelledSchedules: data
            }
        case FETCH_All_SURGEON_SCHEDULES:
            return {
                ...state,
                surgeonAllSchedules: data
            }
        case FETCH_SCHEDULES:
            return {
                ...state,
                schedules: data
            }
        case FETCH_WEEKLY_SCHEDULES:
            return {
                ...state,
                weeklySchedules: data
            }
        case FETCH_WEEKLY_ACTIVE_SCHEDULES:
            return {
                ...state,
                activeSchedules: data.filter((item,i ) => item.scheduleCancelStatus !== "inactive" && moment(item.date).format("YYYY-MM-DD") >= today)
            }
        case FETCH_SERVER_SITE_FILTER_SCHEDULES:
            return {
                ...state,
                serverFilteredSchedules: data
            }
        case FETCH_SCHEDULE:
            return {
                ...state,
                schedule: data,
            };
        case RESET_SCHEDULE:
            return {
                ...state,
                schedule: initState.schedule,
            };
        case RESET_SERVER_SITE_FILTER_SCHEDULE:
            return {
                ...state,
                serverFilteredSchedules: initState.schedule,
            };
        default:
            return state;
    }
}

export default ScheduleReducer;
