import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from "./rootReducer";

const initialState = {};
const middleware = [thunk];
const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(...middleware)
    // compose(
    //     applyMiddleware(...middleware),
    //     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    // )
);

export default store;