import actions from "./actions";

const {FETCH_ACTIVITIES, RESET_ACTIVITY, FETCH_ACTIVITY} = actions;
const initState = {
    activities: [],
    activity: [],
};

const ActivityReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_ACTIVITIES:
            return {
                ...state,
                activities: data
            }
        case FETCH_ACTIVITY:
            return {
                ...state,
                activity: data
            }
        case RESET_ACTIVITY:
            return {
                ...state,
                facility: initState.facility,
            };
        default:
            return state;
    }
}

export default ActivityReducer;
