import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Modal, Row} from "antd";

const PasswordForm = ({passForm, isLoading, passState, user, handlePassOk, changePassword}) => {
    const dispatch = useDispatch();
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}};


    const handlePassSubmit = async (data) => {
        Object.assign(data, {_id: passState._id});
        await dispatch(changePassword(data, handlePassOk, passForm.setFields))
    };

    const footerPassButtons = [
        <Button
            form="passForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="small">
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];

    return (
        <>
            <Row md={12}>
                <Modal
                    type={passState.modalType}
                    title="Change Password"
                    visible={passState.visible}
                    onCancel={() => handlePassOk()}
                    footer={footerPassButtons}
                    width={1000}
                >
                    <Form
                        {...layout}
                        name={'passForm'}
                        form={passForm}
                        id={'passForm'}
                        onFinish={handlePassSubmit}>
                        <Row>
                            <Col md={12}>
                                <Form.Item
                                    rules={[{required: true}]}
                                    label="Current Password"
                                    initialValue={null}
                                    name="currentPassword"
                                >
                                    <Input type="password"/>
                                </Form.Item>
                                <Form.Item
                                    rules={[{required: true}]}
                                    label="New Password"
                                    initialValue={null}
                                    name="newPassword"
                                >
                                    <Input type="password"/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </Row>
        </>
    )
};

export default PasswordForm;
