import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../components/layout/Section";
import RepresentativeForm from "./form";
import RepresentativeList from "./list";
import {Form} from "antd";
import {
    addRepresentative,
    deleteRepresentative,
    fetchRepresentatives,
    fetchRepresentative,
    updateRepresentative,
    resetRepresentativeSurgeonForm
} from "../../../redux/patient-management/representatives/actionCreator";
import {fetchProcedures} from "../../../redux/utilities/actionCreator";

const SupportiveData = () => {
    const representatives = useSelector(state => state.srSupportive.representatives);
    const representative = useSelector(state => state.srSupportive.representative);
    const utilitiesProcedures = useSelector(state => state.utilities.procedures);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchRepresentatives());
            // await dispatch(fetchProcedures());
        };
        fetchData().then(r => {
        });
    }, []);


    const getRepresentativeInfo = (_id) => dispatch(fetchRepresentative(_id, showModalEdit));

    const showModal = async type => {
        dispatch(resetRepresentativeSurgeonForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };


    const handleOk = async () => {
        await dispatch(resetRepresentativeSurgeonForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };
    return (
        <Section>
            <RepresentativeList
                state={state}
                setState={setState}
                showModal={showModal}
                isLoading={'isLoading'}
                representatives={representatives}
                getRepresentativeInfo={getRepresentativeInfo}
                deleteRepresentative={(deleteRepresentative)}
            />
            <RepresentativeForm
                state={state}
                form={form}
                procedures={utilitiesProcedures}
                getUtilityProcedureInfo={fetchProcedures}
                supportive={representative}
                addRepresentative={addRepresentative}
                updateRepresentative={updateRepresentative}
                handleOk={handleOk}
            />
        </Section>
    )
}

export default SupportiveData;