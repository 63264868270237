export class Constants {
    //local server
    // static BASE_ENDPOINT    = 'http://localhost:3030/';

    //live server
    static BASE_ENDPOINT    = 'https://api.ssnbe.cloud/';

    //lvni server
    // static BASE_ENDPOINT    = 'https://lvni-api.ssnbe.cloud/';

    //test server
    // static BASE_ENDPOINT    = 'https://test-api.ssnbe.cloud/';


    static CLIENT_ID        = 'demo-client';
    static CLIENT_SECRET    = 'demo-secret';

    static STORAGE_ACCESS_TOKEN     = 'accessToken';
    static STORAGE_REFRESH_TOKEN    = 'refreshToken';
    static STORAGE_USER_INFO        = 'userInfo';
    static STORAGE_USER_SCOPES      = 'userScopes';

    static USER_MANAGEMENT   = this.BASE_ENDPOINT + 'user-management/';
    static USERS         = this.USER_MANAGEMENT + 'users/';
    static USERS_CHECK         = this.USER_MANAGEMENT + 'users/check/';
    static USERS_SURGEONS         = this.USER_MANAGEMENT + 'users/surgeons/';
    static USERS_SURGEONS_FOR_PROFILE         = this.USER_MANAGEMENT + 'users/surgeons/';
    static USERS_PASS         = this.USER_MANAGEMENT + 'users/pass/';
    static UTILITIES    = this.BASE_ENDPOINT + 'utilities/';

    static AUTH                 = this.BASE_ENDPOINT + 'auth/';
    static PATIENT_MANAGEMENT   = this.BASE_ENDPOINT + 'patient-management/';

    static PROCEDURES       = this.PATIENT_MANAGEMENT + 'procedures/';
    static PROCEDURES_FOR_SCHEDULERS       = this.PATIENT_MANAGEMENT + 'procedures/schedulers/';
    static FACILITIES       = this.PATIENT_MANAGEMENT + 'facilities/';
    static FACILITIES_FOR_SCHEDULERS       = this.PATIENT_MANAGEMENT + 'facilities/schedulers/';
    static SURGEONS         = this.PATIENT_MANAGEMENT + 'surgeons/';
    static SURGEONS_FOR_SCHEDULER         = this.PATIENT_MANAGEMENT + 'surgeons/schedulers/';
    static SURGEONS_PROFILE        = this.PATIENT_MANAGEMENT + 'surgeons/profile/';
    static SURGEONS_IMAGE        = this.PATIENT_MANAGEMENT + 'surgeons/image/';
    static SURGEONS_USERS         = this.PATIENT_MANAGEMENT + 'surgeons/user/';
    static SUPPORTIVE         = this.PATIENT_MANAGEMENT + 'supportive/';
    static EMAIL         = this.PATIENT_MANAGEMENT + 'logs/';
    static PATIENTS         = this.PATIENT_MANAGEMENT + 'patients/';
    static PATIENTS_CSV         = this.PATIENT_MANAGEMENT + 'patients/csv/';
    static CHECK_PATIENTS   = this.PATIENT_MANAGEMENT + 'patients/check/';
    static CHART_ID_CHECK   = this.PATIENT_MANAGEMENT + 'chart/check/';
    static SCHEDULES        = this.PATIENT_MANAGEMENT + 'schedules/';
    static SCHEDULES_CANCEL        = this.PATIENT_MANAGEMENT + 'schedules/cancel/';
    static OTHER_PROCEDURE        = this.PATIENT_MANAGEMENT + 'schedules/procedure/';
    static DOCTOR_AVAILABILITY        = this.PATIENT_MANAGEMENT + 'schedules/doctor/availability/';
    static SCHEDULES_PDF        = this.PATIENT_MANAGEMENT + 'schedules/pdf';
    static SCHEDULES_PDF_INFO        = this.PATIENT_MANAGEMENT + 'schedules/pdf/get';
    static REPRESENTATIVE_EMAIL        = this.PATIENT_MANAGEMENT + 'schedules/email/';
    static REPRESENTATIVE_EMAIL_UNIQUE        = this.PATIENT_MANAGEMENT + 'schedules/unique/';
    static CHECK_SCHEDULES  = this.PATIENT_MANAGEMENT + 'schedules/check/';
    static WEEK_SCHEDULES  = this.PATIENT_MANAGEMENT + 'schedules/week/';
    static WEEK_SCHEDULES_FOR_SURGEONS  = this.PATIENT_MANAGEMENT + 'schedules/week/surgeon/';
    static CANCELLED_SCHEDULES_FOR_SURGEONS  = this.PATIENT_MANAGEMENT + 'schedules/cancelled/surgeon/';
    static ALL_SCHEDULES_FOR_SURGEONS  = this.PATIENT_MANAGEMENT + 'schedules/all/surgeon/';

    static SERVICES       = this.PATIENT_MANAGEMENT + 'services/';

    static ACTIVITIES       = this.UTILITIES + 'activities/';
    static ACTIVITY       = this.UTILITIES + 'activity/';

    static COUNTRIES    = this.PATIENT_MANAGEMENT + 'countries/';
    static COUNTRY      = this.PATIENT_MANAGEMENT + 'country/';
    static CASES        = this.PATIENT_MANAGEMENT + 'cases/';

    static CPT_CODES = [
        { value: '22551' },
        { value: '22552x2' },
        { value: '22853x2' },
        { value: '22552x3' },
        { value: '22853x3' },
        { value: '22552x4' },
        { value: '22853x4' },
        { value: '22845' },
        { value: '22853' },
        { value: '22845-59' },
        { value: '22856' },
        { value: 'L8699' },
        { value: '22857' },
        { value: '63045' },
        { value: '22840' },
        { value: '63048' },
        { value: '63048x2' },
        { value: '22842' },
        { value: '95940-26' },
        { value: '95926-26' },
        { value: '77002-26' },
        { value: '22855' },
        { value: '22318' },
        { value: '22315' },
        { value: '20661' },
        { value: '63020' },
        { value: '22210' },
        { value: '22216' },
        { value: '22843' },
        { value: '22612' },
        { value: '22614' },
        { value: '63047' },
        { value: '20930' },
        { value: '20936' },
        { value: '63047' },
        { value: '94940-26' },
        { value: '95926-26' },
        { value: '22614x2' },
        { value: '63048x3' },
        { value: '22558' },
        { value: '22851' },
        { value: '20930' },
        { value: '62287' },
    ];

    static ICD_10_CODES = [
        { value: 'M54.2' },
        { value: 'M54.12' },
        { value: 'M96.2' },
        { value: 'M47.12' },
        { value: 'M50.12' },
        { value: 'S12.9XXA' },
        { value: 'M48.06' },
        { value: 'M54.5' },
        { value: 'M54.16' },
        { value: 'M54.30' },
    ]
    static SHOW_FIELDS = [
        { value: 'patient' },
        { value: 'surgery' },
        { value: 'start_time' },
        { value: 'end_time' },
        { value: 'hospital' },
        { value: 'location' },
    ]

    static PATIENT_PACKETS = [
        { value: 'ACDF' },
        { value: 'XLIF_ALIF' },
        { value: 'PLIF' },
        { value: 'LUMBAR_DISCECTOMY' },
        { value: 'ARTHROPLASTY_(ARTIFICIAL_DISC TDR)' },
        { value: 'LAMINECTOMY_LAMINOTOMY' },
        { value: 'CHIARI_MALFORMATION' },
        { value: 'CARPAL_TUNNEL_RELEASE' },
        { value: 'CRANIOTOMY' },
        { value: 'DEEP_BRAIN_STIMULATOR' },
        { value: 'PITUITARY' },
        { value: 'BRAIN_BX' },
        { value: 'CARP' },
        { value: 'CORPECTOMY' },
        { value: 'CRANI' },
        { value: 'DBS' },
        { value: 'DISC' },
        { value: 'KYPHO' },
        { value: 'LP' },
        { value: 'NEUROFORAM' },
        { value: 'OCCIPITAL' },
        { value: 'POST_CERV_FUSION' },
        { value: 'PUMP' },
        { value: 'REMOVAL' },
        { value: 'SPINAL_CORD_STIMULATOR' },
        { value: 'SURAL_NERVE_BIOPSY' },
        { value: 'TLIF' },
        { value: 'TRIGEM' },
        { value: 'VATS_EDU' },
        { value: 'VNS' },
        { value: 'VP' },
        { value: 'WOUND' },
    ]

    static SUPPORT_TYPE = [
        { value: 'Representative' },

    ]

    static PROCEDURE_STATUS = [
        { value: 'ImagingNeededPriorToSurgery' },
        { value: 'neuroNavigationRep' },
        { value: 'dbsRep' },
        { value: 'scsOnRep' },
        { value: 'gliadelRep' },
        { value: 'shuntRep' },
        { value: 'neuroMonitoringRep' },
        { value: 'spineInstrumentRep' },
        { value: 'operatingMicroscope' },
        { value: 'fluoroscopy' },
        { value: 'ultrasound' },
        { value: 'ultrasonicAspirator' },
        { value: 'braceRep' },
        { value: 'boneGrowthSimRep' },
        { value: 'postOpMedsRep' },
        { value: 'urineDrugTestingRep' },
        { value: 'homesHealthRep' },
        { value: 'coSurgeon' },

    ]

    static PREP_IMAGING = [
        { value: 'Steinberg' },
        { value: 'Desert Radiology' },
        { value: 'Simon Med' },
        { value: 'Other' },
    ]
    static ORDER_FORM = [
        {value:""}
    ]
}
