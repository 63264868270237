import React, {useRef, useState} from "react"
import {Button, Input, PageHeader, Space, Table, Tag} from "antd";
import moment from "moment";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const ListData = ({activities,  getActivities, showModal}) => {

    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //

    const columns = [
        {
            title: 'Permission',
            dataIndex: 'permission',
            ...getColumnSearchProps('permission'),
            key: 'permission',
        },
        {
            title: 'Activity',
            dataIndex: 'activity',
            ...getColumnSearchProps('activity'),

            key: 'activity',
        },
        {
            title: 'User',
            dataIndex: 'userInString',
            ...getColumnSearchProps('userInString'),

            key: 'userInString',
        },
        {
            title: 'Date',
            dataIndex: 'logDate',
            ...getColumnSearchProps('logDate'),
            key: 'logDate',
        },
        {
            title: 'Time',
            render: (activity) => (
                <span className="pl-5">
                    {`${activity?.createdAt ? moment(activity?.createdAt).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'Show',
            render: (activity) => (
                <>
                    {
                        activity.permission.slice(-6) === "delete" ? (
                            ""
                        ) : (
                            <Tag color="green" className="myPointer" onClick={() => showModal({activity: activity.permission, _id: activity.fields.slice(-1)[0]?.value})}>
                                view
                            </Tag>
                        )
                    }
                </>
            )
        },
    ];

    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Activity Logs"
                subTitle={`List of all activities.`}
                onBack={() => window.history.back()}
            >
                <Table
                    pagination={{
                        defaultPageSize:activities?.perPage,
                        total: activities?.total,
                        current: activities?.page,
                        onChange: async (pageNo, perPageNo) => await getActivities(pageNo, perPageNo)
                    }}
                    rowKey="_id"
                    bordered={false}
                    className="table-responsive"
                    dataSource={activities?.data ? activities.data : ""}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
}
export default ListData;