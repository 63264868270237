import actions from "./actions";

const {FETCH_PROCEDURES, FETCH_PROCEDURE, RESET_PROCEDURE} = actions;
const initState = {
    procedures: [],
    procedure: {
        boneGrowthSimRep: false,
        braceRep: false,
        caseTimeHours: 0,
        cptCodes: [],
        dbsRep: false,
        diagnosis: null,
        fluoroscopy: false,
        homesHealthRep: false,
        icd10Codes: [],
        inOut: null,
        neuroNavigationRep: false,
        operatingMicroscope: false,
        postOpMedsRep: false,
        preopImaging: null,
        scsOnRep: false,
        gliadelRep: false,
        shuntRep: false,
        neuroMonitoringRep: false,
        spineInstrumentRep: false,
        surgery: null,
        ultrasonicAspirator: false,
        ultrasound: false,
        urineDrugTestingRep: false,
        _id: null
    },
};

const ProcedureReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_PROCEDURES:
            return {
                ...state,
                procedures: data
            }
        case RESET_PROCEDURE:
            return {
                ...state,
                procedure: initState.procedure,
            };
        case FETCH_PROCEDURE:
            return {
                ...state,
                procedure: data,
            };
        default:
            return state;
    }
}

export default ProcedureReducer;
