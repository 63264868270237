import { Constants } from "../../config/constants";
import { Requests as req } from "../../utils/requests";
import actions from './actions';

const {
    fetchServicesAction,
    fetchServiceAction,
    resetServiceFormAction,
} = actions;

export const addService = (formData, action, validate) => {
    return async dispatch => {
        await req.postRequest({url: Constants.SERVICES, auth: 'bearer', body: formData, validate: validate}, async (cb) => {
            await dispatch(fetchServices());
            await dispatch(action);
        })
    };
};

export const fetchServices = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.SERVICES,
            auth: 'bearer'
        }, (cb) => dispatch(fetchServicesAction(cb)))
    };
};

export const updateService = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({url: Constants.SERVICES + data._id, auth: 'bearer', body: data, validate: validate}, async (cb) => {
            await dispatch(fetchServices());
            await dispatch(action);
        })
    };
};

export const resetServiceForm = () => dispatch => dispatch(resetServiceFormAction());

export const fetchService = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.SERVICES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchServiceAction(cb));
            await dispatch(action);
        })
    };
};

export const deleteService = (id) => {
    return async dispatch => {
        await req.deleteRequest({url: Constants.SERVICES + id, auth: 'bearer'}, (cb) => dispatch(fetchServices()))
    };
};
