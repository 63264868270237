import React, { useRef, useState } from "react";
import { SearchOutlined } from '@ant-design/icons';
import FontAwesome from "react-fontawesome";
import FeatherIcon from "feather-icons-react";
import {
    Button,
    PageHeader,
    Row,
    Space,
    Table,
    Typography,
    Tag,
    Card, Input, Spin
} from "antd";
import {Alert} from "../../utils/alert";
import {Scope} from "../../services/scopeService";
import Highlighter from "react-highlight-words";
import moment from "moment";
import comment from "../../static/images/bubbles.png"

const { Title, Paragraph, Text, Link } = Typography;
const ServiceList = ({showModal, viewServiceInfo, isLoading, services, getServiceInfo, deleteService}) => {
    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //
    function add3Dots(string, limit)
    {
        var dots = "...";
        if(string.length > limit)
        {
            // you can also use substr instead of substring
            string = string.substring(0,limit) + dots;
        }

        return string;
    }

    const columns = [
        {
            title: 'Ticket ID',
            ...getColumnSearchProps('ticketId'),
            render: (service) => (
                <Typography>
                    <Tag keyboard={true} className="font-black mr-0">
                        {
                            service.lifeStatus === "inactive" ? (
                                <Text mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} level={2}>
                                    # {service?.ticketId ? service.ticketId : null}
                                </Text>
                            ) : (
                            <>
                                # {service?.ticketId ? service.ticketId : null}
                            </>
                            )
                        }
                    </Tag>
                </Typography>
            )
        },
        {
            title: 'Date Issued',
            ...getColumnSearchProps('date'),
            render: (service) => (
                <Typography>
                    <Text mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} level={2}>
                        {service?.createdAt ?  moment(service?.createdAt).format("MM-DD-YYYY") : null}
                    </Text>
                </Typography>
            )
        },
        {
            title: 'Created By',
            ...getColumnSearchProps('userName'),
            render: (service) => (
                <Typography>
                    <Text mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} level={2}>
                        {service?.userInfo ? service?.userInfo?._id?.personal?.firstName + " " + service?.userInfo?._id?.personal?.lastName : null}
                    </Text>
                </Typography>
            )
        },
        {
            title: 'Title',
            ...getColumnSearchProps('title'),
            render: (service) => (
                <Typography>
                    <Text mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"} level={2}>
                        {service?.title ? service.title : null}
                    </Text>
                </Typography>
            )
        },
        {
            title: 'Description',
            ...getColumnSearchProps('description'),
            render: (service) => (
                <Typography>
                    <Paragraph title={service?.description ? service.description : null} mark={service.lifeStatus === "inactive"} delete={service.lifeStatus === "inactive"}>
                        {service?.description ?  add3Dots(service?.description, 30) : null}
                    </Paragraph>
                </Typography>

            )
        },
        {
            title: 'Priority',
            ...getColumnSearchProps('priority'),
            render: (service) => (
                <>
                    <Tag className="mr-0" color={service?.priority === "high" ? "#f50" : service?.priority === "medium" ? "#108ee9" : "#87d068"}>
                        {service?.priority}
                    </Tag>
                </>
            )
        },
        {
            title: 'Status',
            ...getColumnSearchProps('status'),
            render: (service) => (
                <>
                    <Tag className="mr-0" color={service?.lifeStatus !== "active" ? "yellow" : service?.status === "pending" ? "red" : service?.status === "processing" ? "cyan" : "green"}>
                        {service?.lifeStatus === "active" ? service?.status : "cancelled"}
                        <small>{service?.status === "completed" ? service?.completedDate ? " on " + moment(service.completedDate).format("MM-DD-YYYY") : null : ""}</small>
                    </Tag>
                    {
                        service?.comments ? (
                            <Space direction="vertical">
                                <Text className="image-comment" style={{lineHeight: "0.5", fontSize: "11px"}}
                                      title={service?.comments}
                                      type="secondary">
                                    <img src={comment} alt=""/>
                                    {add3Dots(service?.comments, 30)}
                                </Text>
                            </Space>
                        ) : ""
                    }
                </>
            )
        },
        {
            title: 'Action',
            render: (service) => <>
                {Scope.checkScopes(['records_services_view']) && (
                    <FontAwesome
                        onClick={() => viewServiceInfo(service?._id)}
                        name={"eye"}
                        className="color-info mr-1"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', padding: "5px", cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['records_services_update']) && (
                    service?.lifeStatus === "active" ?
                    <FontAwesome
                        onClick={() => getServiceInfo(service?._id)}
                        name={"edit"}
                        className="color-info mr-1"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    /> : ""
                )}
                {Scope.checkScopes(['records_services_delete']) && (
                    service?.lifeStatus === "active" && service.status !== "completed" && service.status !== "processing" ?
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deleteService(service?._id)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                        : ""
                )}
            </>
        },
    ];

    const headerButtons = [
        <div key="1" className="page-header-actions">
            {Scope.checkScopes(['records_services_create']) && (
                <Button size="small" type="primary" onClick={() => showModal('primary')}>
                    <Row>
                        <FeatherIcon icon="plus" size={14}/>
                        Add New
                    </Row>
                </Button>
            )}
        </div>,
    ];
    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Services"
                subTitle={`List of all ${services?.length} services.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                <Table
                    pagination={{pageSize: 50, showSizeChanger: false}}
                    rowKey="_id"
                    bordered={false}
                    className="table-responsive"
                    loading={!services?.length ? <Spin  className="example"/> : ""}
                    dataSource={services? services : ""}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
}

export default ServiceList;