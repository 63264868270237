import React, {useEffect, useState} from "react"
import Section from "../../components/layout/Section";
import ListData from "./list";
import {useDispatch, useSelector} from "react-redux";
import {fetchActivities, fetchActivity} from "../../redux/activity/actionCreator";
import View from "./view";

const ActivityData = () => {

    const activities = useSelector(state => state.pmActivities.activities);
    const activity = useSelector(state => state.pmActivities.activity);
    const dispatch = useDispatch();
    const [showModalState, setShowModalState] = useState({ showModal: false });

    const getActivities = async (page, perPage) => await dispatch(fetchActivities({page, perPage}));

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchActivities({page: 1, perPage: 30}))
        };
        fetchData().then(r => {
        });
    }, []);

    const showModal = async (data) => {
        // const trimmedData = data.slice(0, 19)
        const trimmedData = data.activity.slice(0, -6)
        Object.assign(data, {trimmedData})
        await dispatch(fetchActivity(data))
        setShowModalState({...showModalState, showModal: true})
    }
    return (
        <Section>
           <ListData
               activities={activities}
               getActivities={getActivities}
               showModal={showModal}
           />
            <View
                activities={activity}
                showModalState={showModalState}
                handleOk={() => setShowModalState({...showModalState, showModal: false})}
            />
           {/* <TestApp />*/}
        </Section>
    )
}
export default ActivityData;