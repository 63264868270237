const actions = {
    FETCH_PATIENTS: 'FETCH_PATIENTS',
    FETCH_PATIENT: 'FETCH_PATIENT',
    CHECK_PATIENT: 'CHECK_PATIENT',
    RESET_PATIENT: 'RESET_PATIENT',
    FETCH_STATES: 'FETCH_STATES',
    FETCH_CITIES: 'FETCH_CITIES',
    FETCH_ZIPCODES: 'FETCH_ZIPCODES',
    RESET_CODES: 'RESET_CODES',
    FETCH_INSURED_CITIES: 'FETCH_INSURED_CITIES',
    FETCH_INSURED_ZIPCODES: 'FETCH_INSURED_ZIPCODES',

    fetchPatientsAction: (data) => {
        return {
            type: actions.FETCH_PATIENTS,
            data: data
        };
    },
    resetPatientFormAction: () => {
        return {
            type: actions.RESET_PATIENT,
            data: null
        };
    },
    fetchPatientAction: (data) => {
        return {
            type: actions.FETCH_PATIENT,
            data: data
        };
    },
    checkPatientAction: (data) => {
        return {
            type: actions.CHECK_PATIENT,
            data: data
        };
    },
    fetchStatesAction: (data) => {
        return {
            type: actions.FETCH_STATES,
            data: data
        };
    },
    fetchCitiesAction: (data) => {
        return {
            type: actions.FETCH_CITIES,
            data: data
        };
    },
    fetchZipCodesAction: (data) => {
        return {
            type: actions.FETCH_ZIPCODES,
            data: data
        };
    },
    fetchInsuredCitiesAction: (data) => {
        return {
            type: actions.FETCH_INSURED_CITIES,
            data: data
        };
    },
    fetchInsuredZipCodesAction: (data) => {
        return {
            type: actions.FETCH_INSURED_ZIPCODES,
            data: data
        };
    },
    resetZipCodesFormAction: () => {
        return {
            type: actions.RESET_CODES,
            data: null
        };
    },
}

export default actions;
