import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Form, Input, Modal, Select, Upload } from "antd";
import { Alert } from "../../utils/alert";

const ServiceForm = ({ form, state, service, addService, updateService, isLoading, handleOk }) => {
    const dispatch = useDispatch();
    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

    // File Upload
    const [fileList, setFileList] = useState([]);
    const [serviceForm] = Form.useForm();

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        const type = file.type.split("/")[0];
        if (type === "image") {
            let src = file.url || file.thumbUrl;
            if (!src) {
                src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                });
            }
            const image = new Image();
            image.src = src;
            const imgWindow = window.open(src);
            imgWindow?.document.write(image.outerHTML);
        } else if (type === "application" && file.url) {
            window.open(file.url, "_blank");
        } else {
            // Handle other file types if needed
        }
    };

    const beforeUpload = (file) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
        const isAllowed = allowedTypes.includes(file.type);

        if (!isAllowed) {
            Alert.warning({ title: `Invalid file type. Please upload only .jpg, .png, .jpeg, or .pdf files.` });
        }

        return isAllowed;
    };

    const validateMessages = {
        required: "${label} is required!",
    };
    // End File Upload

    const handleSubmit = async (data) => {
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i].originFileObj;
            if (file && file.size > 2000000) {
                return Alert.warning({ title: `photo-${i + 1} exceeded 2MB` });
            }
        }

        const formData = new FormData();

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i].originFileObj;
            if (file) {
                formData.append(`file${i + 1}`, file);
            }
        }

        // ... rest of your formData.append logic

        if (service?._id) {
            Object.assign(data, { _id: service._id });

            await dispatch(
                updateService(data, handleOk, form.setFields, async (event) => {
                    await serviceForm.setFields(event);
                })
            );
            await setFileList([]);
        } else {
            for (var key in data) {
                formData.append(key, data[key]);
            }

            await dispatch(
                addService(formData, handleOk, form.setFields, async (event) => {
                    await serviceForm.setFields(event);
                })
            );
            await setFileList([]);
        }
    };

    const footerButtons = [
        <Button form="myForm" key="submit" htmlType="submit" type="primary" disabled={isLoading} size="small">
            {service && service?._id ? "Update" : "Submit"}
        </Button>,
    ];

    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Service Form"
                width={800}
                visible={state.visible}
                onCancel={() => handleOk()}
                footer={footerButtons}
            >
                <Form
                    {...layout}
                    name={"serviceForm"}
                    form={form}
                    id={"myForm"}
                    size="large"
                    validateMessages={validateMessages}
                    onFinish={handleSubmit}
                >
                    <Col md={22}>
                        <br />
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={onPreview}
                            accept=".jpg,.jpeg,.png,.pdf"
                            beforeUpload={beforeUpload}
                        >
                            {fileList.length < 5 && "+ Upload"}
                        </Upload>
                        <Form.Item
                            name="title"
                            rules={[{required: true}]}
                            initialValue={service?.title ? service.title : ''}
                            label="Title"
                        >
                            <Input disabled={!!(service && service?._id)} placeholder="Title"/>
                        </Form.Item>
                        <Form.Item
                            name="description"
                            initialValue={service?.description ? service.description : null}
                            rules={[{required: true}]}
                            label="Description">
                            <Input.TextArea
                                disabled={!!(service && service?._id)}
                                autoSize={{ minRows: 10, maxRows: 20 }}
                                placeholder="Description"
                            />
                        </Form.Item>
                        <Form.Item
                            name="priority"
                            initialValue={service?.priority ? service.priority : ''}
                            rules={[{required: true}]}
                            label="Priority">
                            <Select disabled={!!(service && service?._id)}>
                                <Select.Option value="">Select</Select.Option>
                                <Select.Option value="high">High</Select.Option>
                                <Select.Option value="medium">Medium</Select.Option>
                                <Select.Option value="low">Low</Select.Option>
                            </Select>
                        </Form.Item>
                        {
                            service && service?._id ? (
                                <>
                                    <Form.Item
                                        name="status"
                                        initialValue={service?.status ? service.status : ''}
                                        rules={[{required: true}]}
                                        label="Status">
                                        <Select>
                                            <Select.Option value="">Select</Select.Option>
                                            <Select.Option value="pending">Pending</Select.Option>
                                            <Select.Option value="processing">Processing</Select.Option>
                                            <Select.Option value="completed">Completed</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="comments"
                                        initialValue={service?.comments ? service.comments : null}
                                        rules={[{required: false}]}
                                        label="Comments">
                                        <Input.TextArea
                                            autoSize={{ minRows: 7, maxRows: 20 }}
                                            placeholder="Comments"
                                        />
                                    </Form.Item>
                                </>
                            ) : ""
                        }
                    </Col>
                </Form>
            </Modal>
        </Col>
    );
};

export default ServiceForm;
