import dayjs from "dayjs";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import GlobalContext from "./context/GlobalContext";
import moment from "moment";
import {Timeline, Tooltip, Row, Badge, Card, Space, Avatar, Switch} from "antd";
import {ClockCircleOutlined, DownloadOutlined, DeleteOutlined, StopOutlined, MinusCircleOutlined  } from "@ant-design/icons";
import {useReactToPrint} from 'react-to-print';
import {gapi} from "gapi-script";
import * as ics from "ics";
import abcd from "../../../../static/images/b.png";
import "./css/switch.css"
export default function Day({day, rowIdx, surgeon, getThePrint, viewScheduleInfo, showModal, doctorAvailability, getDoctorAvailabilities, availabilities}) {
    const screenWidth = window.innerWidth;
    //Print
    const componentRef = useRef();
    const dispatch = useDispatch();
    const handlePrint = useReactToPrint({
        content: () =>
            componentRef.current,
        documentTitle: 'Daily Schedules',
        pageStyle: "@media print {\n" +
            "  @page { size: portrait; margin: 8mm; }\n" +
            "}",
    })

    //End Print
    // Google Calender
    var CLIENT_ID = "455104623071-9kgio2j9m5mjcsbrttqqlv4ub5u73hjc.apps.googleusercontent.com"
    var API_KEY = "AIzaSyCjx236RdpLS7RlLCE90cQznJBGByY5ZCs"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const handleClick = (id) => {
        const abc = filteredEvents?.find((item) => item.id === id)

        const theSurgery = abc?.surgery ? abc.surgery : null
        const theSurgeon = abc?.surgeon
        const hospitalName = abc?.hospitalName
        const hospitalLocation = abc?.hospitalLocation
        const endTime = abc?.endTime
        const startTime = abc?.startTime
        const date = moment(abc?.scheduleDate).format("YYYY-MM-DD")
        const startDate = `${date}T${startTime}:00-07:00`
        const endDate = `${date}T${endTime}:00-07:00`

        gapi.load('client:auth2', () => {
            console.log('loaded client')

            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
                plugin_name: "chat",
            })

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {

                    var event = {
                        'summary': theSurgery,
                        'location': hospitalName,
                        'description': 'SSNBE Surgery Schedule',
                        'start': {
                            'dateTime': startDate,
                            'timeZone': 'America/Los_Angeles'
                        },
                        'end': {
                            'dateTime': endDate,
                            'timeZone': 'America/Los_Angeles'
                        },
                        'recurrence': [
                            'RRULE:FREQ=DAILY;COUNT=1'
                        ],
                        'attendees': [],
                        'reminders': {
                            'useDefault': false,
                            'overrides': []
                        }
                    }

                    var request = gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': event,
                    })

                    request.execute(event => {
                        console.log(event)
                        window.open(event.htmlLink)
                    })


                    /*
                        Uncomment the following block to get events
                    */
                    /*
                    // get events
                    gapi.client.calendar.events.list({
                      'calendarId': 'primary',
                      'timeMin': (new Date()).toISOString(),
                      'showDeleted': false,
                      'singleEvents': true,
                      'maxResults': 10,
                      'orderBy': 'startTime'
                    }).then(response => {
                      const events = response.result.items
                      console.log('EVENTS: ', events)
                    })
                    */


                })
        })
    }
    // End Google Calender

    const [dayEvents, setDayEvents] = useState([]);

    const onChange = (checked, day) => {
        dispatch(doctorAvailability(day, cb => {
            if (cb) dispatch(getDoctorAvailabilities())
        }))};

    const {
        setDaySelected,
        setShowEventModal,
        filteredEvents,
        setSelectedEvent,
    } = useContext(GlobalContext);

    const [childHover, setChildHover] = useState(false);
    useEffect(() => {
        const events = filteredEvents.filter(
            (evt) =>
                dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
        );

        const surgeonSchedule = filteredEvents.filter(
            (evt) =>
                evt?.surgeonId?.includes(surgeon?._id ? surgeon._id : surgeon?.data?._id) && dayjs(evt.day).format("DD-MM-YY") === day.format("DD-MM-YY")
        );
        surgeon?.data?._id || surgeon?._id ? setDayEvents(surgeonSchedule) : setDayEvents(events);
    }, [filteredEvents, day, surgeon?.data?._id, surgeon?._id]);

    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? "bg-blue-600 text-white rounded-full w-7"
            : "";
    }
    const [getPrint, setPrint] = useState(false)
    const showPrint =() => {
        setPrint(true)
    }
    const hidePrint =() => {
        setPrint(false)
    }

    return (
        <div ref={componentRef} onMouseOver={showPrint} onMouseLeave={hidePrint} className="border border-gray-200 flex flex-col" style={!getThePrint ? { minHeight: '100px', minWidth: "10px"} : {minHeight: '10px'}}>
            {/*{*/}
            {/*    getPrint && componentRef ? (<PrinterOutlined className="text-right" onClick={handlePrint} />) : null*/}
            {/*}*/}

            <header className="flex flex-col items-center">
                {(rowIdx === 0 || screenWidth < 700) && (
                    <p className={`text-sm ${!getThePrint ? "mt-1": ""}`}>
                        {
                            screenWidth < 700 && getPrint ? (day.format("MM-DD-YYYY")) : day.format("ddd").toUpperCase()
                        }
                    </p>
                )}
                <p
                    className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`}
                >
                    {
                        screenWidth > 700 && getPrint ? (day.format("MM-DD-YYYY") + " (" + day.format("dddd")) + ") " : day.format("DD")
                    }

                </p>
            </header>
            <div
                className="flex-1 cursor-pointer"
                onClick={() => {
                    if (!childHover) {
                        const isDayOff = availabilities && availabilities.length && availabilities?.find(mDay => dayjs(mDay.day).format("DD-MM-YYYY") === day.format("DD-MM-YYYY"));

                        console.log("isDayOff", isDayOff);
                        setDaySelected(day);
                        if (!isDayOff) {
                            showModal('primary', day)
                        }
                    }
                }}
            >

                {!getThePrint ? (<br/>) : null}

                {
                  dayEvents.length ? (
                      dayEvents.map((evt, idx) => {
                          // Download ICS
                          const gotToDownload = () => {
                              if (dayEvents?.length) {
                                  const d = new Date();
                                  let diff = d.getTimezoneOffset();
                                  const printDate = diff > 0 ?
                                      moment(`${evt?.scheduleDate.substring(0, 10)}`).subtract(1, "days").format('MM-DD-YYYY') :
                                      moment(`${evt?.scheduleDate.substring(0, 10)}`).format('MM-DD-YYYY')

                                  const start = diff > 0 ?
                                      moment(`${evt?.scheduleDate.substring(0, 10)} ${evt?.startTime}`).subtract(1, "days").format('YYYY-M-D-H-m') :
                                      moment(`${evt?.scheduleDate.substring(0, 10)} ${evt?.startTime}`).format('YYYY-M-D-H-m')
                                  // const start = moment(`${evt?.scheduleDate.substring(0, 10)} ${evt?.startTime}`).format('YYYY-M-D-H-m')
                                  // console.log((start.split("-")).map(x => parseInt(x)))
                                  // console.log((start.split("-")).map(x => Number(x)))
                                  // console.log((start.split("-")).map(x => +x))
                                  // const startTimeHours = parseInt(cb?.startTime?.slice(0, 2));
                                  // const startTimeMinutes = parseInt(cb?.endTime?.slice(-2));
                                  const abc = evt?.caseTime

                                  const event = {
                                      start: (start.split("-")).map(x => parseInt(x)),
                                      duration: {
                                          minutes: abc*60
                                      },
                                      title: `${evt?.surgery}`,
                                      description: `Surgeon: ${evt?.surgeon} | patient : ${evt?.patient}`,
                                      location: `${evt?.hospitalName}`,
                                      // url: 'http://www.bolderboulder.com/',
                                      // geo: { lat: 40.0095, lon: 105.2669 },
                                      // categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
                                      // status: 'CONFIRMED',
                                      // busyStatus: 'BUSY',
                                      // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
                                      // attendees: [
                                      //     { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
                                      //     { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
                                      // ]
                                  }

                                  ics.createEvent(event, (error, value) => {
                                      if (error) {
                                          console.log(error)
                                      }

                                      // window.open( "data:text/calendar;charset=utf8," + escape( value ) );

                                      var link = document.createElement("a");
                                      link.href = 'data:text/calendar;charset=utf8,' + encodeURIComponent(value);
                                      link.download = `${printDate + "-" + evt?.surgery}.ics`;
                                      link.click();
                                  })
                              }
                          }
                          function add3Dots(string, limit)
                          {
                              var dots = "...";
                              if(string.length > limit)
                              {
                                  // you can also use substr instead of substring
                                  string = string.substring(0,limit) + dots;
                              }

                              return string;
                          }

                          //End ICS
                          return (
                              <>
                                  <Tooltip
                                      placement="topLeft"
                                      color={"cyan"}
                                      title={
                                          <Fragment>
                                              <Timeline>
                                                  <Timeline.Item color="cyan"><b>{`Patient Name: `}</b>{evt?.patient}</Timeline.Item>
                                                  <Timeline.Item color="cyan"><b>{`Surgery: `}</b>{evt?.surgery}</Timeline.Item>
                                                  <Timeline.Item color="cyan"><b>{`Surgeon: `}</b>{evt?.surgeon}</Timeline.Item>
                                                  {
                                                      evt?.coSurgeon?.length ? (
                                                          <Timeline.Item color="cyan"><b>{`Co Surgeon: `}</b>{evt?.coSurgeon}</Timeline.Item>
                                                      ) : null
                                                  }
                                                  <Timeline.Item  dot={
                                                      <ClockCircleOutlined
                                                          style={{
                                                              fontSize: '16px',
                                                          }}
                                                      />
                                                  } color="cyan"><b>{`Start Time: `}</b>{moment(evt?.startTime, ["HH.mm"]).format("hh:mm a")}</Timeline.Item>
                                                  <Timeline.Item  dot={
                                                      <ClockCircleOutlined
                                                          style={{
                                                              fontSize: '16px',
                                                          }}
                                                      />
                                                  } color="cyan"><b>{`End Time: `}</b>{moment(evt?.endTime, ["HH.mm"]).format("hh:mm a")}</Timeline.Item>
                                                  <Timeline.Item color="cyan"><b>{`Hospital: `}</b>{evt?.hospitalName}</Timeline.Item>
                                                  <Timeline.Item color="cyan"><b>{`Location: `}</b>{evt?.hospitalLocation}</Timeline.Item>
                                                  <DownloadOutlined className="float-end" onMouseEnter={() => setChildHover(true)} onClick={gotToDownload}/>
                                              </Timeline>
                                          </Fragment>
                                      }
                                  >
                                      <div
                                          key={idx}
                                          // onClick={() => setSelectedEvent(evt)}
                                          // onClick={() => getScheduleInfo(evt.id)}
                                          onMouseEnter={() => setChildHover(true)}
                                          onMouseLeave={() => setChildHover(false)}
                                          onClick={() => {
                                              if (childHover) {
                                                  viewScheduleInfo(evt.id)
                                              }
                                          }}
                                          className={`bg-${!getThePrint ? evt.label : ""}-200 pr-0 pb-0 pt-1 pl-1  mr-1 text-gray-600 text-sm rounded ${!getThePrint ? "mb-1": "mb-0"} truncate`}
                                      >
                                          {
                                              !getThePrint ? (
                                                  <>
                                                      {/*<Button*/}
                                                      {/*    size="small"*/}
                                                      {/*    className="mr-0 border-transparent bg-transparent"*/}
                                                      {/*    icon={ <FeatherIcon icon="calendar" size={15}/>}*/}
                                                      {/*    onClick={() => handleClick(evt.id)}*/}
                                                      {/*>*/}
                                                      {/*</Button>*/}
                                                      {/*{!evt?.hideFields?.filter(hide => hide === "patient").length ? (*/}
                                                      {/*    <>*/}
                                                      {/*        <small><b>Patient: </b></small> {evt?.patient}*/}
                                                      {/*        <br/>*/}
                                                      {/*    </>*/}
                                                      {/*): null}*/}
                                                      {!evt?.hideFields?.filter(hide => hide === "surgery").length ? (
                                                          <div>
                                                              {
                                                                  evt?.scheduleCancelStatus ? (

                                                                      <Row>
                                                                          <Badge size="small" dot="dot" />
                                                                          { evt?.surgeryLength?.length > 1 ? (<img src={abcd} alt="logo" style={{height: 10, marginTop: 2, marginRight: 1}}/>) : ""} <small style={{fontSize:"10px", fontFamily: 'Shantell Sans', color:"#080318"}}><>{moment(evt?.startTime, ["HH.mm"]).format("hh:mma")}</> </small><small style={{fontSize:"11px", marginTop: 0, marginLeft: 1, color:"black"}} >{evt?.surgery ? " " + add3Dots(evt?.surgery, 17) : null}</small>
                                                                      </Row>

                                                                  ) : (
                                                                      <Row>
                                                                          { evt?.surgeryLength?.length > 1 ? (<img src={abcd} alt="logo" style={{height: 10, marginTop: 2, marginRight: 1}}/>) : ""} <small style={{fontSize:"10px", fontFamily: 'Shantell Sans', color:"#080318"}}><>{moment(evt?.startTime, ["HH.mm"]).format("hh:mma")}</> </small><small style={{fontSize:"11px", marginTop: 0, marginLeft: 1, color:"black"}} >{evt?.surgery ? " " + add3Dots(evt?.surgery, 17) : null}</small>
                                                                      </Row>
                                                                  )
                                                              }

                                                          </div>
                                                      ): null}

                                                  </>
                                              ) : (
                                                  <>
                                                      <div>
                                                          {
                                                              evt?.scheduleCancelStatus ? (

                                                                  <Row>
                                                                      <Badge size="small" dot="dot" />
                                                                      { evt?.surgeryLength?.length > 1 ? (<img src={abcd} alt="logo" style={{height: 10, marginTop: 2, marginRight: 1}}/>) : ""} <small style={{fontSize:"10px", color:"#080318"}}><>{moment(evt?.startTime, ["HH.mm"]).format("hh:mma")}</> </small><small style={{fontSize:"11px", marginTop: 0, marginLeft: 1, color:"black"}} >{evt?.surgery ? " " + add3Dots(evt?.surgery, 17) : null}</small>
                                                                  </Row>

                                                              ) : (
                                                                  <Row>
                                                                      { evt?.surgeryLength?.length > 1 ? (<img src={abcd} alt="logo" style={{height: 10, marginTop: 2, marginRight: 1}}/>) : ""} <small style={{fontSize:"10px", color:"#080318"}}><>{moment(evt?.startTime, ["HH.mm"]).format("hh:mma")}</> </small><small style={{fontSize:"11px", marginTop: 0, marginLeft: 1, color:"black"}} >{evt?.surgery ? " " + add3Dots(evt?.surgery, 17) : null}</small>
                                                                  </Row>
                                                              )
                                                          }
                                                      </div>
                                                  </>
                                              )
                                          }
                                      </div>
                                  </Tooltip>
                              </>
                          )
                      })
                  ) : (
                      <div className="pl-5">
                          {
                              availabilities && availabilities.length && availabilities?.find(mDay => dayjs(mDay.day).format("DD-MM-YYYY") === day.format("DD-MM-YYYY")) ? (
                                  <span style={{color: "red", fontSize: "11"}}>Doctor is not Available!</span>
                              ) : ""
                          }
                      </div>
                  )
                }
            </div>

            {!dayEvents.length ? (
                <Switch
                    checked={!!availabilities?.find(mDay => dayjs(mDay.day).format("DD-MM-YYYY") === day.format("DD-MM-YYYY"))}
                    size={"small"}
                    onChange={(checked) => onChange(checked, day)} // Pass the day along with the checked status
                    className={ availabilities && availabilities.length && availabilities?.find(mDay => dayjs(mDay.day).format("DD-MM-YYYY") === day.format("DD-MM-YYYY")) ? "customBCS button-size" : "button-size"}
                />
            ) : ""}

        </div>
    );
}
