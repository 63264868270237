import React, {useEffect, useState} from 'react';
import { Row, Col, Form, Select, PageHeader, Button, Checkbox} from 'antd';
import { Cards } from '../../components/cards/frame/cards-frame';
import Section from "../../components/layout/Section";
import { useDispatch, useSelector } from 'react-redux';
import {fetchRoles} from "../../redux/userManagement/roles/actionCreator";
import { fetchRolesPermissions, saveRolesPermissions } from '../../redux/userManagement/rolesPermissions/actionCreator';
import {Scope} from "../../services/scopeService";

const Permissions = () => {
    const dispatch = useDispatch();
    const roles = useSelector(state => state.umRoles.roles);

    const [state, setState] = useState({
        checked: [],
        permissions: [],
        roleId: '',
    });

    const handleChange = (value) => {
        if (fetchRolesPermissions)
            dispatch(fetchRolesPermissions(value, {state, setState}));
    };

    const setRolesPermissions = (permissions) => {
        if (saveRolesPermissions)
            dispatch(saveRolesPermissions({roleId: state.roleId, permissions}));
    };

    const multipleChange = (checked, permissions, permissionIndex) => {
        let statePermissions = state.permissions;
        let permissionChecked = [];

        statePermissions[permissionIndex].checked = checked;
        statePermissions.forEach((permission) => permissionChecked = [...permissionChecked, ...permission.checked]);
        setState({ ...state, permissions: statePermissions, checked: permissionChecked });
    };

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchRoles())
        };
        fetchData().then(r => {});
    }, []);

    return (
        <>
            <Section>
                <PageHeader ghost title="Permissions" />
                {Scope.checkScopes(['administration_permissions_index']) && (
                    <>
                        <Form
                            name="basic"
                            labelCol={{
                                span: 8,
                            }}
                            wrapperCol={{
                                span: 16,
                            }}
                            style={{
                                maxWidth: 300,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="role"
                                initialValue={''}
                                label="Select Role">
                                <Select onChange={handleChange}>
                                    {roles?.data?.map((data) => <Select.Option value={data._id} key={data._id}>{data.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Form>

                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                {state.permissions && state.permissions.length > 0 ? (
                                    <Cards title={'Permissions'}>
                                        {state.permissions.map((data, index) => (
                                            <div key={index}>
                                                {index !== 0 ? <><br/><br/></> : ''}
                                                <div style={{ borderBottom: '1px solid #E9E9E9', marginBottom: '10px'}}>
                                                    <Checkbox
                                                        indeterminate={data.checked.length && data.checked.length < data.permissions.length}
                                                        onChange={(event) => multipleChange((data.permissions.length === data.checked.length ? [] : data.permissions.map((el) => el.value)), data.permissions, index)}
                                                        checked={data.checked.length === data.permissions.length}
                                                    >
                                                        {data.group}
                                                    </Checkbox>
                                                    {/*{data.group}*/}
                                                </div>

                                                <Checkbox.Group
                                                    options={data.permissions}
                                                    value={data.checked}
                                                    onChange={(event) => multipleChange(event, data.permissions, index)} />
                                            </div>
                                        ))}
                                        <div className="text-right">
                                            <br/>
                                            <Button
                                                type="primary"
                                                htmlType="button"
                                                onClick={() => setRolesPermissions(state.checked)}>
                                                Submit
                                            </Button>
                                        </div>
                                    </Cards>
                                ) : ''}
                            </Col>
                        </Row>
                    </>
                )}
            </Section>
        </>
    );
};

export default Permissions;
