import React from 'react';
import {Layout} from "antd";

const { Content: ContentSec } = Layout;

const Content = ({children}) => {
    return (
        <ContentSec style={{ margin: '16px' }}>
            <div className="site-layout-background" style={{ padding: 5, minHeight: 360, borderRadius: 5 }}>
                {children}
            </div>
        </ContentSec>
    )
}

export default Content;