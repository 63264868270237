import actions from "./actions";

const {FETCH_REPRESENTATIVES, FETCH_REPRESENTATIVE, RESET_REPRESENTATIVE, FETCH_LOGS, FETCH_CANCEL_LOGS} = actions;
const initState = {
    representatives: [],
    representative: {
        _id: null,
        name: null,
        status: null,
        description: null
    },
    logs: [],
    cancelLogs: [],
};

const RepresentativeReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_REPRESENTATIVES:
            return {
                ...state,
                representatives: data
            }
        case RESET_REPRESENTATIVE:
            return {
                ...state,
                representative: initState.representative,
            };
        case FETCH_REPRESENTATIVE:
            return {
                ...state,
                representative: data,
            };
        case FETCH_LOGS:
            return {
                ...state,
                logs: data
            }
        case FETCH_CANCEL_LOGS:
            return {
                ...state,
                cancelLogs: data
            }
        default:
            return state;
    }
}

export default RepresentativeReducer;
