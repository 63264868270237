import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {Button, Col, Divider, Form, Input, Modal, Row, Tag, Tooltip, Select, Space, Dropdown, Menu, Avatar} from "antd";
import {
    LogoutOutlined,
    PlusOutlined,
    RadarChartOutlined,
    MinusCircleOutlined,
    MinusSquareOutlined,
    PrinterOutlined
} from '@ant-design/icons';
import moment from 'moment';
import "./calender/css/form.css"
import TextArea from "antd/lib/input/TextArea";
import {Alert} from "../../../utils/alert";
import ReactToPrint, {useReactToPrint} from 'react-to-print';
import {Constants} from "../../../config/constants";
import {Scope} from "../../../services/scopeService";
import { TimePicker } from 'antd';
import FontAwesome from "react-fontawesome";
import {gapi} from "gapi-script";
import View from "./view/view";

const ScheduleForm = (
    {
        state,
        showPrint,
        setShowPrint,
        setState,
        getSchedule,
        setSchedule,
        setModalVisible,
        setScheduleVisible,
        form,
        getUsers,
        schedule,
        addSchedule,
        updateSchedule,
        updateOneSchedule,
        deleteSchedule,
        checkSchedule,
        viewScheduleOk,
        patients,
        fetchFilteredPatients,
        utilitiesPatients,
        surgeons,
        coSurgeons,
        getPatientPacketsPDF,
        sendRepresentativeEmail,
        sendRepresentativeEmailId,
        cancelSchedule,
        facilities,
        procedures,
        isLoading,
        emails,
        cancelEmails,
        handleOk,
        getPatientInfo,
        getProcedureInfo,
        getScheduleInfo,
        getPdfOrderInfo,

    }
) => {

    const checkEmail = {
        emails: emails?.filter((item) =>
            item.scheduleDate.includes(schedule?.schedule?.scheduleDate) &&
            item.surgery.includes(schedule?.schedule?.procedure?.surgery) &&
            item.startTime.includes(schedule?.schedule?.startTime) &&
            item.endTime.includes(schedule?.schedule?.endTime)
        ),
    }
    const checkCancelEmail = {
        cancelEmails: cancelEmails?.filter((item) =>
            item.scheduleDate.includes(schedule?.schedule?.scheduleDate) &&
            item.surgery.includes(schedule?.schedule?.procedure?.surgery) &&
            item.startTime.includes(schedule?.schedule?.startTime) &&
            item.endTime.includes(schedule?.schedule?.endTime)
        ),
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Schedule',
        pageStyle: "@media print {\n" +
            "  @page { size: portrait; margin: 2.5mm; }\n" +
            "}",
    })

    const gotPrint = async () => {
        await setShowPrint(true)
        await handlePrint()
    }

    const filteredSurgeon = surgeons?.filter((item) => item?.userInfo?._id === getUsers?._id)

    const filteredEmails = schedule?.getTheAllEmails?.filter((i,p)=>schedule.getTheAllEmails.indexOf(i)===p)

    // Google Calender
    var CLIENT_ID = "455104623071-9kgio2j9m5mjcsbrttqqlv4ub5u73hjc.apps.googleusercontent.com"
    var API_KEY = "AIzaSyCjx236RdpLS7RlLCE90cQznJBGByY5ZCs"
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
    var SCOPES = "https://www.googleapis.com/auth/calendar.events"

    const handleClick = (data) => {

        const theSurgeon = surgeons.find((item) => item._id === data.surgeon)
        const thePatient = patients.find((item) => item._id === data.patientId)
        const theFacility = facilities.find((item) => item._id === data.facility)
        const getSurgery = procedures.filter((item) => data.procedure.includes(item._id))
        const theSurgery = getSurgery.map((item)=> item.surgery).join(", ")
        const desWithPatient = "SSNBE Surgery Schedule " + " | " + "Patient: " + thePatient?.firstName + " " +thePatient.lastName
        const hospitalName = theFacility?.name
        const endTime = data?.endTime
        const startTime = data?.startTime
        const date = moment(data?.date).format("YYYY-MM-DD")
        const startDate = `${date}T${startTime}:00-08:00`
        const endDate = `${date}T${endTime}:00-08:00`

        gapi.load('client:auth2', () => {
            console.log('loaded client')

            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
                plugin_name: "chat",
            })

            gapi.auth2.getAuthInstance().signIn()
                .then(() => {

                    var event = {
                        'summary': theSurgery,
                        'location': hospitalName,
                        'description': desWithPatient,
                        'start': {
                            'dateTime': startDate,
                            'timeZone': 'America/Los_Angeles'
                        },
                        'end': {
                            'dateTime': endDate,
                            'timeZone': 'America/Los_Angeles'
                        },
                        'recurrence': [
                            'RRULE:FREQ=DAILY;COUNT=1'
                        ],
                        'attendees': [],
                        'reminders': {
                            'useDefault': false,
                            'overrides': []
                        }
                    }

                    var request = gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': event,
                    })

                    request.execute(event => {
                        Alert.success({title: "Successfully added to the google calender"})
                        // window.open(event.htmlLink)
                    })

                    /*
                        Uncomment the following block to get events
                    */
                    /*
                    // get events
                    gapi.client.calendar.events.list({
                      'calendarId': 'primary',
                      'timeMin': (new Date()).toISOString(),
                      'showDeleted': false,
                      'singleEvents': true,
                      'maxResults': 10,
                      'orderBy': 'startTime'
                    }).then(response => {
                      const events = response.result.items
                      console.log('EVENTS: ', events)
                    })
                    */


                })
        })
    }
    // End Google Calender

    const dispatch = useDispatch();
    const {Option} = Select;
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}};
    const validateMessages = {required: '${label} is required!'};
    let printAreaRef = useRef(null);
    const [ICDShow, setICDShow] = useState(false)
    const [CPTShow, setCPTShow] = useState(false)
    const [checkTheEmail, setCheckTheEmail] = useState(false)
    const [caseTime, showCaseTime] = useState({time: ""})
    const [currentEmails, setCurrentEmails] = useState({sentEmails: []})
    const [value, setValue] = useState('');
    const [procedureValue, setProcedureValue] = useState("");
    const [surgeonName, setSurgeonName] = useState("")
    const [coSurgeonName, setCoSurgeonName] = useState("")
    const [procedureName, setProcedureName] = useState("")

    const cancelEmail = "cancelEmail"

    useEffect(() => {
        setCurrentEmails({sentEmails: schedule?.getTheAllEmails ? schedule.getTheAllEmails : []})
    },[schedule?.schedule?._id])

    const updateCurrentEmails = () => {
        setCurrentEmails({
            ...currentEmails, sentEmails: form.getFieldsValue().emailDetails
        })
    }
    const updateTheCurrentEmails = () => {
        setCurrentEmails({
            ...currentEmails, sentEmails: form.getFieldsValue().theEmailDetails
        })
    }

    const [items, setItems] = useState([
        'Pending',
        'Not Needed',
    ]);
    const [name, setName] = useState('');
    const inputRef = useRef(null);

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItem = (e) => {
        e.preventDefault();
        setName('');
        form.setFieldsValue({
            authorization: name
        })
    };
    //
    const REG_EXP =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Email Validation
    const validateEmail = (currentEmails) => {
        return form.getFieldsValue().theEmailDetails ? form.getFieldsValue().theEmailDetails.map((element) =>element.match(REG_EXP)) : null;
    };

    const validateAnotherEmail = (currentEmails) => {
        return form.getFieldsValue().emailDetails ? form.getFieldsValue().emailDetails.map((element) =>element.match(REG_EXP)) : null;
    };

    useEffect(() => {
        validateEmail()?.some(el => el == null) ? Alert.warning({title: "An invalid Email in the Queue"}) : setCheckTheEmail(false)
    },[form.getFieldsValue()?.theEmailDetails])

    useEffect(() => {
        validateAnotherEmail()?.some(el => el == null) ? Alert.warning({title: "An invalid Email in the Queue"}) : setCheckTheEmail(false)
    },[form.getFieldsValue()?.emailDetails])

    // validateEmail()?.length?.some(el => el == null) ? Alert.warning({title: "An invalid Email in the Queue"}) : Alert.warning({title: " Email in the"})

    // End Validation

    const mainSurgeonCheck = async (_id) => {
        const validMainSurgeon = await surgeons.filter((item, i) => item._id === _id)
        const getMainSurgeonNames = await validMainSurgeon?.map((item) => item.firstName + " " + item.lastName)?.join(", ")
        setSurgeonName(getMainSurgeonNames)
    }
    const surgeonCheck = async (ids) => {
        const validCoSurgeons = await surgeons.filter(obj1 => ids.find(obj2 => obj1._id === obj2))
        const getCoNames = await validCoSurgeons?.map((item) => item.firstName + " " + item.lastName)?.join(", ")
        setCoSurgeonName(getCoNames)
        // const filteredResults = surgeons.filter(item => item._id === ids.some((id, i) => id  ));
        // console.log(filteredResults)

        if (!form.getFieldsValue().surgeon) Alert.warning({title: "Please select the default surgeon first!"})
        if (form.getFieldsValue().surgeon) {
            const co = form.getFieldsValue().coSurgeon
            const main = form.getFieldsValue().surgeon
            const abc =  co?.filter((item) => item.includes(main))
            if(abc?.length) Alert.warning({title: "Default Surgeon can not be CO-Surgeon"})
        }
    }

    const handleSubmit = async (data) => {
        if (!form.getFieldsValue().surgeon) Alert.warning({title: "Please select the default surgeon first!"})
        if (form.getFieldsValue().surgeon) {
            const co = form.getFieldsValue().coSurgeon
            const main = form.getFieldsValue().surgeon
            const abc =  co?.filter((item) => item.includes(main))
            if(abc?.length) {
                Alert.warning({title: "Default Surgeon can not be CO-Surgeon"})
            } else {
                const docDate = data.date

                const uniqueId = Math.floor(Math.random() * Date.now()).toString()
                Object.assign(data, {startTime: data?.startTime.format("HH:mm"), uniqueId: uniqueId})
                const setDate = new Date(data.date)
                const addADay = setDate.getTime() + 24 * 60 * 60 * 1000
                const d = new Date();
                let diff = d.getTimezoneOffset();
                let dayAdd = null ;
                diff > 0 ? dayAdd = addADay : dayAdd = data.date

                const setPreOPDate = new Date(form.getFieldsValue().preOpDate)
                const addPreOPADay = setPreOPDate.getTime() + 24 * 60 * 60 * 1000
                const preOPd = new Date();
                let preOPDiff = preOPd.getTimezoneOffset();
                let dayPreOPAdd = null ;
                preOPDiff > 0 ? dayPreOPAdd = addPreOPADay : dayPreOPAdd = form.getFieldsValue().preOpDate

                const setPostOPDate = new Date(form.getFieldsValue().postOpDate)
                const addPostOPADay = setPostOPDate.getTime() + 24 * 60 * 60 * 1000
                const postOPd = new Date();
                let postOPDiff = postOPd.getTimezoneOffset();
                let dayPostOPAdd = null ;
                postOPDiff > 0 ? dayPostOPAdd = addPostOPADay : dayPostOPAdd = form.getFieldsValue().postOpDate

                const userInfo = {
                    userId: getUsers?._id,
                    userFirstName: getUsers?.personal?.firstName,
                    userLastName: getUsers?.personal?.lastName,
                    userEmail: getUsers?.email,
                    userUserName: getUsers?.username,
                    userPhone: getUsers?.personal?.phone,
                    userRole: getUsers?.role?.name,
                }
                Object.assign(data, {date: dayAdd, preOpDate: dayPreOPAdd, postOpDate: dayPostOPAdd, docDate})
                Object.assign(data, {surgeonName, coSurgeonName, procedureName})
                Object.assign(data, {userInfo, currentEmails: currentEmails.sentEmails})
                if (schedule?.schedule?._id ) {
                    Object.assign(data, {_id: schedule?.schedule?._id});
                    // await handleClick(data)
                    await dispatch(updateSchedule(data, handleOk, form.setFields))
                    await setICDShow(!ICDShow)
                    await setCPTShow(!CPTShow)
                    await showCaseTime({...caseTime, time: ''})
                } else {
                    if (!validateEmail()?.some(el => el == null)) {
                        await dispatch(addSchedule(data, handleOk, form.setFields))
                        // setTimeout(() => {
                        //     sendRepresentativeEmailId({uniqueId: uniqueId, contentData: data, procedureId: schedule?.schedule?.procedure?._id, currentEmails: currentEmails, userInfo: getUsers})
                        //     // .then(() => {
                        //     //     handleClick(data)
                        //     //     // Alert.success({title: "Email Sent"})
                        //     // })
                        // }, 3000);

                        await setICDShow(!ICDShow)
                        await setCPTShow(!CPTShow)
                        await showCaseTime({...caseTime, time: ''})
                    }
                    if (validateEmail()?.some(el => el == null)) {
                        Alert.warning({title: "An invalid Email in the Queue"})
                    }
                }
            }
            }
        }

    const ICDShowHide = () => {
        setICDShow(!ICDShow)
    }
    const CPTShowHide = () => {
        setCPTShow(!CPTShow)
    }

    const onPatientChange = async (_id) => {
        if (_id) {
            await dispatch(getPatientInfo(_id, (data) => {
                form.setFieldsValue({
                    patient: "",
                    firstName: data?.firstName + " " + data?.lastName,
                    fstName: data?.firstName + " " + data?.lastName,
                    phone: data?.phone,
                    patientId: data?._id,
                    insuranceName: data?.lastInsurance?.insurance,
                    insuredPolicyNumber: data?.lastInsurance?.insuredPolicyNumber,
                    dob: moment(data?.dob).format('MM-DD-YYYY'),
                    dobDate: moment(data?.dob).format('MM-DD-YYYY')
                });
            }))
        }
    }

    const getValidated = async (event) => {
        const docDate = form.getFieldsValue().date
        const setDate = new Date(form.getFieldsValue().date)
        const addADay = setDate.getTime() + 24 * 60 * 60 * 1000
        const d = new Date();
        let diff = d.getTimezoneOffset();
        let dayAdd = null ;
        diff > 0 ? dayAdd = addADay : dayAdd = form.getFieldsValue().date

        if (!form.getFieldsValue().date) Alert.warning({title: "Please select a date!"})
        if (!form.getFieldsValue().startTime) Alert.warning({title: "Please select start time!"})
        if (!form.getFieldsValue().surgeon) Alert.warning({title: "Please select a surgeon!"})
        if (form.getFieldsValue().date && form.getFieldsValue().startTime && form.getFieldsValue().endTime && form.getFieldsValue().surgeon) {
            const data = {
                _id: schedule?.schedule?._id,
                startTime: form.getFieldsValue().startTime?.format("HH:mm"),
                endTime: form.getFieldsValue().endTime,
                date: dayAdd,
                docDate: docDate,
                surgeonId: form.getFieldsValue().surgeon
            }
            await dispatch(checkSchedule(data, form.setFields));
        } else form.setFieldsValue({endTime: null})
    }
    const onProcedureChange = async (_id) => {
        const validProcedures = await procedures.filter(obj1 => _id?.find(obj2 => obj1._id === obj2))
        const getProcedures = await validProcedures?.map((item) => item.surgery)?.join(", ")
        setProcedureName(getProcedures)

        if (!form.getFieldsValue().procedure.length) Alert.warning({title: "Please select Procedure!"})
        const checkStartTime = form.getFieldsValue().startTime ? form.getFieldsValue().startTime : null
        const checkSurgeon = form.getFieldsValue().surgeon ? form.getFieldsValue().surgeon : null
        if (checkStartTime && checkSurgeon && _id.length) {
            await dispatch(getProcedureInfo({proID:_id}, (data) => {
                const hours = data?.Procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => partialSum + a, 0);
                const icd10Codes = data?.Procedure?.map((get)=> get.icd10Codes).flat();
                const filteredIcd10Codes = icd10Codes.filter((i,p)=>icd10Codes.indexOf(i)===p);

                const cptCodes = data?.Procedure?.map((get)=> get.cptCodes).flat();
                const filteredCptCodes = cptCodes.filter((i,p)=>cptCodes.indexOf(i)===p);

                const minutes = hours * 60
                const startTime = form.getFieldsValue().startTime?.format("HH:mm")
                const newTime = new Date(new Date("1970/01/01 " + startTime).getTime() + minutes * 60000).toLocaleTimeString('en-UK', { hour: '2-digit', minute: '2-digit', hour12: false });
                form.setFieldsValue({
                    icd10Codes: filteredIcd10Codes ? filteredIcd10Codes : null,
                    cptCodes: filteredCptCodes ? filteredCptCodes : null,
                    repAndInstruments: data?.filteredNES?.length ? data.filteredNES : null,
                    theEmailDetails: data?.onlyEmails?.length ? data.onlyEmails.filter((i,p)=>data.onlyEmails.indexOf(i)===p) : null,
                    endTime: newTime ? newTime : null,
                });
                showCaseTime({...caseTime, time: hours})
                setCurrentEmails({...currentEmails, sentEmails: data?.onlyEmails.filter((i,p)=>data.onlyEmails.indexOf(i)===p)})
            }))
        }
    }
    const startTimeChange = async () => {
        const _id = form.getFieldsValue().procedure ? form.getFieldsValue().procedure : null
        if(_id.length) {
            await dispatch(getProcedureInfo({proID:_id}, (data) => {
                const hours = data?.Procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => partialSum + a, 0);
                const icd10Codes = data?.Procedure?.map((get)=> get.icd10Codes).flat();
                const filteredIcd10Codes = icd10Codes.filter((i,p)=>icd10Codes.indexOf(i)===p);

                const cptCodes = data?.Procedure?.map((get)=> get.cptCodes).flat();
                const filteredCptCodes = cptCodes.filter((i,p)=>cptCodes.indexOf(i)===p);

                const minutes = hours * 60
                const startTime = form.getFieldsValue().startTime?.format("HH:mm")
                const newTime = new Date(new Date("1970/01/01 " + startTime).getTime() + minutes * 60000).toLocaleTimeString('en-UK', { hour: '2-digit', minute: '2-digit', hour12: false });
                form.setFieldsValue({
                    icd10Codes: filteredIcd10Codes ? filteredIcd10Codes : null,
                    cptCodes: filteredCptCodes ? filteredCptCodes : null,
                    repAndInstruments: data?.filteredNES?.length ? data.filteredNES : null,
                    theEmailDetails: data?.onlyEmails?.length ? data.onlyEmails.filter((i,p)=>data.onlyEmails.indexOf(i)===p) : null,
                    endTime: newTime ? newTime : null,
                });
                showCaseTime({...caseTime, time: hours})
                setCurrentEmails({...currentEmails, sentEmails: data?.onlyEmails.filter((i,p)=>data.onlyEmails.indexOf(i)===p)})
            }))
        }
    }

    const onStartTimeBlur =  () => {
        const _id = form.getFieldsValue().procedure ? form.getFieldsValue().procedure : null
        if (_id) {
            getValidated()
        }
    }

    const screenWidth = window.innerWidth;


    const footerButtons = [
        <>
            {Scope.checkScopes(['records_scheduleSurgery_delete']) && (
                <>
                    {schedule?.schedule?._id ?
                        <Button size="small" type="danger"
                                onClick={() => Alert.confirm({action: deleteSchedule(schedule?.schedule?._id,{cancelEmail: cancelEmail, currentEmails: currentEmails, userInfo: getUsers, procedureValue: procedureValue}, dispatch(handleOk))})}>
                            Cancel Schedule
                        </Button>
                        : ""}
                </>
            )}
            <Button
                form="myForm"
                key="submit"
                htmlType="submit"
                type="primary"
                disabled={isLoading}
                size="small">
                {isLoading ? 'Loading...' : 'Submit'}
            </Button>
        </>
    ];
    const theItems = schedule?.schedule?.procedure?.map((get, i) => (
        <Menu.Item target="_blank" key={i} onClick={() => getPatientPacketsPDF({_id: schedule?.schedule?._id, patientPackets: get?.patientPackets, procedureValue: procedureValue})}>
            {get?.patientPackets}
        </Menu.Item>
    ))

    const ids = theItems?.map((item) => item.props.children)
    const theFilteredItem = theItems?.filter(({props}, index) => !ids.includes(props.children, index + 1))
    const menu = (
        <Menu>
            {theFilteredItem}
        </Menu>
    );

    const sendPepEmail = () => {
        if (!validateAnotherEmail()?.some(el => el == null)) {
            sendRepresentativeEmail(schedule?.schedule?._id, {procedureId: schedule?.schedule?.procedure?._id, currentEmails: currentEmails, userInfo: getUsers, procedureValue: procedureValue})
        } else {
            Alert.warning({title: "An invalid Email in the Queue"})
        }
    }

    const sendCancelRepEmail = () => {
        if (!validateAnotherEmail()?.some(el => el == null)) {
            sendRepresentativeEmail(schedule?.schedule?._id, {procedureId: schedule?.schedule?.procedure?._id, cancelEmail: cancelEmail, currentEmails: currentEmails, userInfo: getUsers, procedureValue: procedureValue})
        } else {
            Alert.warning({title: "An invalid Email in the Queue"})
        }
    }
    const cancellation = () => {
        cancelSchedule(schedule?.schedule?._id)
    }

    const viewFooterButtons = [
        <>
            {schedule?.schedule?._id ?
                (
                    <>
                        <Row md={24}>
                            <Col md={12}>
                                <div style={{textAlign:"left"}}>
                                    {Scope.checkScopes(['records_scheduleSurgery_send_email']) && (
                                        <Fragment>
                                            {
                                                schedule?.schedule?.scheduleCancelStatus === null ? (
                                                    <>
                                                        <Button size="small" className="mb-2" type="primary" style={{alignItems: "flex-start"}}
                                                                onClick={() => sendPepEmail()}>
                                                            Send Email
                                                        </Button>
                                                        <Button size="small" className="mb-2" type="primary" style={{alignItems: "flex-start"}}
                                                                onClick={() => sendCancelRepEmail()}>
                                                            Cancel Email
                                                        </Button>
                                                    </>
                                                ) : (
                                                   ""
                                                )
                                            }

                                        </Fragment>
                                    )}

                                    {Scope.checkScopes(['records_scheduleSurgery_get_pdf']) && (
                                        schedule?.schedule?.scheduleCancelStatus === null ? (
                                            <span size="small" className="mb-2" style={{alignItems: "flex-start"}}>
                                            <Dropdown.Button size="small" className="ml-2" overlay={menu}>
                                                Patient Packets
                                            </Dropdown.Button>
                                        </span>
                                        ) : (
                                            ""
                                        )
                                    )}
                                </div>
                            </Col>
                            <Col md={12}>
                                {Scope.checkScopes(['records_scheduleSurgery_get_pdf']) && (
                                    <>
                                        {
                                            schedule?.schedule?.scheduleCancelStatus === null && schedule?.schedule?.facility?.code ?
                                                <Button size="small" type="primary"
                                                        onClick={() => getPdfOrderInfo({_id: schedule.schedule._id, hospitalCode: schedule?.schedule?.facility?.code, procedureValue: procedureValue})}>
                                                    Order Form
                                                </Button>
                                                : null
                                        }
                                    </>
                                )}
                                {Scope.checkScopes(['records_scheduleSurgery_update']) && (
                                    schedule?.schedule?.scheduleCancelStatus === null ? (
                                        <Button size="small" type="danger"
                                                onClick={() => {
                                                    getScheduleInfo(schedule.schedule._id)
                                                    showCaseTime({...caseTime, time: schedule.schedule.procedure.length ? schedule.schedule.procedure.map((get)=> get.caseTimeHours).reduce((partialSum, a) => +partialSum + +a) : ""})
                                                }
                                                }>
                                            Edit
                                        </Button>
                                    ) : (
                                            ""
                                        )
                                )}
                                {
                                    schedule?.schedule?.scheduleCancelStatus === null ? (
                                        <PrinterOutlined className="text-right ml-5" onClick={gotPrint} />
                                    ) : (
                                        ""
                                    )
                                }
                            </Col>
                        </Row>

                    </>
                )

                : ""}
        </>
    ];

    return (
        <>
            <Row md={12}>
                {Scope.checkScopes(['records_scheduleSurgery_create']) && (
                    <Modal
                        type={state.modalType}
                        title={
                        <>
                            SSNBE  ( Scheduling Form )
                            <Button
                                onClick={() => {
                                    setState({...state, visible: false})
                                    setModalVisible(true)
                                }}
                                title="Minimize"
                                className="float-right mr-8"
                                icon={<MinusSquareOutlined />}
                                disabled={isLoading}
                                size="middle"
                            >
                            </Button>
                        </>
                    }
                        visible={state.visible}
                        onCancel={() => { handleOk(); showCaseTime({...caseTime, time: ''});}}
                        footer={footerButtons}
                        width={1350}
                    >

                        <Form
                            {...layout}
                            name={'userForm'}
                            form={form}
                            id={'myForm'}
                            validateMessages={validateMessages}
                            onFinish={handleSubmit}>
                            <br/>
                            <Col md={4}>
                                <div key="1" className="page-header-action">
                                </div>
                                <Form.Item
                                    hidden={true}
                                    name="patientId"
                                    rules={[{required: true}]}
                                    initialValue={schedule?.schedule?.patient?._id ? schedule.schedule.patient._id : ""}
                                    label="Patient ID"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Row md={22}>
                                <Col md={8}>
                                </Col>
                                <Col md={16}>
                                    <Row>
                                        <Form.Item
                                            name="fstName"
                                            initialValue={schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}
                                            label="Patient's Name"
                                        >
                                            <Input readOnly={true} size="small"
                                                   style={{border: 0, fontSize: "large", fontWeight: "bold"}}
                                                   placeholder="Name"/>
                                        </Form.Item>
                                        <Form.Item
                                            name="dobDate"
                                            initialValue={schedule?.schedule?.patient?.dob ? moment(schedule.schedule.patient.dob).format('MM-DD-YYYY') : ""}
                                            label="Date of birth"
                                        >
                                            <Input readOnly={true} size="small"
                                                   style={{border: 0, fontSize: "large", fontWeight: "bold"}}
                                                   placeholder="Date of Birth"/>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Row>
                            <Col md={22}>
                                <Form.Item
                                    name="patient"
                                    label="Search"
                                >
                                    <Select
                                        showSearch
                                        placeholder="FirstName / LastName / Chart ID / Phone / Email"
                                        loading={isLoading}
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        onSearch={(event) => event?.length && event.length > 2 ? dispatch(fetchFilteredPatients({name: event})) : null}
                                        onChange={onPatientChange}
                                    >
                                        {patients?.map((patient, si) => (
                                            <Select.Option key={si} value={patient._id}>
                                                {patient?.firstName + " " + patient?.lastName + " - " + " " + " Chart ID: " + (patient?.chartId ? patient?.chartId : "not available") + " - " + " " + " Phone: " + patient?.phone + " - " + " " + " Email: " + patient?.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Row style={screenWidth < 700 ? {display: "grid"} : null}>
                                <Col md={11}>
                                    <Form.Item
                                        name="surgeon"
                                        rules={[{required: true}]}
                                        initialValue={schedule?.schedule?.surgeon?._id ? schedule.schedule.surgeon._id : ""}
                                        label="Surgeon"
                                    >
                                        {
                                            filteredSurgeon?.length ? (
                                                <Select onChange={mainSurgeonCheck}>
                                                    {filteredSurgeon?.map((surgeon, si) => (
                                                        <Select.Option key={si} value={surgeon._id}>{surgeon.firstName} {surgeon.lastName}</Select.Option>
                                                    ))}
                                                </Select>
                                            ) : (
                                                <Select onChange={mainSurgeonCheck}>
                                                    {surgeons?.map((surgeon, si) => (
                                                        <Select.Option key={si} value={surgeon._id}>{surgeon.firstName} {surgeon.lastName}</Select.Option>
                                                    ))}
                                                </Select>
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        name="coSurgeon"
                                        rules={[{required: false}]}
                                        initialValue={schedule?.schedule?.coSurgeon.map((val, i) =>val._id ) ? schedule.schedule.coSurgeon.map((val, i) =>val._id )  : []}
                                        label="CO Surgeon"
                                    >
                                        <Select mode="multiple" onChange={surgeonCheck}>
                                            {coSurgeons?.map((surgeon, si) => (
                                                <Select.Option key={si} value={surgeon._id}>{surgeon.firstName} {surgeon.lastName}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="date"
                                        initialValue={schedule?.schedule?.date ? moment(schedule.schedule.date).format('YYYY-MM-DD') : ""}
                                        rules={[{required: true}]}
                                        label="Surgery Date"
                                    >
                                        <Input
                                            type="date"
                                            value={schedule?.schedule?.date ? moment(schedule.schedule.date).format('YYYY-MM-DD') : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="startTime"
                                        initialValue={schedule?.schedule?.startTime ? moment(schedule.schedule.startTime, "hh:mm") : ""}
                                        rules={[{required: true}]}
                                        label="Start Time"
                                    >
                                        <TimePicker
                                            minuteStep={15}
                                            use12Hours
                                            onChange={startTimeChange}
                                            onBlur={onStartTimeBlur}
                                            initialValue={schedule?.schedule?.startTime ? moment(schedule.schedule.startTime, "hh:mm") : null}
                                            format={'hh:mm a'}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="procedure"
                                        initialValue={schedule?.schedule?.procedure.map((val, i) =>val._id ) ? schedule.schedule.procedure.map((val, i) =>val._id )  : []}
                                        rules={[{required: true}]}
                                        label="Surgery Name"
                                    >
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            onChange={onProcedureChange}
                                            onBlur={getValidated}
                                            placeholder="Select Procedure"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {procedures ? procedures.map((procedure, pi) => (
                                                <Select.Option
                                                    key={pi}
                                                    value={procedure._id}
                                                >
                                                    {procedure.surgery}
                                                </Select.Option>
                                            )) : ""}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Case Time Hours"
                                        initialValue={schedule?.schedule?.procedure?.caseTimeHours ? schedule?.schedule?.procedure?.caseTimeHours : ""}
                                    >
                                        <Input
                                            readOnly={true} disabled={true}
                                            value={caseTime.time ? caseTime.time : "" }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="endTime"
                                        initialValue={schedule?.schedule?.endTime ? schedule.schedule.endTime : ""}
                                        rules={[{required: true}]}
                                        label="End Time"
                                    >
                                        <Input
                                            type="time"
                                            readOnly={true} disabled={true}
                                            value={schedule?.schedule?.endTime ? schedule.schedule.endTime : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="icd10Codes"
                                        initialValue={schedule?.schedule?.icd10Codes ? schedule.schedule.icd10Codes : []}
                                        rules={[{required: true}]}
                                        label={
                                            <Fragment>
                                                <span className="mr-2">ICD Codes</span> <FontAwesome
                                                onClick={ICDShowHide}
                                                name={"fa-regular fa-lock"}
                                                className="color-lock"
                                                size="2x"
                                                style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                                            />
                                            </Fragment>
                                        }
                                    >
                                        <Select mode="tags" readOnly={true} disabled={ICDShow ? false : true}>
                                            {Constants.ICD_10_CODES.map((code, ci) => (
                                                <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="cptCodes"
                                        initialValue={schedule?.schedule?.cptCodes ? schedule.schedule.cptCodes : []}
                                        rules={[{required: true}]}
                                        label={
                                            <Fragment>
                                                <span className="mr-2">CPT Codes</span> <FontAwesome
                                                onClick={CPTShowHide}
                                                name={"fa-regular fa-lock"}
                                                className="color-lock"
                                                size="2x"
                                                style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                                            />
                                            </Fragment>
                                        }
                                    >
                                        <Select mode="tags" readOnly={true} disabled={CPTShow ? false : true}>
                                            {Constants.CPT_CODES.map((code, ci) => (
                                                <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="facility"
                                        initialValue={schedule?.schedule?.facility?._id ? schedule.schedule.facility._id : ""}
                                        rules={[{required: true}]}
                                        label="Facilities"
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select a Facility"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {facilities ? facilities.map((facility, fi) => (
                                                <Select.Option
                                                    key={fi}
                                                    value={facility._id}
                                                >
                                                    {facility.name}
                                                </Select.Option>
                                            )) : ""}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="repAndInstruments"
                                        rules={[{required: false}]}
                                        initialValue={schedule?.filteredNES ? schedule.filteredNES : ""}
                                        label="Reps. & Instruments"
                                    >
                                        <TextArea
                                            readOnly={true}
                                            disabled={true}
                                            placeholder="Reps. & Instruments"
                                            autoSize={{ minRows: 2, maxRows: 20 }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="details"
                                        rules={[{required: false}]}
                                        initialValue={schedule?.schedule?.details ? schedule.schedule.details : ""}
                                        label="Other details"
                                    >
                                        <TextArea
                                            placeholder="details"
                                            autoSize={{ minRows: 2, maxRows: 20 }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="theEmailDetails"
                                        initialValue={filteredEmails ? filteredEmails : schedule?.getTheAllEmails}
                                        label="REPRESENTATIVE EMAIL:"
                                    >
                                        <Select onBlur={validateEmail} mode="tags" onChange={updateTheCurrentEmails}>
                                            {schedule?.allNamesAndEmails?.map((rep, ci) => (
                                                <Select.Option key={ci} value={rep.repEmail}>
                                                    <Tooltip color={"red"} placement="topLeft" title={rep?.repFirstName + " " + rep?.repLastName}>
                                                        {rep.repEmail}
                                                    </Tooltip>
                                                </Select.Option>

                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={11}>
                                    <Form.Item
                                        name="firstName"
                                        initialValue={schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}
                                        rules={[{required: true}]}
                                        label="Name"
                                    >
                                        <Input readOnly={true} disabled={true} placeholder="Name"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        initialValue={schedule?.schedule?.patient?.phone ? schedule.schedule.patient.phone : ""}
                                        rules={[{required: true}]}
                                        label="Phone"
                                    >
                                        <Input readOnly={true} disabled={true} placeholder="Phone"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="dob"
                                        initialValue={schedule?.schedule?.patient?.dob ? moment(schedule.schedule.patient.dob).format('MM-DD-YYYY') : ""}
                                        rules={[{required: true}]}
                                        label="Date of birth"
                                    >
                                        <Input readOnly={true} disabled={true} placeholder="Date of birth"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="insuranceName"
                                        initialValue={schedule?.schedule?.insuranceName ? schedule.schedule.insuranceName : ""}
                                        label="Insurance Provider"
                                    >
                                        <Input readOnly={true} disabled={true} placeholder="Insured Name"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="insuredPolicyNumber"
                                        initialValue={schedule?.schedule?.insuredPolicyNumber ? schedule.schedule.insuredPolicyNumber : ""}
                                        label="Insured Policy Number"
                                    >
                                        <Input readOnly={true} disabled={true} size={"large"} placeholder="Insured Policy Number"/>
                                    </Form.Item>
                                    <Form.Item
                                        name="hideFields"
                                        initialValue={schedule?.schedule?.hideFields ? schedule.schedule.hideFields : []}
                                        label="Hide Fields for Calender"
                                    >
                                        <Select mode="tags">
                                            {Constants.SHOW_FIELDS.map((code, ci) => (
                                                <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="authorization"
                                        rules={[{required: true}]}
                                        initialValue={schedule?.schedule?.authorization ? schedule.schedule.authorization : ""}
                                        label="Authorization Code"
                                    >
                                        <Select
                                            placeholder="Authorization"
                                            dropdownRender={(menu) => (
                                                <>
                                                    {menu}
                                                    <Divider
                                                        style={{
                                                            margin: '8px 0',
                                                        }}
                                                    />
                                                    <Space
                                                        style={{
                                                            padding: '0 8px 4px',
                                                        }}
                                                    >
                                                        <Input
                                                            placeholder="Custom Code"
                                                            ref={inputRef}
                                                            value={name}
                                                            onChange={onNameChange}
                                                        />
                                                        <Button className="btn-sm" type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                            Add
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        >
                                            {items.map((item) => (
                                                <Option key={item}>{item}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="preOpDate"
                                        initialValue={schedule?.schedule?.preOpDate ? moment(schedule.schedule.preOpDate).format('YYYY-MM-DD') : ""}
                                        rules={[{required: false}]}
                                        label="Pre-Op Date"
                                    >
                                        <Input
                                            type="date"
                                            value={schedule?.schedule?.preOpDate ? moment(schedule.schedule.preOpDate).format('YYYY-MM-DD') : ""}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="postOpDate"
                                        initialValue={schedule?.schedule?.postOpDate ? moment(schedule.schedule.postOpDate).format('YYYY-MM-DD') : ""}
                                        rules={[{required: false}]}
                                        label="Post-Op Date"
                                    >
                                        <Input
                                            type="date"
                                            value={schedule?.schedule?.postOpDate ? moment(schedule.schedule.postOpDate).format('YYYY-MM-DD') : ""}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                )}
            </Row>
            <Modal
                type={getSchedule.modalType}
                title={
                <>
                    View Schedule
                    <Button
                        onClick={() => {
                            setSchedule({...getSchedule, visible: false})
                            setScheduleVisible(true)
                        }
                    }
                        title="Minimize"
                        className="float-right mr-8"
                        icon={<MinusSquareOutlined />}
                        disabled={isLoading}
                        size="middle"
                    >
                    </Button>
                </>
            }
                visible={getSchedule.visible}
                onCancel={() => viewScheduleOk()}
                footer={viewFooterButtons}
                width={1000}
            >
                <Col md={24}>
                    <div>
                        <form ref={componentRef}>
                            {/*<div className="banner">*/}
                            {/*    /!*<img className="mainLogo" src={logo} alt="Logo"/>*!/*/}
                            {/*    <span className="titleFont" style={{fontFamily: "sans-serif"}}><Avatar size="large" className="mr-2" src={schedule?.schedule?.surgeon?.logo ? schedule.schedule.surgeon.logo : null}/> Surgery Schedule</span>*/}
                            {/*</div>*/}
                            {/*<br/>*/}
                            <div>
                                {screenWidth < 700 ? (
                                    <View
                                        showPrint={showPrint}
                                        schedule={schedule}
                                        layout={layout}
                                        form={form}
                                        setProcedureValue={setProcedureValue}
                                        updateOneSchedule={updateOneSchedule}
                                        filteredEmails={filteredEmails}
                                        setValue={setValue}
                                        validateAnotherEmail={validateAnotherEmail}
                                        updateCurrentEmails={updateCurrentEmails}
                                    />
                                    // <>
                                    //     <table className="table-bordered table-responsive-sm"
                                    //            style={{fontSize: "15px", paddingRight: "5px"}} cellSpacing="0"
                                    //            cellPadding="0">
                                    //         <tbody>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>HOSPITAL:</span>
                                    //                 {schedule?.schedule?.facility?.name ? schedule.schedule.facility.name : null}
                                    //                 <br/>
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //                <span style={{paddingTop: "0px"}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px",
                                    //             }}>PROCEDURE:</span>
                                    //                 <Form
                                    //                     {...layout}
                                    //                     form={form}
                                    //                     className="textAreaPadding"
                                    //                     layout="horizontal"
                                    //                     labelCol={{ span: 7 }}
                                    //                     wrapperCol={{ span: 50 }}
                                    //                 >
                                    //                     <Form.Item
                                    //                         name="otherProcedures"
                                    //                         initialValue={schedule?.schedule?.procedureValue === null ? schedule?.schedule?.procedure.map((val, i) => val.surgery).join(", ") : schedule?.schedule?.procedureValue}
                                    //                     >
                                    //                     <TextArea
                                    //                         bordered={false}
                                    //                         onChange={e => {setProcedureValue(e.target.value)}}
                                    //                         onBlur={e => updateOneSchedule({data: e.target.value, _id: schedule?.schedule?._id}) }
                                    //                         placeholder=""
                                    //                         autoSize={{ minRows: 1, maxRows: 10 }}
                                    //                     />
                                    //                 </Form.Item>
                                    //                 </Form>
                                    //             </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>PATIENT NAME:</span>
                                    //                 {schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}
                                    //                 <br/>
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px",
                                    //             }}>DOB:</span>
                                    //                 {schedule?.schedule?.patient?.dob ? moment(schedule.schedule.patient.dob).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" rowSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>SSN:</span>
                                    //                 {/*{schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}*/}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "15px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>CELL:
                                    //                 </span>
                                    //                 {schedule?.schedule?.patient?.phone ? schedule.schedule.patient.phone : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //
                                    //
                                    //         <tr>
                                    //             <td colSpan="1" rowSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "10px"}}>Date of Service:</span>
                                    //                 {schedule?.schedule?.date ? moment(schedule.schedule.date).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "15px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>Time123121:
                                    //                 </span>
                                    //                 {schedule?.schedule?.startTime ? moment(schedule.schedule.startTime, ["HH.mm"]).format("hh:mm a") + " - " + moment(schedule.schedule.endTime, ["HH.mm"]).format("hh:mm a") : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>CPT(s):</span>
                                    //                 {schedule?.schedule?.cptCodes ? schedule?.schedule.cptCodes.join(", ") : "" }
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>ICD CODE(S):
                                    //                 </span>
                                    //                 {schedule?.schedule?.icd10Codes ? schedule?.schedule.icd10Codes.join(", ") : "" }
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>PRIMARY INSURANCE:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredProvider ? schedule.patientDetails.lastInsurance.insuredProvider : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>POLICY #
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredPolicyNumber ? schedule.patientDetails.lastInsurance.insuredPolicyNumber : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>INS PHONE #:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredPhone ? schedule.patientDetails.lastInsurance.insuredPhone : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>INSURED:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredName ? schedule.patientDetails.lastInsurance.insuredName : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>AUTHORIZATION #</span>
                                    //                 {schedule?.schedule?.authorization ? schedule.schedule.authorization : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>DOB:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.insuredDateOfBirth).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>SECONDARY INSURANCE:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuranceProvider ? schedule.patientDetails.lastInsurance.secondaryInsuranceProvider : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>POLICY #
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredPolicyNumber ? schedule.patientDetails.lastInsurance.secondaryInsuredPolicyNumber : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>INS PHONE #:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredPhone ? schedule.patientDetails.lastInsurance.secondaryInsuredPhone : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>INSURED-4:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredName ? schedule.patientDetails.lastInsurance.secondaryInsuredName : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>AUTHORIZATION #</span>
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>DOB:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.secondaryInsuredDateOfBirth).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>OFFICE SCHEDULER:</span>
                                    //                 {schedule?.schedule?.userInfo?.userFirstName ? schedule?.schedule?.userInfo?.userFirstName + " " + schedule?.schedule?.userInfo?.userLastName : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{paddingLeft: "5px", paddingRight: "15px",}}>PHONE:</span>
                                    //                 {schedule?.schedule?.userInfo?.userPhone? schedule?.schedule?.userInfo?.userPhone : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="2" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0,
                                    //                 paddingLeft:0,
                                    //             }}>
                                    //                 <Col md={12}>
                                    //                     <Form
                                    //                         {...layout}
                                    //                         name={'sampleForm'}
                                    //                         form={form}
                                    //                         validateMessages={validateMessages}
                                    //                     >
                                    //                         <Col md={24}>
                                    //                             <br/>
                                    //                             <Form.Item
                                    //
                                    //                                 name="emailDetails"
                                    //                                 initialValue={filteredEmails ? filteredEmails : []}
                                    //                                 label="REPRESENTATIVE EMAIL:"
                                    //                             >
                                    //                                 <Select disabled={schedule?.schedule?.scheduleCancelStatus !== null} onBlur={validateAnotherEmail} mode="tags" onChange={updateCurrentEmails}>
                                    //                                     {schedule?.allNamesAndEmails?.map((rep, ci) => (
                                    //                                         <Select.Option key={ci} value={rep.repEmail}>
                                    //                                             <Tooltip color={"red"} placement="topLeft" title={rep?.repFirstName + " " + rep?.repLastName}>
                                    //                                                 {rep.repEmail}
                                    //                                             </Tooltip>
                                    //                                         </Select.Option>
                                    //
                                    //                                     ))}
                                    //                                 </Select>
                                    //                             </Form.Item>
                                    //                         </Col>
                                    //                         <Form.Item
                                    //                             name="otherDetails"
                                    //                             initialValue={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                    //                             label="DETAILS:"
                                    //                         >
                                    //                             <TextArea
                                    //                                 disabled={schedule?.schedule?.scheduleCancelStatus !== null}
                                    //                                 value={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                    //                                 onChange={e => setValue(e.target.value)}
                                    //                                 placeholder=""
                                    //                                 autoSize={{ minRows: 2, maxRows: 10 }}
                                    //                             />
                                    //                         </Form.Item>
                                    //                     </Form>
                                    //                 </Col>
                                    //             </td>
                                    //         </tr>
                                    //         </tbody>
                                    //     </table>
                                    // </>
                                ) : (
                                    // <>
                                    //     <table className="table table-bordered table-responsive-sm"
                                    //            style={{fontSize: "15px", paddingRight: "5px"}} cellSpacing="0"
                                    //            cellPadding="0">
                                    //         <tbody>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "5px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>HOSPITAL:</span>
                                    //                 {schedule?.schedule?.facility?.name ? schedule.schedule.facility.name : null}
                                    //                 <br/>
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "5px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span>
                                    //            <span style={{paddingTop: "0px"}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px",
                                    //             }}>PROCEDURE:</span>
                                    //                 <Form
                                    //                     {...layout}
                                    //                     form={form}
                                    //                     className="textAreaPadding"
                                    //                     layout="horizontal"
                                    //                     labelCol={{ span: 7 }}
                                    //                     wrapperCol={{ span: 50 }}
                                    //                 >
                                    //                     <Form.Item
                                    //                         name="otherProcedures"
                                    //                         initialValue={schedule?.schedule?.procedureValue === null ? schedule?.schedule?.procedure.map((val, i) => val.surgery).join(", ") : schedule?.schedule?.procedureValue}
                                    //                     >
                                    //                     <TextArea
                                    //                         bordered={false}
                                    //                         onChange={e => {setProcedureValue(e.target.value)}}
                                    //                         onBlur={e => updateOneSchedule({data: e.target.value, _id: schedule?.schedule?._id}) }
                                    //                         placeholder=""
                                    //                         autoSize={{ minRows: 1, maxRows: 10 }}
                                    //                     />
                                    //                 </Form.Item>
                                    //                 </Form>
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>PATIENT NAME:</span>
                                    //                 {schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}
                                    //                 <br/>
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px",
                                    //             }}>DOB:</span>
                                    //                 {schedule?.schedule?.patient?.dob ? moment(schedule.schedule.patient.dob).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" rowSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>SSN:</span>
                                    //                 {/*{schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}*/}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "15px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>CELL:
                                    //                 </span>
                                    //                 {schedule?.schedule?.patient?.phone ? schedule.schedule.patient.phone : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" rowSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "10px"}}>Date of Service:</span>
                                    //                 {schedule?.schedule?.date ? moment(schedule.schedule.date).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "15px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>Time:
                                    //                 </span>
                                    //                 {schedule?.schedule?.startTime ? moment(schedule.schedule.startTime, ["HH.mm"]).format("hh:mm a") + " - " + moment(schedule.schedule.endTime, ["HH.mm"]).format("hh:mm a") : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>CPT(s):</span>
                                    //                 {schedule?.schedule?.cptCodes ? schedule?.schedule.cptCodes.join(", ") : "" }
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>ICD CODE(S):
                                    //                 </span>
                                    //                 {schedule?.schedule?.icd10Codes ? schedule?.schedule.icd10Codes.join(", ") : "" }
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>PRIMARY INSURANCE:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredProvider ? schedule.patientDetails.lastInsurance.insuredProvider : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>POLICY #
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredPolicyNumber ? schedule.patientDetails.lastInsurance.insuredPolicyNumber : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>INS PHONE #:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredPhone ? schedule.patientDetails.lastInsurance.insuredPhone : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>INSURED:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredName ? schedule.patientDetails.lastInsurance.insuredName : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>AUTHORIZATION #</span>
                                    //                 {schedule?.schedule?.authorization ? schedule.schedule.authorization : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>DOB:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.insuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.insuredDateOfBirth).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>SECONDARY INSURANCE:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuranceProvider ? schedule.patientDetails.lastInsurance.secondaryInsuranceProvider : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>POLICY #
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredPolicyNumber ? schedule.patientDetails.lastInsurance.secondaryInsuredPolicyNumber : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>INS PHONE #:</span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredPhone ? schedule.patientDetails.lastInsurance.secondaryInsuredPhone : ""}
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>Secondary Insured Name:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredName === "null" ? "" : schedule?.patientDetails?.lastInsurance?.secondaryInsuredName}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{
                                    //                 paddingLeft: "5px",
                                    //                 paddingRight: "15px"
                                    //             }}>AUTHORIZATION #</span>
                                    //             </span>
                                    //                 </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{
                                    //                     paddingLeft: "5px",
                                    //                     paddingRight: "15px",
                                    //                 }}>DOB:
                                    //                 </span>
                                    //                 {schedule?.patientDetails?.lastInsurance?.secondaryInsuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.secondaryInsuredDateOfBirth).format('MM-DD-YYYY') : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px",}}>
                                    //             <span style={{paddingLeft: "5px", paddingRight: "15px"}}>OFFICE SCHEDULER:</span>
                                    //                 {schedule?.schedule?.userInfo?.userFirstName ? schedule?.schedule?.userInfo?.userFirstName + " " + schedule?.schedule?.userInfo?.userLastName : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //             <td colSpan="1" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0
                                    //             }}>
                                    //         <span style={{display: "flex"}}>
                                    //             <span style={{paddingTop: "0px"}}>
                                    //                 <span style={{paddingLeft: "5px", paddingRight: "15px",}}>PHONE:</span>
                                    //                 {schedule?.schedule?.userInfo?.userPhone? schedule?.schedule?.userInfo?.userPhone : ""}
                                    //             </span>
                                    //         </span>
                                    //             </td>
                                    //         </tr>
                                    //         <tr>
                                    //             <td colSpan="2" width="50%" style={{
                                    //                 textAlign: "left",
                                    //                 paddingTop: "0px",
                                    //                 paddingBottom: "27px",
                                    //                 marginLeft: 0,
                                    //                 paddingLeft:0,
                                    //             }}>
                                    //                 <Col md={24}>
                                    //                     <Form
                                    //                         {...layout}
                                    //                         name={'sampleForm'}
                                    //                         form={form}
                                    //                         validateMessages={validateMessages}
                                    //                     >
                                    //                         <Col md={24}>
                                    //                             <br/>
                                    //
                                    //                                 <Form.Item
                                    //
                                    //                                     name="emailDetails"
                                    //                                     initialValue={filteredEmails ? filteredEmails : []}
                                    //                                     label="REPRESENTATIVE EMAIL:"
                                    //                                 >
                                    //                                     <Select disabled={schedule?.schedule?.scheduleCancelStatus !== null} onBlur={validateAnotherEmail} mode="tags" onChange={updateCurrentEmails}>
                                    //                                         {schedule?.allNamesAndEmails?.map((rep, ci) => (
                                    //                                             <Select.Option key={ci} value={rep.repEmail}>
                                    //                                                 <Tooltip color={"red"} placement="topLeft" title={rep?.repFirstName + " " + rep?.repLastName}>
                                    //                                                     {rep.repEmail}
                                    //                                                 </Tooltip>
                                    //                                             </Select.Option>
                                    //
                                    //                                         ))}
                                    //                                     </Select>
                                    //                                 </Form.Item>
                                    //                         </Col>
                                    //                         <Form.Item
                                    //                             name="otherDetails"
                                    //                             initialValue={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                    //                             label="DETAILS:"
                                    //                         >
                                    //                             <TextArea
                                    //                                 disabled={schedule?.schedule?.scheduleCancelStatus !== null}
                                    //                                 value={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                    //                                 onChange={e => setValue(e.target.value)}
                                    //                                 placeholder=""
                                    //                                 autoSize={{ minRows: 2, maxRows: 10 }}
                                    //                             />
                                    //                         </Form.Item>
                                    //                     </Form>
                                    //                 </Col>
                                    //             </td>
                                    //         </tr>
                                    //         </tbody>
                                    //     </table>
                                    // </>
                                    <View
                                        showPrint={showPrint}
                                        schedule={schedule}
                                        layout={layout}
                                        form={form}
                                        setProcedureValue={setProcedureValue}
                                        updateOneSchedule={updateOneSchedule}
                                        filteredEmails={filteredEmails}
                                        setValue={setValue}
                                        validateAnotherEmail={validateAnotherEmail}
                                        updateCurrentEmails={updateCurrentEmails}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                </Col>
            </Modal>
        </>
    )
};

export default ScheduleForm;
