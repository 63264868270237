import React from 'react';
import {HashRouter, Route,} from 'react-router-dom';
import Dashboard from "./components/Dashboard";
import UsersData from "./container/user-management/users";
import rolesData from "./container/user-management/roles";
import permissionData from "./container/user-management/Permissions";
import PatientData from "./container/patient-management/patients";
import FacilityData from "./container/patient-management/facilities";
import SurgeonData from "./container/patient-management/surgeons";
import ProcedureData from "./container/patient-management/procedures";
import SupportiveData from "./container/patient-management/representatives";
import ScheduleData from "./container/schedule-management/schedules";
import EmailLogsData from "./container/patient-management/emailLogs";
import ActivityData from "./container/activity";
import ServiceData from "./container/service";
import Login from "./container/auth/login";
import Profile from "./container/user-management/Profile";

const Routes = () => (
    <HashRouter>
            <Route exact path='/login' component={Login} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/dashboard' component={Dashboard} />
            <Route exact path='/users' component={UsersData} />
            <Route exact path='/patients' component={PatientData} />
            <Route exact path='/roles' component={rolesData} />
            <Route exact path='/permissions' component={permissionData} />
            <Route exact path='/facilities' component={FacilityData} />
            <Route exact path='/surgeons' component={SurgeonData} />
            <Route exact path='/procedures' component={ProcedureData} />
            <Route exact path='/supportive' component={SupportiveData} />
            <Route exact path='/email' component={EmailLogsData} />
            <Route exact path='/activities' component={ActivityData} />
            <Route exact path='/services' component={ServiceData} />
            <Route exact path='/schedules' component={ScheduleData} />
    </HashRouter>
)

export default Routes;
