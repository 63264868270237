import axios from 'axios';
import {Constants} from "./constants";
import {Auth} from "../utils/auth";
import {Alert} from "../utils/alert";
import store from "../redux/store";

const axiosInstance = axios.create({});
axiosInstance.interceptors.request.use(request => requestHandler(request));
axiosInstance.interceptors.response.use(response => successHandler(response),error => errorHandler(error));

const requestHandler = (request) => {
    return request;
};

const errorHandler = (error) => {
    if (error.response && error.response.status === 401) {
        window.location.reload()
        return error
    } else if (error.response && error.response.status === 405) {
        Alert.error({title: 'something went wrong', status: 405});
        return error
    } else if (error.response && error.response.status === 406) {
        Alert.error({title: error?.response?.data?.message && typeof error?.response?.data?.message === 'string' ? error?.response?.data?.message : 'something went wrong', status: 406})
        return error
    } else if (error.response && error.response.status === 400) {
        Alert.error({title: 'something went wrong', status: 400});
        return error
    } else if (error.response && error.response.status === 422) {
        // Alert.error({title: error?.response?.data?.message && typeof error?.response?.data?.message === 'string' ? error?.response?.data?.message : 'something went wrong', status: 422})
        return error?.response
    } else {
        Alert.error({title: 'Server not found', status: 500});
        return error
    }
};

const successHandler = (response) => {
    // store.dispatch(loadingEnd());
    return response
};
export default axiosInstance;
