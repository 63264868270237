import React, {useEffect, useState} from 'react';
import { Layout } from 'antd';
import SideBar from "./Sider";
import Footer from "./Footer";
import Content from "./Content";
import Header from "./Header";
import history from "../../utils/history";
import {Auth} from "../../utils/auth";
import {useDispatch} from "react-redux";
import {logOut, renew} from "../../redux/authentication/actionCreator";
import {Form, Spin} from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
    <LoadingOutlined
        style={{
            margin: 50,
            fontSize: 24,
        }}
        spin
    />
);

const Section = ({children}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [render, setRender] = useState(false);

    useEffect(() => {
        const accessToken = Auth.getAccessToken();
        const refreshToken = Auth.getRefreshToken();

        if (accessToken && refreshToken) {
            if (new Date(accessToken.expires) < new Date()) {
                if (new Date(refreshToken.expires) < new Date()) {
                    dispatch(logOut())
                } else {
                    dispatch(renew(() => setRender(true)))
                }
            } else setRender(true)
        } else {
            history.push('#/login')
            setTimeout(() => {
                window.location.reload()
            }, 100)
        }
    }, [])


    return render ? (
        <Layout style={{ minHeight: '100vh' }}>
            <SideBar
            />
            <Layout className="site-layout">
                <Header />
                <Content children={children} />
                <Footer />
            </Layout>
        </Layout>
    ) : (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout className="site-layout">
                <Spin indicator={antIcon} />
            </Layout>
        </Layout>
    )
}

export default Section;
