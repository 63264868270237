import { Constants } from "../../../config/constants";
import { Requests as req } from "../../../utils/requests";
import actions from './actions';

const { fetchFacilitiesAction, fetchFacilityAction, resetFacilityFormAction } = actions;

export const addFacility = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({ url: Constants.FACILITIES, auth: 'bearer', body: data, validate}, async (cb) => {
            await dispatch(fetchFacilities());
            await dispatch(action);
        })
    };
};

export const fetchFacilities = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.FACILITIES,
            auth: 'bearer'
        }, (cb) => dispatch(fetchFacilitiesAction(cb)))
    };
};

export const fetchFacilitiesForSchedulers = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.FACILITIES_FOR_SCHEDULERS,
            auth: 'bearer'
        }, (cb) => dispatch(fetchFacilitiesAction(cb)))
    };
};


export const updateFacility = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.FACILITIES + data._id, auth: 'bearer', body: data, validate},  async (cb) => {
            await dispatch(fetchFacilities());
            await dispatch(action);
        })
    };
};

export const resetFacilityForm = () => dispatch => dispatch(resetFacilityFormAction());

export const fetchFacility = (_id, action) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.FACILITIES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchFacilityAction(cb));
            await dispatch(action);
        })
    };
};

export const deleteFacility = (id) => {
    return async dispatch => {
        await req.deleteRequest({ url: Constants.FACILITIES + id, auth: 'bearer'}, (cb) => dispatch(fetchFacilities()))
    };
};
