import React, {useContext, Fragment, useEffect, useState, useRef} from "react";
import dayjs from "dayjs";
import GlobalContext from "./context/GlobalContext";
import Month from "./Month";
import EventModal from "./EventModal";
import {getMonth} from "./util";
import logo from "./assets/logo.png";
import plusImg from "./assets/plus.svg";
import "./css/output.css"
import {fetchSchedules} from "../../../../redux/schedule-management/schedules/actionCreator";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import {Scope} from "../../../../services/scopeService";
import {Button} from "antd";
import {FilterOutlined, PrinterOutlined, FullscreenOutlined} from "@ant-design/icons";
import {useReactToPrint} from 'react-to-print';
import {resetFilteredPatients} from "../../../../redux/utilities/actionCreator";

const ScheduleCalender = ({showModal, filterState, setState, state, getSchedule, setSchedule, scheduleVisible, modalVisible, getUsers, setFilterState, surgeon, getFilteredSchedules, viewScheduleInfo, doctorAvailability, getDoctorAvailabilities, availabilities}) => {
    const {
        setMonthIndex,
        monthIndex,
        setSmallCalendarMonth,
        setDaySelected,
        daySelected,
        labels,
        updateLabel,
        showEventModal,
        dispatchCalEvent
    } = useContext(GlobalContext);

    const dispatch = useDispatch();

    function handlePrevMonth() {
        setMonthIndex(monthIndex - 1);
    }

    function handleNextMonth() {
        setMonthIndex(monthIndex + 1);
    }

    function handleReset() {
        setMonthIndex(
            monthIndex === dayjs().month()
                ? monthIndex + Math.random()
                : dayjs().month()
        );
    }
    //Print
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Monthly Schedules',
        pageStyle: "@media print {\n" +
            "  @page { size: landscape; margin: 2.5mm; }\n" +
            "}",
    })

    const [getPrint, setPrint] = useState(false)
    const showPrint =() => {
        setPrint(true)
    }
    const hidePrint =() => {
        setPrint(false)
    }
    //End Print

    // small functions
    const [currentMonthIdx, setCurrentMonthIdx] = useState(
        dayjs().month()
    );

    const [currentMonth, setCurrentMonth] = useState(getMonth());

    useEffect(() => {
        setCurrentMonth(getMonth(currentMonthIdx));
    }, [currentMonthIdx]);

    useEffect(() => {
        setCurrentMonthIdx(monthIndex);
    }, [monthIndex]);

    function handleSidePrevMonth() {
        setCurrentMonthIdx(currentMonthIdx - 1);
    }

    function handleSideNextMonth() {
        setCurrentMonthIdx(currentMonthIdx + 1);
    }

    const schedules = useSelector(state => state.smSchedules.schedules);
    const theSchedules = schedules?.filter((item,i ) => item.scheduleCancelStatus !== "inactive")
    const filteredSurgeon = schedules?.filter((item) => item?.surgeon?._id === getUsers?.surgeon?._id && item.scheduleCancelStatus !== "inactive")
    const setSchedulesToCalender = (values) => dispatchCalEvent({type: "push", payload: values})

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSchedules());
        };
        fetchData().then(r => null);
    }, []);

    useEffect(() => {
        const arr = [];
        getUsers && getUsers?.surgeon?._id ? (
                filteredSurgeon?.forEach((schedule) => {arr.push({
                    day: +new Date(schedule?.date),
                    scheduleDate: schedule?.date,
                    patient: schedule?.patient?.firstName + " " + schedule?.patient?.lastName,
                    surgery: schedule?.procedure?.length ? schedule?.procedure?.map((val, i) => val.surgery).join(", ") : [],
                    surgeryLength: schedule?.procedure?.length ? schedule?.procedure : [],
                    caseTime: schedule?.procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => +partialSum + +a),
                    coSurgeon: schedule?.coSurgeon?.length ? schedule?.coSurgeon?.map((val, i) => val.firstName + " " + val.lastName).join(", ") : [],
                    surgeon: schedule?.surgeon?.firstName + " " + schedule?.surgeon?.lastName,
                    startTime: schedule?.startTime,
                    scheduleDateForDownload: schedule?.scheduleDate,
                    scheduleCancelStatus: schedule?.scheduleCancelStatus,
                    hospitalName: schedule?.facility?.name,
                    hospitalLocation: schedule?.facility?.location,
                    endTime: schedule?.endTime,
                    hideFields: schedule?.hideFields,
                    description: schedule?.details,
                    label: schedule?.facility?.label,
                    id: schedule?._id,
                    surgeonId: schedule?.surgeon?._id
                })})
            ) :
            !getFilteredSchedules?.length  ? (
            theSchedules?.forEach((schedule) => {arr.push({
               day: +new Date(schedule?.date),
               scheduleDate: schedule?.date,
               patient: schedule?.patient?.firstName + " " + schedule?.patient?.lastName,
               surgery: schedule?.procedure?.length ? schedule?.procedure?.map((val, i) => val.surgery).join(", ") : [],
               surgeryLength: schedule?.procedure?.length ? schedule?.procedure : [],
               caseTime: schedule?.procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => +partialSum + +a),
               coSurgeon: schedule?.coSurgeon?.length ? schedule?.coSurgeon?.map((val, i) => val.firstName + " " + val.lastName).join(", ") : [],
               surgeon: schedule?.surgeon?.firstName + " " + schedule?.surgeon?.lastName,
               startTime: schedule?.startTime,
               scheduleDateForDownload: schedule?.scheduleDate,
               scheduleCancelStatus: schedule?.scheduleCancelStatus,
               hospitalName: schedule?.facility?.name,
               hospitalLocation: schedule?.facility?.location,
               endTime: schedule?.endTime,
               hideFields: schedule?.hideFields,
               description: schedule?.details,
               label: schedule?.facility?.label,
               id: schedule?._id,
               surgeonId: schedule?.surgeon?._id
           })})
            ) : (
           getFilteredSchedules.forEach((schedule) => {arr.push({
               day: +new Date(schedule?.date),
               scheduleDate: schedule?.date,
               patient: schedule?.patient?.firstName + " " + schedule?.patient?.lastName,
               surgery: schedule?.procedure?.length ? schedule?.procedure?.map((val, i) => val.surgery).join(", ") : [],
               surgeryLength: schedule?.procedure?.length ? schedule?.procedure : [],
               caseTime: schedule?.procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => +partialSum + +a),
               coSurgeon: schedule?.coSurgeon?.length ? schedule?.coSurgeon?.map((val, i) => val.firstName + " " + val.lastName).join(", ") : [],
               surgeon: schedule?.surgeon?.firstName + " " + schedule?.surgeon?.lastName,
               startTime: schedule?.startTime,
               scheduleDateForDownload: schedule?.scheduleDate,
               scheduleCancelStatus: schedule?.scheduleCancelStatus,
               hospitalName: schedule?.facility?.name,
               hospitalLocation: schedule?.facility?.location,
               endTime: schedule?.endTime,
               hideFields: schedule?.hideFields,
               description: schedule?.details,
               label: schedule?.facility?.label,
               id: schedule?._id,
               surgeonId: schedule?.surgeon?._id
           })})
            )
        setSchedulesToCalender(arr)

    }, [!getFilteredSchedules?.length ? schedules : getFilteredSchedules])

    function getDayClass(day) {
        const format = "DD-MM-YY";
        const nowDay = dayjs().format(format);
        const currDay = day.format(format);
        const slcDay = daySelected && daySelected.format(format);
        if (nowDay === currDay) {
            return "bg-blue-500 rounded-full text-white";
        } else if (currDay === slcDay) {
            return "bg-blue-100 rounded-full text-blue-600 font-bold";
        } else {
            return "";
        }
    }

    const screenWidth = window.innerWidth;

    return (
        <Fragment>
            {showEventModal && <EventModal/>}
            <div className="flex flex-col">
                <header className={`px-4 py-2 ${screenWidth > 700 ? 'flex' : ''} items-center`}>
                    {screenWidth > 700 ? (
                        <React.Fragment>
                            <img src={logo} alt="calendar" className="mr-2 w-12 h-12"/>
                            <h1 className="mr-10 text-xl text-gray-500 fond-bold">
                                Calendar
                            </h1>
                            <button onClick={handleReset} className="border rounded py-2 px-4 mr-5">
                                Today
                            </button>
                            <button onClick={handlePrevMonth}>
                                <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                                    <FeatherIcon icon="arrow-left" size={25}/>
                                </span>
                            </button>
                            <button onClick={handleNextMonth}>
                                <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                                    <FeatherIcon icon="arrow-right" size={25}/>
                                </span>
                            </button>
                            <h2 className="ml-4 text-xl text-gray-500 font-bold">
                                {dayjs(new Date(dayjs().year(), monthIndex)).format(
                                    "MMMM YYYY"
                                )}
                            </h2>
                            {
                                screenWidth < 1501 ? (
                                    <>
                                        {/*<p className="text-gray-500 font-bold mt-10">Label</p>*/}
                                        <div className="flex flex-wrap  hover:flex-wrap-reverse py-2 px-8 gap-2">
                                            {labels.map(({label: lbl, checked}, idx) => (
                                                <label key={idx} className="items-center mt-3 block">
                                                    <input
                                                        type="checkbox"
                                                        checked={checked}
                                                        onChange={() =>
                                                            updateLabel({label: lbl, checked: !checked})
                                                        }
                                                        className={`form-checkbox h-5 w-5 text-${lbl}-400 rounded focus:ring-0 cursor-pointer`}
                                                    />
                                                    <span className="ml-2 text-gray-700 capitalize">{lbl}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </>
                                ) : null
                            }
                        </React.Fragment>
                    ) : (
                        <>
                            <React.Fragment>
                                <div className="flex">
                                    <h1 className="mr-10 text-xl text-gray-500 fond-bold" style={{paddingTop: '17px'}}>
                                        Calendar
                                    </h1>
                                    <button onClick={handlePrevMonth}>
                                        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                                            <FeatherIcon icon="arrow-left" size={25}/>
                                        </span>
                                    </button>
                                    <button onClick={handleNextMonth}>
                                        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                                            <FeatherIcon icon="arrow-right" size={25}/>
                                        </span>
                                    </button>
                                </div>
                                <h2 className="text-xl text-gray-500 font-bold">
                                    {dayjs(new Date(dayjs().year(), monthIndex)).format(
                                        "MMMM YYYY"
                                    )}
                                </h2>
                            </React.Fragment>
                            <Fragment>
                                <p className="text-gray-500 font-bold mt-10">Label</p>
                                <div className="flex flex-wrap hover:flex-wrap-reverse gap-3">
                                    {labels.map(({label: lbl, checked}, idx) => (
                                        <label key={idx} className="items-center mt-3 block">
                                            <input
                                                type="checkbox"
                                                checked={checked}
                                                onChange={() =>
                                                    updateLabel({label: lbl, checked: !checked})
                                                }
                                                className={`form-checkbox h-5 w-5 text-${lbl}-400 rounded focus:ring-0 cursor-pointer`}
                                            />
                                            <span className="ml-2 text-gray-700 capitalize">{lbl}</span>
                                        </label>
                                    ))}
                                </div>
                            </Fragment>
                        </>
                    )}
                    <div className="float-right ml-5">
                        {Scope.checkScopes(['records_scheduleSurgery_filter']) && (
                            <Button
                                size="medium"
                                className="minimum-mr"
                                type="secondary"
                                icon={<FilterOutlined />}
                                onClick={ async () => {
                                    setFilterState({...filterState, filterVisible: true})
                                    await dispatch(resetFilteredPatients())
                                }
                                }
                            >
                                Filters
                            </Button>
                        )}
                    </div>
                    <PrinterOutlined onMouseOver={showPrint} onMouseLeave={hidePrint} className="text-right ml-5" onClick={handlePrint} />
                    {
                        modalVisible ? (
                            <Button
                                size="medium"
                                className="ml-6 float-right"
                                type="secondary"
                                title="Maximize"
                                icon={<FullscreenOutlined />}
                                onClick={ async () => {setState({...state, visible: true})}}
                            >
                            </Button>
                        ) : (
                            ""
                        )
                    }
                    {
                        scheduleVisible ? (
                            <Button
                                size="medium"
                                className="ml-6 float-right"
                                type="secondary"
                                title="Maximize"
                                icon={<FullscreenOutlined />}
                                onClick={ async () => {setSchedule({...getSchedule, visible: true})}}
                            >
                            </Button>
                        ) : (
                            ""
                        )
                    }

                </header>
                <div className="flex flex-1">
                    {window.innerWidth > 1500 ? (
                        <aside className={ !getPrint ? "border p-55 w-644" : "border p-55 w-645"}>
                            <button
                                // onClick={() => setShowEventModal(true)}
                                onClick={() => showModal('primary')}
                                className="border p-2 rounded-full flex items-center shadow-md hover:shadow-2xl"
                            >
                                <img src={plusImg} alt="create_event" className="w-7 h-7"/>
                                <span className="pl-3 pr-7"> Create</span>
                            </button>

                            {/* Sidebar */}
                            <div className="mt-9">
                                <header className="flex justify-between">
                                    <p className="text-gray-500 font-bold">
                                        {dayjs(new Date(dayjs().year(), currentMonthIdx)).format(
                                            "MMMM YYYY"
                                        )}
                                    </p>
                                    <div>
                                        <button onClick={handleSidePrevMonth}>
                                        <span className="material-icons-outlined cursor-pointer text-gray-600">
                                            <FeatherIcon icon="arrow-left-circle" size={20}/>
                                        </span>
                                        </button>
                                        <button onClick={handleSideNextMonth}>
                                        <span className="material-icons-outlined cursor-pointer text-gray-600">
                                            <FeatherIcon icon="arrow-right-circle" size={20}/>
                                        </span>
                                        </button>
                                    </div>
                                </header>
                                <div className="grid grid-cols-7 grid-rows-6">
                                    {currentMonth[0].map((day, i) => (
                                        <span key={i} className="text-sm py-1 text-center">
                                    {day.format("dd").charAt(0)}
                                  </span>
                                    ))}
                                    {currentMonth.map((row, i) => (
                                        <React.Fragment key={i}>
                                            {row.map((day, idx) => (
                                                <button
                                                    key={idx}
                                                    onClick={() => {
                                                        setSmallCalendarMonth(currentMonthIdx);
                                                        setDaySelected(day);
                                                    }}
                                                    className={`py-1 w-full ${getDayClass(day)}`}
                                                >
                                                    <span className="text-sm">{day.format("D")}</span>
                                                </button>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                            {/* Labels */}
                            <Fragment>
                                <p className="text-gray-500 font-bold mt-10">Label</p>
                                {labels.map(({label: lbl, checked}, idx) => (
                                    <label key={idx} className="items-center mt-3 block">
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            onChange={() =>
                                                updateLabel({label: lbl, checked: !checked})
                                            }
                                            className={`form-checkbox h-5 w-5 text-${lbl}-400 rounded focus:ring-0 cursor-pointer`}
                                        />
                                        <span className="ml-2 text-gray-700 capitalize">
                                        {lbl}
                                    </span>
                                    </label>
                                ))}
                            </Fragment>
                        </aside>
                    ) : null}
                    <Month availabilities={availabilities} month={currentMonth} getPrint={getPrint} monthIndex={monthIndex} componentRef={componentRef} surgeon={surgeon} viewScheduleInfo={viewScheduleInfo} showModal={showModal} doctorAvailability={doctorAvailability} getDoctorAvailabilities={getDoctorAvailabilities}/>
                </div>
            </div>
        </Fragment>
    )
}

export default ScheduleCalender;