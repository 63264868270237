import React from "react"
import "./style.scss"
import {Avatar, Col, Form, Row, Select, Tooltip} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {borderColor} from "tailwindcss/lib/plugins";
import moment from "moment";

const View = (
    {
        showPrint,
        schedule,
        layout,
        form,
        setProcedureValue,
        updateOneSchedule,
        validateMessages,
        filteredEmails,
        setValue,
        validateAnotherEmail,
        updateCurrentEmails
  }
  ) => {
    console.log(showPrint)
    return (
        <div className="container">
            <div className="mainFont">
                <Avatar size="large" className="mr-2" src={schedule?.schedule?.surgeon?.logo ? schedule.schedule.surgeon.logo : null}/>
                Surgery Schedule
            </div>
            <br/>
            <ul className="timeline">
                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>HOSPITAL: <span
                            style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.facility?.name ? schedule.schedule.facility.name : null}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon mt-2"></div>
                    <details className="panel">
                        <summary style={{marginBottom: -25, marginRight: 0}}>
                            <div className="row">
                                <Row>
                                    <Col md={6}>
                                        <span style={{fontSize: 15, fontWeight: "bold"}}>PROCEDURE:</span>
                                    </Col>
                                    <Col md={18} style={{
                                        width: showPrint ? "550px" : "",
                                        marginBottom: showPrint ? -10 : "",
                                        marginTop: showPrint ? -3 : ""
                                    }}>
                                        <Form
                                            {...layout}
                                            size="small"
                                            form={form}
                                            className="textAreaPadding"
                                            layout="horizontal"
                                            wrapperCol={{span: 0}}
                                        >

                                            <Form.Item
                                                name="otherProcedures"

                                                initialValue={schedule?.schedule?.procedureValue === null ? schedule?.schedule?.procedure.map((val, i) => val.surgery).join(", ") : schedule?.schedule?.procedureValue}
                                            >
                                                <TextArea
                                                    bordered={false}
                                                    value={schedule?.schedule?.procedureValue === null ? schedule?.schedule?.procedure.map((val, i) => val.surgery).join(", ") : schedule?.schedule?.procedureValue}
                                                    onChange={e => {
                                                        setProcedureValue(e.target.value)
                                                    }}
                                                    onBlur={e => updateOneSchedule({
                                                        data: e.target.value, _id: schedule?.schedule?._id
                                                    })}
                                                    placeholder=""
                                                    autoSize={{minRows: 1, maxRows: 10}}
                                                />
                                            </Form.Item>

                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>PATIENT NAME: <span
                            style={{fontSize: 15, fontWeight: "normal"}}> {schedule?.schedule?.patient?.firstName ? schedule.schedule.patient.firstName + " " + schedule.schedule.patient.lastName : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>DOB: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.patient?.dob ? moment(schedule.schedule.patient.dob).format('MM-DD-YYYY') : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>SSN: <span style={{fontSize: 15, fontWeight: "normal"}}></span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>CELL: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.patient?.phone ? schedule.schedule.patient.phone : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>DATE OF SERVICE: <span
                            style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.date ? moment(schedule.schedule.date).format('MM-DD-YYYY') : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>TIME: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.startTime ? moment(schedule.schedule.startTime, ["HH.mm"]).format("hh:mm a") + " - " + moment(schedule.schedule.endTime, ["HH.mm"]).format("hh:mm a") : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>CPT(s): <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.cptCodes ? schedule?.schedule.cptCodes.join(", ") : "" }</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>ICD CODE(S): <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.icd10Codes ? schedule?.schedule.icd10Codes.join(", ") : "" }</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>PRIMARY INSURANCE: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.insuredProvider ? schedule.patientDetails.lastInsurance.insuredProvider : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>POLICY: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.insuredPolicyNumber ? schedule.patientDetails.lastInsurance.insuredPolicyNumber : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>INS PHONE: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.insuredPhone ? schedule.patientDetails.lastInsurance.insuredPhone : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>INSURED: <span style={{fontSize: 15, fontWeight: "normal"}}> {schedule?.patientDetails?.lastInsurance?.insuredName ? schedule.patientDetails.lastInsurance.insuredName : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>AUTHORIZATION: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.authorization ? schedule.schedule.authorization : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>DOB: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.insuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.insuredDateOfBirth).format('MM-DD-YYYY') : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>SECONDARY INSURANCE: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.secondaryInsuranceProvider ? schedule.patientDetails.lastInsurance.secondaryInsuranceProvider : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>POLICY: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.secondaryInsuredPolicyNumber ? schedule.patientDetails.lastInsurance.secondaryInsuredPolicyNumber : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>INS PHONE: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.secondaryInsuredPhone ? schedule.patientDetails.lastInsurance.secondaryInsuredPhone : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>SECONDARY INSURED NAME: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.secondaryInsuredName ? schedule.patientDetails.lastInsurance.secondaryInsuredName : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>DOB: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.patientDetails?.lastInsurance?.secondaryInsuredDateOfBirth ? moment(schedule.patientDetails.lastInsurance.secondaryInsuredDateOfBirth).format('MM-DD-YYYY') : ""}</span></summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>OFFICE SCHEDULER: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.userInfo?.userFirstName ? schedule?.schedule?.userInfo?.userFirstName + " " + schedule?.schedule?.userInfo?.userLastName : ""}</span>
                        </summary>
                    </details>
                </li>

                <li className="timeline">
                    <div className="icon"></div>
                    <details className="panel">
                        <summary>PHONE: <span style={{fontSize: 15, fontWeight: "normal"}}>{schedule?.schedule?.userInfo?.userPhone? schedule?.schedule?.userInfo?.userPhone : ""}</span></summary>
                    </details>
                </li>
                <li className="timeline">
                    <div className="icon mt-2"></div>
                    <details className="panel">
                        <summary style={{marginBottom: -25, marginRight: 0}}>
                            <div className="row">
                                <Row>
                                    <Col md={5}>
                                        <span style={{fontSize: 15, fontWeight: "bold"}}>REP EMAIL:</span>
                                    </Col>
                                    <Col md={19} style={{
                                        width: showPrint ? "550px" : "",
                                        fontWeight: "normal",
                                        marginBottom: showPrint ? 0 : "",
                                        marginTop: -1
                                    }}>
                                        <Form
                                            {...layout}
                                            size="small"
                                            form={form}
                                            className="textAreaPadding"
                                            layout="horizontal"
                                            wrapperCol={{span: 0}}
                                        >

                                            <Form.Item
                                                name="emailDetails"
                                                initialValue={filteredEmails ? filteredEmails : []}
                                            >
                                                <Select bordered={false}
                                                        disabled={schedule?.schedule?.scheduleCancelStatus !== null}
                                                        onBlur={validateAnotherEmail} mode="tags"
                                                        onChange={updateCurrentEmails}>
                                                    {schedule?.allNamesAndEmails?.map((rep, ci) => (
                                                        <Select.Option key={ci} value={rep.repEmail}>
                                                            <Tooltip color={"red"} placement="topLeft"
                                                                     title={rep?.repFirstName + " " + rep?.repLastName}>
                                                                {rep.repEmail}
                                                            </Tooltip>
                                                        </Select.Option>

                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </summary>
                    </details>
                </li>
                <li className="timeline">
                    <div className="icon mt-2"></div>
                    <details className="panel">
                        <summary style={{marginBottom: -25, marginRight: 0}}>
                            <div className="row">
                                <Row>
                                    <Col md={4}>
                                        <span style={{fontSize: 15, fontWeight: "bold"}}>DETAILS:</span>
                                    </Col>
                                    <Col md={20} style={{
                                        width: showPrint ? "550px" : "",
                                        marginBottom: showPrint ? 0 : "",
                                        marginTop: -5
                                    }}>
                                        <Form
                                            {...layout}
                                            size="small"
                                            form={form}
                                            className="textAreaPadding"
                                            layout="horizontal"
                                            wrapperCol={{span: 0}}
                                        >

                                            <Form.Item
                                                name="otherDetails"
                                                initialValue={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                            >
                                                <TextArea
                                                    bordered={false}
                                                    disabled={schedule?.schedule?.scheduleCancelStatus !== null}
                                                    value={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}
                                                    onChange={e => setValue(e.target.value)}
                                                    placeholder=""
                                                    autoSize={{minRows: 1, maxRows: 10}}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </summary>
                    </details>
                </li>
            </ul>
            {/*<div>*/}
            {/*    <Col md={24}>*/}
            {/*        <Form*/}
            {/*            {...layout}*/}
            {/*            size="small"*/}
            {/*            name={'sampleForm'}*/}
            {/*            form={form}*/}
            {/*            validateMessages={validateMessages}*/}
            {/*        >*/}
            {/*            <summary style={{fontSize: 15, fontWeight: "bold"}}>REPRESENTATIVE EMAIL:</summary>*/}
            {/*            <Form.Item*/}
            {/*                name="emailDetails"*/}
            {/*                initialValue={filteredEmails ? filteredEmails : []}*/}
            {/*            >*/}
            {/*                <Select disabled={schedule?.schedule?.scheduleCancelStatus !== null}*/}
            {/*                        onBlur={validateAnotherEmail} mode="tags" onChange={updateCurrentEmails}>*/}
            {/*                    {schedule?.allNamesAndEmails?.map((rep, ci) => (*/}
            {/*                        <Select.Option key={ci} value={rep.repEmail}>*/}
            {/*                            <Tooltip color={"red"} placement="topLeft"*/}
            {/*                                     title={rep?.repFirstName + " " + rep?.repLastName}>*/}
            {/*                                {rep.repEmail}*/}
            {/*                            </Tooltip>*/}
            {/*                        </Select.Option>*/}

            {/*                    ))}*/}
            {/*                </Select>*/}
            {/*            </Form.Item>*/}
            {/*            <summary style={{fontSize: 15, fontWeight: "bold"}}>DETAILS:</summary>*/}
            {/*            <Form.Item*/}
            {/*                name="otherDetails"*/}
            {/*                initialValue={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}*/}
            {/*            >*/}
            {/*                <TextArea*/}
            {/*                    disabled={schedule?.schedule?.scheduleCancelStatus !== null}*/}
            {/*                    value={schedule?.concatWithDetails ? `${schedule?.concatWithDetails}` : ""}*/}
            {/*                    onChange={e => setValue(e.target.value)}*/}
            {/*                    placeholder=""*/}
            {/*                    autoSize={{minRows: 1, maxRows: 10}}*/}
            {/*                />*/}
            {/*            </Form.Item>*/}
            {/*        </Form>*/}
            {/*    </Col>*/}
            {/*</div>*/}
        </div>
    )
}

export default View;