const actions = {
    FETCH_REPRESENTATIVES: 'FETCH_REPRESENTATIVES',
    FETCH_REPRESENTATIVE: 'FETCH_REPRESENTATIVE',
    RESET_REPRESENTATIVE: 'RESET_REPRESENTATIVE',
    FETCH_LOGS: 'FETCH_LOGS',
    FETCH_CANCEL_LOGS: 'FETCH_CANCEL_LOGS',

    fetchRepresentativesAction: (data) => {
        return {
            type: actions.FETCH_REPRESENTATIVES,
            data: data
        };
    },
    resetRepresentativeAction: () => {
        return {
            type: actions.RESET_REPRESENTATIVE,
            data: null
        };
    },
    fetchRepresentativeAction: (data) => {
        return {
            type: actions.FETCH_REPRESENTATIVE,
            data: data
        };
    },
    fetchEmailLogsAction: (data) => {
        return {
            type: actions.FETCH_LOGS,
            data: data
        };
    },
    fetchCancelEmailLogsAction: (data) => {
        return {
            type: actions.FETCH_CANCEL_LOGS,
            data: data
        };
    },
}

export default actions;
