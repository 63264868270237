import actions from './actions';
import {Requests as req} from "../../utils/requests";
import { Constants } from '../../config/constants';

const {
    fetchRolesAction,
    fetchUserAction,
    fetchProceduresAction,
    fetchUsersAction,
    fetchSurgeonsAction,
    fetchCoSurgeonsAction,
    fetchPatientsAction,
    fetchFacilitiesAction,
    fetchEmailsAction,
    resetFilteredPatientFormAction,
    fetchCancelEmailsAction
} = actions;

const fetchRoles = () => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}roles`,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRolesAction(cb)))
    };
};

const fetchUser = (queries, action = null) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}user`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchUserAction(cb))
            if (action) action(cb)
        })
    };
};

const fetchProcedures = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}procedures`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchProceduresAction(cb))
        })
    };
};

const fetchProcedure = (queries, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}procedure`,
            queries,
            auth: 'bearer'
        }, async (cb) => {
            await action(cb)
        })
    };
};

const fetchSurgeons = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}surgeons`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSurgeonsAction(cb))
        })
    };
};

const fetchCoSurgeons = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}co-surgeons`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchCoSurgeonsAction(cb))
        })
    };
};

const fetchPatients = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}patients`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchPatientsAction(cb))
        })
    };
};

const fetchFilteredPatients = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}patients/filter`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchPatientsAction(cb))
        })
    };
};


const resetFilteredPatients = () => dispatch => dispatch(resetFilteredPatientFormAction());

const fetchPatient = (_id, action) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}patient/${_id}`,
            auth: 'bearer'
        }, async (cb) => {
            await action(cb)
        })
    };
};

const fetchFacilities = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}facilities`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchFacilitiesAction(cb))
        })
    };
};

const fetchUsers = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}users`,
            queries,
            auth: 'bearer'
        }, (cb) => dispatch(fetchUsersAction(cb)))
    };
};

const fetchEmails = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}emails`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchEmailsAction(cb))
        })
    };
};

const fetchCancelEmails = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.UTILITIES}cancel`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchCancelEmailsAction(cb))
        })
    };
};


export {
    fetchRoles,
    fetchUser,
    fetchUsers,
    fetchProcedures,
    fetchProcedure,
    fetchSurgeons,
    fetchCoSurgeons,
    fetchPatients,
    fetchFilteredPatients,
    fetchPatient,
    fetchFacilities,
    fetchEmails,
    fetchCancelEmails,
    resetFilteredPatients
};
