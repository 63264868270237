import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import RoleForm from "./form";
import RoleList from "./list";
import Section from "../../../components/layout/Section";
import {
    addRole,
    fetchRoles,
    deleteRole,
    fetchRole,
    updateRole,
    resetRoleForm
} from '../../../redux/userManagement/roles/actionCreator';

const RolesData = () => {
    const dispatch = useDispatch();
    const roles = useSelector(state => state.umRoles.roles);
    const role = useSelector(state => state.umRoles.role);
    const [form] = Form.useForm();

    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchRoles({page: 1, perPage: 10}))
        };
        fetchData().then(r => {});
    }, []);

    const getRoles = async (page, perPage) => await dispatch(fetchRoles({page, perPage}));
    const roleInfo = (_id) => dispatch(fetchRole(_id, showModalEdit));

    const showModal = async type => {
        await dispatch(resetRoleForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
    };

    const handleOk = () => {
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
    };

    return (
        <Section>
            <RoleForm
                form={form}
                state={state}
                role={role}
                addRole={addRole}
                updateRole={updateRole}
                handleOk={handleOk}
               />

            <RoleList
                state={state}
                setState={setState}
                showModal={showModal}
                roles={roles}
                getRoles={getRoles}
                roleInfo={roleInfo}
                deleteRole={deleteRole} />
        </Section>
    );
};

export default RolesData;
