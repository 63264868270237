import actions from "./actions";

const {FETCH_FACILITIES, FETCH_FACILITY, RESET_FACILITY} = actions;
const initState = {
    facilities: [],
    facility: {
        _id: null,
        name: null,
        status: null,
        description: null
    }
};

const FacilityReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_FACILITIES:
            return {
                ...state,
                facilities: data
            }
        case RESET_FACILITY:
            return {
                ...state,
                facility: initState.facility,
            };
        case FETCH_FACILITY:
            return {
                ...state,
                facility: data,
            };
        default:
            return state;
    }
}

export default FacilityReducer;
