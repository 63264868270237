import { Constants } from "../../../config/constants";
import { Requests as req } from "../../../utils/requests";
import actions from './actions';
import {fetchProcedures as fetchUtilitiesProcedures} from "../../utilities/actionCreator";

const { fetchRepresentativesAction, fetchEmailLogsAction, fetchCancelEmailLogsAction, fetchRepresentativeAction, resetRepresentativeAction } = actions;

export const addRepresentative = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({ url: Constants.SUPPORTIVE, auth: 'bearer', body: data, validate}, async (cb) => {
            await dispatch(fetchRepresentatives());
            await dispatch(action);
        })
    };
};

export const fetchRepresentatives = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.SUPPORTIVE,
            auth: 'bearer'
        }, (cb) => dispatch(fetchRepresentativesAction(cb)))
    };
};

export const updateRepresentative = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.SUPPORTIVE + data._id, auth: 'bearer', body: data, validate},  async (cb) => {
            await dispatch(fetchRepresentatives());
            await dispatch(action);
        })
    };
};

export const resetRepresentativeSurgeonForm = () => dispatch => dispatch(resetRepresentativeAction());

export const fetchRepresentative = (_id, action) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.SUPPORTIVE + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchRepresentativeAction(cb));
            await dispatch(action);
            if (cb?.supportingArea?.length) dispatch(fetchUtilitiesProcedures({supportiveType: cb.supportingArea}))
        })
    };
};

export const deleteRepresentative = (_id) => {
    return async dispatch => {
        await req.deleteRequest({ url: Constants.SUPPORTIVE + _id, auth: 'bearer'}, (cb) => dispatch(fetchRepresentatives()))
    };
};

//Email Logs

export const fetchEmailLogs = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.EMAIL,
            auth: 'bearer'
        }, (cb) => dispatch(fetchEmailLogsAction(cb)))
    };
};

//Email Logs

export const fetchCancelEmailLogs = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.EMAIL + 'cancel/',
            auth: 'bearer'
        }, (cb) => dispatch(fetchCancelEmailLogsAction(cb)))
    };
};


