import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import UserList from "./list";
import UserForm from "./form";
import Section from "../../../components/layout/Section";
import {
    addUser,
    deleteUser,
    fetchUser,
    fetchUsers,
    fetchUsersAsSurgeons,
    resetUserForm,
    updatePass,
    updateUser
} from "../../../redux/userManagement/users/actionCreator";
import PasswordForm from "./passwordForm";
import {fetchRoles} from "../../../redux/userManagement/roles/actionCreator";

const UsersData = () => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.umUsers.users);
    const user = useSelector(state => state.umUsers.user);
    const roles = useSelector(state => state.umRoles.roles);
    const [form] = Form.useForm();
    const [passForm] = Form.useForm();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });
    const [passState, setPassState] = useState({
        visible: false,
        _id: null,
        modalType: 'secondary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchUsers({page: 1, perPage: 10}));
            await dispatch(fetchRoles())
            await dispatch(fetchUsersAsSurgeons())
        };
        fetchData().then(r => {
        });
    }, []);

    const getUsers = async (page, perPage) => await dispatch(fetchUsers({page, perPage}));
    const getUserInfo = (_id) => dispatch(fetchUser(_id, showModalEdit));

    const showModal =  type => {
        dispatch(resetUserForm());
        form.resetFields();
        setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {

        setState({
            ...state,
            visible: true,
            modalType: "secondary",
        });
        await form.resetFields();
    };

    const showPassModalEdit = async (_id) => {
        setPassState({
            ...passState,
            visible: true,
            _id: _id,
            modalType: "secondary",
        });
        await passForm.resetFields();
    };

    const handleOk = async () => {
        await  dispatch(resetUserForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields();
    };
    const handlePassOk = async () => {
        await  dispatch(resetUserForm());
        setPassState({
            ...passState,
            visible: false,
            colorModal: false,
        });
        passForm.resetFields()
    };

    return (
        <Section>
            <UserList
                state={state}
                setState={setState}
                showModal={showModal}
                users={users}
                getUsers={getUsers}
                getUserInfo={getUserInfo}
                getPassInfo={showPassModalEdit}
                deleteUser={deleteUser}
            />

            <UserForm
                form={form}
                state={state}
                handleOk={handleOk}
                user={user}
                roles={roles}
                addUser={addUser}
                updateUser={updateUser}
            />
            <PasswordForm
                passForm={passForm}
                passState={passState}
                handlePassOk={handlePassOk}
                user={user}
                changePassword={updatePass}
            />
        </Section>
    );
};

export default UsersData;
