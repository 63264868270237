import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import FeatherIcon from "feather-icons-react";
import FontAwesome from "react-fontawesome";
import {Button, PageHeader, Row, Spin, Input, Table, Form, Select} from "antd";
import {Alert} from "../../../utils/alert";
import {Scope} from "../../../services/scopeService";
import Papa from "papaparse";
import moment from "moment";

import {resetFilteredPatients} from "../../../redux/utilities/actionCreator";


const PatientList = (
    {
        patients,
        getPatients,
        fetchFilteredPatients,
        isLoading,
        form,
        utilitiesPatients,
        showModal,
        getPatientInfo,
        viewPatientInfo,
        deletePatient,
        addPatientInCSV,
    }
) => {
    const dispatch = useDispatch();
    //CSV
    const [csvPatients, setCsvPatients] = useState([])
    const [filteredPatients, setFilteredPatients] = useState([])

    const submitCSV =  () => {
        // console.log(csvPatients.slice(0, -1))
        if (csvPatients.length)  dispatch(addPatientInCSV(csvPatients.slice(0, -1)));
        setCsvPatients([])
    }
    //End CSV

    const columns = [
        {
            title: 'Patient Name',
            width:'6%',
            render: (patient) => (
                <Row>
                    {patient?.firstName ? patient?.firstName + " " + patient?.lastName : ""}
                </Row>
            )
        },
        {
            title: 'Chart ID',
            width:'3%',
            render: (patient) => (
                <Row>
                    {patient?.chartId ? patient.chartId : "Not Available"}
                </Row>
            )
        },
        {
            title: 'Phone',
            width:'4%',
            render: (patient) => (
                <Row>
                    {patient?.phone ? patient.phone : ""}
                </Row>
            )
        },
        {
            title: 'Date of Birth',
            width:'4%',
            render: (patient) => (
                <Row>
                    {moment(patient?.dob).format('MM-DD-YYYY')}
                </Row>
            ),
        },
        {
            title: 'Insurance Info',
            width:'10%',
            render: (patient) => (
                <Row>
                    {
                        utilitiesPatients?.length ? (
                            <>
                                {patient?.insuranceInfo[0]?.insurance ? <b className="mr-2">{`Insured Name: `}</b> : ""}
                                {` ${patient?.insuranceInfo[0]?.insurance ? patient.insuranceInfo[0].insurance : ""}`}

                                {patient?.insuranceInfo[0]?.insuredProvider ? <b className="mr-2">{`Insurance Provider: `}</b> : ""}
                                {` ${patient?.insuranceInfo[0]?.insuredProvider ? patient.insuranceInfo[0].insuredProvider : ""}`}

                                {patient?.insuranceInfo[0]?.insuredPhone ? <b className="mr-2">{` Phone: `}</b> : ""}
                                {` ${patient?.insuranceInfo[0]?.insuredPhone ? patient.insuranceInfo[0].insuredPhone : ""}`}
                            </>

                        ) : (
                            <>
                                {patient?.lastInsurance?.insurance ? <b className="mr-2">{`Insured Name: `}</b> : ""}
                                {` ${patient?.lastInsurance?.insurance ? patient.lastInsurance.insurance : ""}`}

                                {patient?.lastInsurance?.insuredProvider ? <b className="mr-2">{`Insurance Provider: `}</b> : ""}
                                {` ${patient?.lastInsurance?.insuredProvider ? patient.lastInsurance.insuredProvider : ""}`}

                                {patient?.lastInsurance?.insuredPhone ? <b className="mr-2">{` Phone: `}</b> : ""}
                                {` ${patient?.lastInsurance?.insuredPhone ? patient.lastInsurance.insuredPhone : ""}`}
                            </>
                        )
                    }

                </Row>
            )
        },
        {
            title: 'Action',
            dataIndex: '_id',
            width:'5%',
            key: '_id',
            render: (key) => <>
                <FontAwesome
                    onClick={() => viewPatientInfo(key)}
                    name={"eye"}
                    className="color-info"
                    size="2x"
                    style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', padding: "5px", cursor: "pointer"}}
                />
                {Scope.checkScopes(['records_patientInfo_update']) && (
                    <FontAwesome
                        onClick={() => getPatientInfo(key)}
                        name={"edit"}
                        className="color-info"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', padding: "5px", cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['records_patientInfo_delete']) && (
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deletePatient(key)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', padding: "5px", cursor: "pointer"}}
                    />
                )}
            </>
        },
    ];

    const resetFilters = async () => {
        await setFilteredPatients([])
        await dispatch(resetFilteredPatients())
    }

    const headerButtons = [
        <div key="1" className="page-header-action">
            {Scope.checkScopes(['records_patientInfo_create']) && (
                <>
                    <Button className="mr-3" size="small" type="dashed" onClick={() => submitCSV()}>
                        <Row>
                            <FeatherIcon icon="plus" size={14}/>
                            Submit CSV
                        </Row>
                    </Button>
                    <div className="mb-2">

                        <Input
                            type="file"
                            placeholder="Import CSV"
                            size="small"
                            accept=".csv,.xlsx,.xls"
                            onChange={(e) => {
                                console.log(e.target.value)
                                const files = e.target.files;
                                if (files) {
                                    Papa.parse(files[0], {
                                        delimiter: ",",
                                        header: true,
                                        dynamicTyping: true,
                                        transformHeader:function(h) { return h.replace(/\s/g, ''); },
                                        complete: function(results) {
                                            setCsvPatients(results?.data)
                                        }}
                                    )}
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    ];

    const filteredForm = [
        <Form
            name={'reportFilter'}
            form={form}
            id={'reportFilterForm'}
            className="my-form"
        >
            <Form.Item
                name="patientId"
                label="Search"
            >
                <Select
                    showSearch
                    placeholder="FirstName / LastName / Chart ID / Phone / Email"
                    loading={isLoading}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    onSearch={(event) => event?.length && event.length > 2 ? dispatch(fetchFilteredPatients({name: event})) : null}
                    onSelect={(event) => {
                        if (event) {
                            const patient = utilitiesPatients?.filter(x => x._id.toString() === event.toString());
                            if (patient) {
                                setFilteredPatients(patient)
                                form.setFieldsValue({ patientId: null })
                            }
                        }
                    }}
                >
                    {utilitiesPatients?.map((patient, si) => (
                        <Select.Option key={si} value={patient._id}>
                            {patient?.firstName + " " + patient?.lastName + " - " + " " + " Chart ID: " + (patient?.chartId ? patient?.chartId : "not available") + " - " + " " + " Phone: " + patient?.phone + " - " + " " + " Email: " + patient?.email}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Form>
    ]

    const resetAndNewPatient = [
        <Row className="float-right mb-1">
            <Button className="mt-0 mr-2" size="small" type="default" onClick={resetFilters}>
                Reset
            </Button>
            {Scope.checkScopes(['records_patientInfo_create']) && (
                <Button className="ml-15 float-right" size="small" type="primary" onClick={() => showModal('primary')}>
                    <Row>
                        <FeatherIcon icon="plus" size={14}/>
                        New Patient
                    </Row>
                </Button>
            )}
        </Row>
    ]

    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Patient Information"
                subTitle={`List of all ${filteredPatients?.length ? filteredPatients?.length : utilitiesPatients?.length ? utilitiesPatients.length : patients?.data?.length ? patients.data.length : ""} patient information.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                {filteredForm}
                {resetAndNewPatient}
                <Table
                    pagination={{
                        defaultPageSize: 30,
                        total: patients?.total,
                        current: patients?.page,
                        onChange: async (pageNo, perPageNo) => await getPatients(pageNo, perPageNo)
                    }}
                    rowKey="_id"
                    bordered={false}
                    loading={!patients?.data?.length ? <Spin  className="example"/> : ""}
                    dataSource={filteredPatients?.length ? filteredPatients : utilitiesPatients?.length ? utilitiesPatients : patients?.data ? patients?.data : ""}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
};

export default PatientList;
