import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Modal, Select, Row} from "antd";
import {fetchSurgeonForProfile} from "../../../redux/patient-management/surgeons/actionCreator";
import {Auth} from "../../../utils/auth";

const FormData = ({form, state, surgeon, addSurgeon, usersAsSurgeons, updateSurgeon, isLoading, handleOk}) => {
    const dispatch = useDispatch()
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}}
    const getUsers = Auth.getUserInfo()
    const validateMessages = {
        required: '${label} is required!',
    };

    const handleSubmit = async (data) => {
        if (surgeon?._id) {
            Object.assign(data, {_id: surgeon._id});
            await dispatch(updateSurgeon(data, handleOk, form.setFields))
            await dispatch(fetchSurgeonForProfile(getUsers._id));
        } else
            await dispatch(addSurgeon(data, handleOk, form.setFields));
    };

    const footerButtons = [
        <Button
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="small">
            {isLoading ? 'Loading...' : 'Update'}
        </Button>
    ];

    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Personal Info"
                visible={state.visible}
                onCancel={() => handleOk()}
                footer={footerButtons}
                width={1000}
            >
                <Form
                    {...layout}
                    name={'surgeonForm'}
                    form={form}
                    id={'myForm'}
                    validateMessages={validateMessages}
                    onFinish={handleSubmit}>
                    <Row md={22}>
                        <Col md={11}>
                            <br/>
                            <Form.Item
                                name="firstName"
                                rules={[{required: true}]}
                                initialValue={surgeon?.firstName ? surgeon.firstName : null}
                                label="First Name">
                                <Input placeholder="First Name"/>
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                rules={[{required: true}]}
                                initialValue={surgeon?.lastName ? surgeon.lastName : null}
                                label="Last Name">
                                <Input placeholder="Last Name"/>
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[{required: true}]}
                                initialValue={surgeon?.phone ? surgeon.phone : null}
                                label="Phone">
                                <Input placeholder="Phone"/>
                            </Form.Item>
                            <Form.Item
                                name="fax"
                                rules={[{required: false}]}
                                initialValue={surgeon?.fax ? surgeon.fax : null}
                                label="Fax">
                                <Input placeholder="Fax"/>
                            </Form.Item>
                        </Col>
                        <Col md={11}>
                            <br/>
                            <Form.Item
                                name="department"
                                rules={[{required: false}]}
                                hidden={true}
                                initialValue={surgeon?.department ? surgeon.department : null}
                                label="Department">
                                <Input placeholder="Department Name"/>
                            </Form.Item>
                            <Form.Item
                                name="companyName"
                                rules={[{required: false}]}
                                initialValue={surgeon?.companyName ? surgeon.companyName : null}
                                label="Company">
                                <Input placeholder="Company Name"/>
                            </Form.Item>
                            <Form.Item
                                name="address"
                                initialValue={surgeon?.address ? surgeon.address : null}
                                rules={[{required: false}]}
                                label="Address">
                                <Input.TextArea placeholder="Address"/>
                            </Form.Item>
                            <Form.Item
                                name="status"
                                hidden={true}
                                initialValue={surgeon?.status ? surgeon.status : ''}
                                rules={[{required: true}]}
                                label="Status">
                                <Select>
                                    <Select.Option value="">Status</Select.Option>
                                    <Select.Option value="active">Active</Select.Option>
                                    <Select.Option value="inactive">Inactive</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

        </Col>

    )
}

export default FormData;