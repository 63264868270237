import React from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";

const MainSurgeonForm = ({
    isLoading,
    state,
    handleOk,
    form,
    handleSubmit,
    surgeon,
    usersAsSurgeons,
    onSurgeonEmailChange,
}) => {
    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Surgeon Form"
                visible={state.visible}
                onCancel={handleOk}
                width={800}
                footer={[
                    <Button
                        form="myForm"
                        key="submit"
                        htmlType="submit"
                        type="primary"
                        disabled={isLoading}
                        size="small"
                    >
                        {isLoading ? "Loading..." : "Submit"}
                    </Button>,
                ]}
            >
                <Form
                    name="surgeonForm"
                    form={form}
                    id="myForm"
                    onFinish={handleSubmit}
                >
                    <Row gutter={16}>
                        <Col md={12} style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <Form.Item
                                name="userId"
                                initialValue={surgeon?.userInfo?._id || ""}
                                rules={[{ required: true }]}
                                label="Email"
                            >
                                <Select
                                    showSearch
                                    loading={isLoading}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    onChange={onSurgeonEmailChange}
                                >
                                    <Select.Option value="">Select</Select.Option>
                                    {usersAsSurgeons?.map((usersAsSurgeon) => (
                                        <Select.Option
                                            key={usersAsSurgeon._id}
                                            value={usersAsSurgeon._id}
                                        >
                                            {usersAsSurgeon.email}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="firstName"
                                initialValue={surgeon?.firstName || ""}
                                rules={[{ required: true }]}
                                label="First Name"
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name="lastName"
                                initialValue={surgeon?.lastName || ""}
                                rules={[{ required: true }]}
                                label="Last Name"
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                initialValue={surgeon?.phone || ""}
                                rules={[{ required: true }]}
                                label="Phone"
                            >
                                <Input placeholder="Phone" />
                            </Form.Item>
                            <Form.Item
                                name="fax"
                                initialValue={surgeon?.fax || ""}
                                rules={[{ required: false }]}
                                label="Fax"
                            >
                                <Input placeholder="Fax" />
                            </Form.Item>
                        </Col>
                        <Col md={12} style={{ paddingRight: 16, paddingLeft: 16 }}>
                            <Form.Item
                                name="department"
                                initialValue={surgeon?.department || ""}
                                rules={[{ required: false }]}
                                label="Department"
                            >
                                <Input placeholder="Department Name" />
                            </Form.Item>
                            <Form.Item
                                name="companyName"
                                initialValue={surgeon?.companyName || ""}
                                rules={[{ required: false }]}
                                label="Company"
                            >
                                <Input placeholder="Company Name" />
                            </Form.Item>
                            <Form.Item
                                name="address"
                                initialValue={surgeon?.address || ""}
                                rules={[{ required: false }]}
                                label="Address"
                            >
                                <Input.TextArea placeholder="Address" />
                            </Form.Item>
                            <Form.Item
                                name="status"
                                initialValue={surgeon?.status || ""}
                                rules={[{ required: true }]}
                                label="Status"
                            >
                                <Select>
                                    <Select.Option value="">Status</Select.Option>
                                    <Select.Option value="active">Active</Select.Option>
                                    <Select.Option value="inactive">Inactive</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Col>
    );
};

export default MainSurgeonForm;