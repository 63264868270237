import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import PatientForm from "./form";
import PatientList from "./list";
import Section from "../../../components/layout/Section";
import {
    addPatient,
    addPatientInCSV,
    checkPatientInfo,
    getChartIdInfo,
    deletePatient,
    fetchPatient,
    fetchPatients,
    fetchStates,
    getCityInfo,
    getCityInfoWithZipCodes,
    getStateInfo,
    resetPatientForm,
    resetZipCodesForm,
    updatePatient,
    getInsuredStateInfo,
    getInsuredCityInfo,
    getInsuredCityInfoWithZipCodes,
} from "../../../redux/patient-management/patients/actionCreator";
import {resetScheduleForm,} from "../../../redux/schedule-management/schedules/actionCreator";
import {fetchFilteredPatients} from "../../../redux/utilities/actionCreator";


const PatientData = (props) => {
    const patients = useSelector(state => state.pmPatents.patients);
    const patient = useSelector(state => state.pmPatents.patient);
    const checkPatient = useSelector(state => state.pmPatents.checkPatient);
    const utilitiesPatients = useSelector(state => state.utilities.patients);
    const states = useSelector(state => state.pmPatents.states);
    const cities = useSelector(state => state.pmPatents.cities);
    const insuredCities = useSelector(state => state.pmPatents.insuredCities);
    const zipCodes = useSelector(state => state.pmPatents.zipCodes);
    const insuredZipCodes = useSelector(state => state.pmPatents.insuredZipCodes);

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState("1");

    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    const [getPatient, setPatient] = useState({
        visible: false,
        modalType: 'secondary',
        colorModal: false,
        uploading: false,
    });

    const [filterState, setFilterState] = useState({filterVisible: false});

    const getPatients = async (page, perPage) => await dispatch(fetchPatients({page, perPage}));

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchPatients({page: 1, perPage: 30}));
            await dispatch(fetchStates());
            await dispatch(getCityInfoWithZipCodes());
        };
        fetchData().then(r => {
        });
    }, []);

    const changeTab = (activeKey) => {
        setActiveTab(activeKey.toString());
    };

    const getPatientInfo = (_id) => dispatch(fetchPatient(_id, showModalEdit));
    const viewPatientInfo = (_id) => dispatch(fetchPatient(_id, showPatientModal));

    const showModal = async type => {
        dispatch(resetPatientForm());
        dispatch(resetZipCodesForm());
        setActiveTab("1");
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalEdit = async () => {
        setActiveTab("1");
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };

    const showPatientModal = async () => {
        setActiveTab("4");
        await form.resetFields();
        await setPatient({
            ...getPatient,
            visible: true,
            modalType: "secondary",
        });
        await form.resetFields();
    };

    const handleOk = async () => {
        await dispatch(resetPatientForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        await form.resetFields();
    };

    const nextHandleOk = async () => {
        await dispatch(resetPatientForm());
        setPatient({
            ...getPatient,
            visible: false,
            colorModal: false,
        });
    }

    //Schedule Form Function
    const [scheduleState, setScheduleState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });


    const showTheScheduleModal = async (type, date = null) => {
        await dispatch(resetScheduleForm())
        await form.resetFields()
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        await setScheduleState({
            ...scheduleState,
            visible: true,
            modalType: type,
        });
        await form.resetFields()
        if (date) {
            const myDate = new Date(date);
            const myDateYMD = new Date(myDate.getTime() - (myDate.getTimezoneOffset() * 60000))
                .toISOString()
                .split("T")[0];

            await form.setFieldsValue({
                ["date"]: myDateYMD
            })
        }
    };

    return (
        <Section>
            <PatientList
                fetchFilteredPatients={fetchFilteredPatients}
                form={form}
                showModal={showModal}
                patients={patients}
                getPatients={getPatients}
                utilitiesPatients={utilitiesPatients}
                isLoading={'isLoading'}
                getPatientInfo={getPatientInfo}
                addPatientInCSV={addPatientInCSV}
                deletePatient={deletePatient}
                viewPatientInfo={viewPatientInfo}
                setFilterState={setFilterState}
                filterState={filterState}
            />
            <PatientForm
                getPatient={getPatient}
                getChartIdInfo={getChartIdInfo}
                state={state}
                states={states}
                cities={cities}
                insuredCities={insuredCities}
                insuredZipCodes={insuredZipCodes}
                changeTab={changeTab}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                zipCodes={zipCodes}
                addPatient={addPatient}
                handleOk={handleOk}
                nextHandleOk={nextHandleOk}
                myForm={form}
                patient={patient}
                patients={patients}
                updatePatient={updatePatient}
                getStateInfo={getStateInfo}
                getCityInfo={getCityInfo}
                checkPatient={checkPatient}
                checkPatientInfo={checkPatientInfo}
                getCityInfoWithZipCodes={getCityInfoWithZipCodes}
                showScheduleModal={showTheScheduleModal}
                getInsuredStateInfo={getInsuredStateInfo}
                getInsuredCityInfo={getInsuredCityInfo}
                getInsuredCityInfoWithZipCodes={getInsuredCityInfoWithZipCodes}
            />

        </Section>
    )
}

export default PatientData;