import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, PageHeader, Spin, Table, Card, Col, Row, Statistic, Form, Badge} from 'antd';
import {
    fetchWeekSchedules,
    fetchFilteredSchedules,
    resetServerFilteredScheduleForm,
    getServerFilteredMonth
} from "../redux/schedule-management/schedules/actionCreator";
import moment from "moment";
import { CSVLink } from "react-csv";
import Section from "./layout/Section";
import {PrinterOutlined, ArrowUpOutlined, FilterOutlined, DownloadOutlined} from "@ant-design/icons";
import {useReactToPrint} from 'react-to-print';
import {fetchSurgeonForProfile} from "../redux/patient-management/surgeons/actionCreator";
import {Auth} from "../utils/auth";
import { DualAxes } from '@ant-design/plots';
import DashBoardFilter from "./filter";
import {
    fetchCoSurgeons as fetchUtilitiesCoSurgeons,
    fetchFacilities as fetchUtilitiesFacilities, fetchFilteredPatients, fetchPatients as fetchUtilitiesPatients,
    fetchProcedures as fetchUtilitiesProcedures,
    fetchSurgeons as fetchUtilitiesSurgeons, resetFilteredPatients
} from "../redux/utilities/actionCreator";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

const Dashboard = () => {
    const schedules = useSelector(state => state.smSchedules.weeklySchedules);
    const serverFilteredSchedules = useSelector(state => state.smSchedules.serverFilteredSchedules);
    const activeSchedules = useSelector(state => state.smSchedules.activeSchedules);
    const utilitiesSurgeons = useSelector(state => state.utilities.surgeons);
    const utilitiesCoSurgeons = useSelector(state => state.utilities.coSurgeons);
    const utilitiesPatients = useSelector(state => state.utilities.patients);
    const utilitiesProcedures = useSelector(state => state.utilities.procedures);
    const utilitiesFacilities = useSelector(state => state.utilities.facilities);
    const surgeon = useSelector(state => state.pmSurgeons.surgeon);
    const dispatch = useDispatch();
    const getUsers = Auth.getUserInfo()
    const [filter] = Form.useForm();
    const [filterState, setFilterState] = useState({filterVisible: false});
    const [getTheSurgeon, setTheSurgeon] = useState({})
    const initialFilters = {surgeonId: ""};
    const [filters, setFilters] = useState({
        surgeonId: "",
        active: "",
        scheduleStatus: "",
        daysValue: [],
        coSurgeonId: "",
        procedure: [],
        facilityId: "",
        patientId: ""
    });
    const [getActiveSchedules, setActiveSchedules] = useState([])
    const [viewChart, setViewChart] = useState(false)
    const now = new Date();
    const today = moment().format("YYYY-MM-DD")

    const  filteredSchedules = schedules?.filter((item,i ) => item?.scheduleCancelStatus !== "inactive" &&  moment(item.date).format("YYYY-MM-DD") > today)
    const  filteredSchedulesExceptInActive = schedules?.filter((item,i ) => item?.scheduleCancelStatus !== "inactive")

    useEffect(() => {
        const fetchData = async () => {
            if (
                filters?.surgeonId
                || filters?.active
                || filters?.scheduleStatus
                || filters?.daysValue?.length
                || filters?.coSurgeonId
                || filters?.procedure?.length
                || filters?.facilityId
                || filters?.patientId
            ) await dispatch(fetchFilteredSchedules(filters))
        };
        fetchData().then(r => {});

    },[filters])

    const active = async () => {
        await dispatch(resetServerFilteredScheduleForm())
    }

    const getMonthYear = async (data) => {
        await dispatch(getServerFilteredMonth(data))
    }

    const year = now.getFullYear()

    // Start month mapping

    const getDaysInMonth = (month, year) => (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)

    const jan = getDaysInMonth(1,year)

    const janSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jan[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jan.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jan[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jan.slice(-1)[0]).format("YYYY-MM-DD") )

    const feb = getDaysInMonth(2,year)
    const febSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(feb[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(feb.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(feb[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(feb.slice(-1)[0]).format("YYYY-MM-DD") )

    const mar = getDaysInMonth(3,year)
    const marSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(mar[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(mar.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(mar[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(mar.slice(-1)[0]).format("YYYY-MM-DD") )

    const apr = getDaysInMonth(4,year)
    const aprSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(apr[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(apr.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(apr[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(apr.slice(-1)[0]).format("YYYY-MM-DD") )

    const may = getDaysInMonth(5,year)
    const maySchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(may[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(may.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(may[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(may.slice(-1)[0]).format("YYYY-MM-DD") )

    const jun = getDaysInMonth(6,year)
    const junSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jun[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jun.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jun[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jun.slice(-1)[0]).format("YYYY-MM-DD") )

    const jul = getDaysInMonth(7,year)
    const julSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jul[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jul.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(jul[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(jul.slice(-1)[0]).format("YYYY-MM-DD") )

    const aug = getDaysInMonth(8,year)
    const augSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(aug[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(aug.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(aug[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(aug.slice(-1)[0]).format("YYYY-MM-DD") )

    const sept = getDaysInMonth(9,year)
    const septSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(sept[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(sept.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(sept[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(sept.slice(-1)[0]).format("YYYY-MM-DD") )

    const oct = getDaysInMonth(10,year)
    const octSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(oct[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(oct.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(oct[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(oct.slice(-1)[0]).format("YYYY-MM-DD") )

    const nov = getDaysInMonth(11,year)
    const novSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(nov[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(nov.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(nov[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(nov.slice(-1)[0]).format("YYYY-MM-DD") )

    const dec = getDaysInMonth(12,year)
    const decSchedule = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(dec[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(dec.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(dec[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(dec.slice(-1)[0]).format("YYYY-MM-DD") )

    // End month mapping

    // Start year mapping

    const getDaysInYear = (year) => (new Array(365)).fill('').map((v,i)=>new Date(year, 12,i+1))

    const twentyTwo = getDaysInYear(2021)
    const yearTwentyTwo = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyTwo[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyTwo.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyTwo[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyTwo.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyThree = getDaysInYear(2022)
    const yearTwentyThree = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyThree[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyThree.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyThree[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyThree.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyFour = getDaysInYear(2023)
    const yearTwentyFour = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyFour[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyFour.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyFour[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyFour.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyFive = getDaysInYear(2024)
    const yearTwentyFive = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyFive[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyFive.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyFive[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyFive.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentySix = getDaysInYear(2025)
    const yearTwentySix = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentySix[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentySix.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentySix[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentySix.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentySeven = getDaysInYear(2026)
    const yearTwentySeven = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentySeven[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentySeven.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentySeven[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentySeven.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyEight = getDaysInYear(2027)
    const yearTwentyEight = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyEight[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyEight.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyEight[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyEight.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyNine = getDaysInYear(2028)
    const yearTwentyNine = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyNine[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyNine.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyNine[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyNine.slice(-1)[0]).format("YYYY-MM-DD") )

    const twentyThirty = getDaysInYear(2029)
    const yearTwentyThirty = serverFilteredSchedules?.length ?
        serverFilteredSchedules?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyThirty[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyThirty.slice(-1)[0]).format("YYYY-MM-DD") )
        : filteredSchedulesExceptInActive?.filter((item,i ) => moment(item.date).format("YYYY-MM-DD") >= moment(twentyThirty[0]).format("YYYY-MM-DD") && moment(item.date).format("YYYY-MM-DD") <= moment(twentyThirty.slice(-1)[0]).format("YYYY-MM-DD") )

    // End year mapping

    const uniqueSurgeon = [];
    const filteredSurgeon = activeSchedules?.filter((item) => item?.surgeon?._id === surgeon?._id)

    for (const item of serverFilteredSchedules.length ? serverFilteredSchedules  : filteredSchedulesExceptInActive ? filteredSchedulesExceptInActive : getActiveSchedules?.length ? getActiveSchedules : activeSchedules && activeSchedules.length ? activeSchedules : []) {
        const isDuplicate = uniqueSurgeon?.find((obj) => obj?.surgeon._id === item?.surgeon._id );
        if (!isDuplicate) {
            uniqueSurgeon.push(item);
        }
    }

    // Chart config for Month

    const yearIn = new Date().getFullYear().toString();

    const monthlySchedule = () => {
        return (
            <>
                {
                    janSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 1, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`January-${yearIn}`}
                                    value={janSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    febSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 2, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`February-${yearIn}`}
                                    value={febSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    marSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 3, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`March-${yearIn}`}
                                    value={marSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    aprSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 4, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`April-${yearIn}`}
                                    value={aprSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    maySchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 5, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`May-${yearIn}`}
                                    value={maySchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    junSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 6, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`June-${yearIn}`}
                                    value={junSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    julSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 7, year: year})} style={{cursor:"pointer"}}  className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`July-${yearIn}`}
                                    value={julSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    augSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 8, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`August-${yearIn}`}
                                    value={augSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    septSchedule?.length ? (
                        <Col  span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 9, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`September-${yearIn}`}
                                    value={septSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    octSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 10, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`October-${yearIn}`}
                                    value={octSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    novSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 11, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`November-${yearIn}`}
                                    value={novSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    decSchedule?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({month: 12, year: year})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title={`December-${yearIn}`}
                                    value={decSchedule?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
            </>
        )
    }

    const uvData = [
        {
            month: `January-${yearIn}`,
            Schedules: janSchedule?.length,
        },
        {
            month: `February-${yearIn}`,
            Schedules: febSchedule?.length,
        },
        {
            month: `March-${yearIn}`,
            Schedules: marSchedule?.length,
        },
        {
            month: `April-${yearIn}`,
            Schedules: aprSchedule?.length,
        },
        {
            month: `May-${yearIn}`,
            Schedules: maySchedule?.length,
        },
        {
            month: `June-${yearIn}`,
            Schedules: junSchedule?.length,
        },
        {
            month: `July-${yearIn}`,
            Schedules: julSchedule?.length,
        },
        {
            month: `August-${yearIn}`,
            Schedules: augSchedule?.length,
        },
        {
            month: `September-${yearIn}`,
            Schedules: septSchedule?.length,
        },
        {
            month: `October-${yearIn}`,
            Schedules: octSchedule?.length,
        },
        {
            month: `November-${yearIn}`,
            Schedules: novSchedule?.length,
        },
        {
            month: `December-${yearIn}`,
            Schedules: decSchedule?.length,
        },
    ];

    const transformData = uniqueSurgeon.flatMap((item) => (
        [
            {
                month: `January-${yearIn}`,
                count: janSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `February-${yearIn}`,
                count: febSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `March-${yearIn}`,
                count: marSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `April-${yearIn}`,
                count: aprSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `May-${yearIn}`,
                count: maySchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `June-${yearIn}`,
                count: junSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `July-${yearIn}`,
                count: julSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `August-${yearIn}`,
                count: augSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `September-${yearIn}`,
                count: septSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `October-${yearIn}`,
                count: octSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `November-${yearIn}`,
                count: novSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: `December-${yearIn}`,
                count: decSchedule?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            }
        ]
    ))

    const config = {
        data: [uvData, transformData],
        xField: 'month',
        yField: ['Schedules', 'count'],
        geometryOptions: [
            {
                geometry: 'column',
                columnWidthRatio: 0.4,
            },
            {
                geometry: 'line',
                smooth: true,
                seriesField: 'name',
            },
        ],
        onReady: (plot) => {
            plot.on('element:click', (...args) => {
                if (args[0].data.data.month === "January-2023") {getMonthYear({month: 1, year: year})}
                if (args[0].data.data.month === "February-2023") {getMonthYear({month: 2, year: year})}
                if (args[0].data.data.month === "March-2023") {getMonthYear({month: 3, year: year})}
                if (args[0].data.data.month === "April-2023") {getMonthYear({month: 4, year: year})}
                if (args[0].data.data.month === "May-2023") {getMonthYear({month: 5, year: year})}
                if (args[0].data.data.month === "June-2023") {getMonthYear({month: 6, year: year})}
                if (args[0].data.data.month === "July-2023") {getMonthYear({month: 7, year: year})}
                if (args[0].data.data.month === "August-2023") {getMonthYear({month: 8, year: year})}
                if (args[0].data.data.month === "September-2023") {getMonthYear({month: 9, year: year})}
                if (args[0].data.data.month === "October-2023") {getMonthYear({month: 10, year: year})}
                if (args[0].data.data.month === "November-2023") {getMonthYear({month: 11, year: year})}
                if (args[0].data.data.month === "December-2023") {getMonthYear({month: 12, year: year})}
            });
        },
        annotations: {
            Schedules: [
                {
                    type: 'text',
                    position: [`November-${yearIn}`, 'max'],
                    content: 'Schedule History',
                },
            ],
        },
    };

    // Chart config for Month

    const yearlySchedule = () => {
        return (
            <>
                {
                    yearTwentyTwo?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({year: 2021})} style={{cursor:"pointer"}}  className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2022"
                                    value={yearTwentyTwo?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyThree?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card onClick={() =>getMonthYear({year: 2022})} style={{cursor:"pointer"}} className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2023"
                                    value={yearTwentyThree?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyFour?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2024"
                                    value={yearTwentyFour?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyFive?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2025"
                                    value={yearTwentyFive?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentySix?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2026"
                                    value={yearTwentySix?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentySeven?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2027"
                                    value={yearTwentySeven?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyEight?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2028"
                                    value={yearTwentyEight?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyNine?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2029"
                                    value={yearTwentyNine?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
                {
                    yearTwentyThirty?.length ? (
                        <Col span={ screenWidth > 700 ? 2 : 24}>
                            <Card className="mt-2" size="small" bordered={true}>
                                <Statistic
                                    title="Year-2030"
                                    value={yearTwentyThirty?.length}
                                    valueStyle={{
                                        color: '#27869b',
                                    }}
                                />
                            </Card>
                        </Col>
                    ) : (
                        ""
                    )
                }
            </>
        )
    }

    const yearData = [
        {
            month: '2022',
            Schedules: yearTwentyTwo?.length,
        },
        {
            month: '2023',
            Schedules: yearTwentyThree?.length,
        },
        {
            month: '2024',
            Schedules: yearTwentyFour?.length,
        },
        {
            month: '2025',
            Schedules: yearTwentyFive?.length,
        },
        {
            month: '2026',
            Schedules: yearTwentySix?.length,
        },
        {
            month: '2027',
            Schedules: yearTwentySeven?.length,
        },
        {
            month: '2028',
            Schedules: yearTwentyEight?.length,
        },
        {
            month: '2029',
            Schedules: yearTwentyNine?.length,
        },
        {
            month: '2030',
            Schedules: yearTwentyThirty?.length,
        },
    ];

    const transformYearData = uniqueSurgeon.flatMap((item) => (
        [
            {
                month: "2022",
                count: yearTwentyTwo?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2023",
                count: yearTwentyThree?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2024",
                count: yearTwentyFour?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2025",
                count: yearTwentyFive?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2026",
                count: yearTwentySix?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2027",
                count: yearTwentySeven?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2028",
                count: yearTwentyEight?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2029",
                count: yearTwentyNine?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
            {
                month: "2030",
                count: yearTwentyThirty?.filter(c => c.surgeon._id === item.surgeon._id)?.length,
                name:item?.surgeon?.firstName + " " + item?.surgeon?.lastName
            },
        ]
    ))

    const configYear = {
        data: [yearData, transformYearData],
        xField: 'month',
        yField: ['Schedules', 'count'],
        geometryOptions: [
            {
                geometry: 'column',
                columnWidthRatio: 0.4,
            },
            {
                geometry: 'line',
                smooth: true,
                seriesField: 'name',
            },
        ],

        annotations: {
            Schedules: [
                {
                    type: 'text',
                    position: ['2029', 'max'],
                    content: 'Schedule History',
                },
            ],
        },
    };

    // End Chart

    //Print
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () =>
            componentRef.current,
        documentTitle: 'Reports',
        pageStyle: "@media print {\n" +
            "  @page { size: landscape; margin: 8mm; }\n" +
            "}",
    })
    const [getPrint, setPrint] = useState(false)
    const showPrint =() => {
        setPrint(true)
    }
    const hidePrint =() => {
        setPrint(false)
    }


    //End Print

    useEffect(() => {
        const fetchData = async () => {
           if (getUsers && getUsers?.surgeon?._id) await dispatch(fetchSurgeonForProfile(getUsers._id));
        };
        fetchData().then(r => {
        });
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchWeekSchedules());
            await dispatch(fetchUtilitiesProcedures())
            await dispatch(fetchUtilitiesCoSurgeons())
            await dispatch(fetchUtilitiesSurgeons())
            await dispatch(fetchUtilitiesFacilities())
            // await dispatch(fetchUtilitiesPatients())
        };
        fetchData().then(r => null);
    }, [dispatch]);

    const columns1 = [
        {
            title: 'Scheduling Date',
            render: (schedule) => (
                <span>
                    {schedule?.date ?  moment(schedule.date).format("MM-DD-YYYY") : ""}
                </span>
            )
        },
        {
            title: 'Surgeon',
            render: (schedule) => schedule?.surgeon?.firstName + " " + schedule?.surgeon?.lastName
        },
        {
            title: 'Co Surgeon',
            render: (schedule) => (
                <span>
                    {` ${schedule?.coSurgeon?.length ? schedule?.coSurgeon?.map((item) => item.firstName + " " + item.lastName).join(", ") : []}`}
                </span>
            )
        },
        {
            title: 'Surgeries',
            render: (schedule) => (
                <span>
                    {` ${schedule?.procedure?.length ? schedule?.procedure?.map((item) => item.surgery).join(", ") : schedule?.procedure.surgery}`}
                </span>
            )
        },
        {
            title: 'Facility',
            render: (schedule) => schedule?.facility?.name ?  schedule?.facility?.name : ""
        },
        {
            title: 'Patient',
            render: (schedule) => (
                <span>
                    {schedule?.patient ?  schedule?.patient.firstName + " " + schedule?.patient.lastName : ""}
                </span>
            )
        },
        {
            title: 'Start Time',
            render: (schedule) => (
                <span>
                    {` ${schedule?.startTime ? moment(schedule.startTime, ["HH.mm"]).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'End Time',
            render: (schedule) => (
                <span>
                    {` ${schedule?.endTime ? moment(schedule.endTime, ["HH.mm"]).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'Status',
            render: (schedule) => (
                <span>
                    {schedule?.scheduleCancelStatus ? (<Badge size="default" dot="dot" />) : moment(schedule.date).format("YYYY-MM-DD") >= today ? (<Badge size="default" color="green" dot="dot" />) : (<Badge size="default" color="yellow" dot="dot" />)}
                </span>
            )
        },
    ];

    const columns2 = [
        {
            title: 'Scheduling Date',
            render: (schedule) => (
                <span>
                    {schedule?.date ?  moment(schedule.date).format("MM-DD-YYYY") : ""}
                </span>
            )
        },
        {
            title: 'Surgeon',
            render: (schedule) => schedule?.surgeon?.firstName + " " + schedule?.surgeon?.lastName
        },
        {
            title: 'Co Surgeon',
            render: (schedule) => (
                <span>
                    {` ${schedule?.coSurgeon?.length ? schedule?.coSurgeon?.map((item) => item.firstName + " " + item.lastName).join(", ") : []}`}
                </span>
            )
        },
        {
            title: 'Surgeries',
            render: (schedule) => (
                <span>
                    {` ${schedule?.procedure?.length ? schedule?.procedure?.map((item) => item.surgery).join(", ") : schedule?.procedure.surgery}`}
                </span>
            )
        },
        {
            title: 'Facility',
            render: (schedule) => schedule?.facility?.name ?  schedule?.facility?.name : ""
        },
        {
            title: 'Patient',
            render: (schedule) => (
                <span>
                    {schedule?.patient ?  schedule?.patient.firstName + " " + schedule?.patient.lastName : ""}
                </span>
            )
        },
        {
            title: 'Start Time',
            render: (schedule) => (
                <span>
                    {` ${schedule?.startTime ? moment(schedule.startTime, ["HH.mm"]).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'End Time',
            render: (schedule) => (
                <span>
                    {` ${schedule?.endTime ? moment(schedule.endTime, ["HH.mm"]).format("hh:mm a") : ""}`}
                </span>
            )
        },
        {
            title: 'Status',
            render: (schedule) => (
                <span>
                    {schedule?.scheduleCancelStatus ? "cancelled" : moment(schedule.date).format("YYYY-MM-DD") >= today ? "active" : "out-of-date"}
                </span>
            )
        },
    ];

    const screenWidth = window.innerWidth;

    const unique = [];
    for (const item of activeSchedules) {
        const isDuplicate = unique?.find((obj) => obj?.surgeon._id === item?.surgeon._id );
        if (!isDuplicate) {
            unique.push(item);
        }
    }

    const data = (getUsers && getUsers?.surgeon?._id ? filteredSurgeon : serverFilteredSchedules.length ? serverFilteredSchedules  : serverFilteredSchedules?.length ? serverFilteredSchedules : getActiveSchedules?.length ? getActiveSchedules : activeSchedules && activeSchedules.length ? activeSchedules : []).map(item => ({
        Schedule_Date: moment(item.date).format("MM-DD-YYYY"),
        Start_Time: moment(item.startTime, ["HH.mm"]).format("hh:mm a"),
        End_Time: moment(item.endTime, ["HH.mm"]).format("hh:mm a"),
        Procedure: item?.procedure?.map((p) => p.surgery).join(", "),
        Surgeon: item?.surgeon?.firstName + " " + item?.surgeon?.lastName,
        CoSurgeon: item?.coSurgeon?.map((p) => p.firstName + " " + p.lastName).join(", "),
        Patient: item?.patient.firstName + " " + item?.patient?.lastName,
        Facility: item?.facility?.name,
        Authorization: item?.authorization,
        Reps_and_Instruments: item?.repAndInstruments,
        Details: item?.details,
        Cpt_Codes: item?.cptCodes?.join(", "),
        Icd_10_Codes: item?.icd10Codes?.join(", "),
    }))
    const pdfData = (getUsers && getUsers?.surgeon?._id ? filteredSurgeon : serverFilteredSchedules.length ? serverFilteredSchedules : serverFilteredSchedules?.length ? serverFilteredSchedules : getActiveSchedules?.length ? getActiveSchedules : activeSchedules && activeSchedules.length ? activeSchedules : []).map(item => ({
        Schedule_Date: moment(item.date).format("MM-DD-YYYY"),
        Start_Time: moment(item.startTime, ["HH.mm"]).format("hh:mm a"),
        End_Time: moment(item.endTime, ["HH.mm"]).format("hh:mm a"),
        Procedure: item?.procedure?.map((p) => p.surgery).join(", "),
        Surgeon: item?.surgeon?.firstName + " " + item?.surgeon?.lastName,
        CoSurgeon: item?.coSurgeon?.map((p) => p.firstName + " " + p.lastName).join(", "),
        Patient: item?.patient.firstName + " " + item?.patient?.lastName,
        Facility: item?.facility?.name,
        Reps_and_Instruments: item?.repAndInstruments,
        Details: item?.details,
    }))

    const convertToPDF = () => {
        var doc = new jsPDF({
            orientation: "landscape",
            unit: "in",
        });


        autoTable(doc, {
            columns: [
                {header: 'Schedule_Date', dataKey: 'Schedule_Date'},
                {header: 'Start_Time', dataKey: 'Start_Time'},
                {header: 'End_Time', dataKey: 'End_Time'},
                {header: 'Procedure', dataKey: 'Procedure'},
                {header: 'Surgeon', dataKey: 'Surgeon'},
                {header: 'CoSurgeon', dataKey: 'CoSurgeon'},
                {header: 'Patient', dataKey: 'Patient'},
                {header: 'Facility', dataKey: 'Facility'},
                {header: 'Reps_and_Instruments', dataKey: 'Reps_and_Instruments'},
                {header: 'Details', dataKey: 'Details'},
            ],
            // head: [['Schedule_Date', 'Surgeon', 'CoSurgeon', 'Patient', 'Start_Time', 'End_Time', 'Authorization', 'Facility', "Reps_and_Instruments", 'Details', 'Cpt_Codes', 'Icd_10_Codes']],
            body: pdfData ? pdfData : [],
            tableWidth: 'auto',
        })

        doc.save(`${new Date().toLocaleString()} -Schedule.pdf`);

        // doc.autoTable(col, item);

    }

    const headerButtons = [
        <div key="1" className="page-header-actions">
            <>
                <Button hidden={getPrint} size="small" type="primary" onClick={() => active()}>
                    <Row>
                        Reset
                    </Row>
                </Button>
                {!getUsers?.surgeon ? (
                    <Button
                        hidden={getPrint}
                        size="medium"
                        className="minimum-mr"
                        type="secondary"
                        icon={<FilterOutlined/>}
                        onClick={ async () => {
                            setFilterState({...filterState, filterVisible: true})
                            await dispatch(resetFilteredPatients())
                        }
                    }
                    >
                        Filters
                    </Button>
                ) : ""
                }
                <CSVLink
                    data={data}
                    filename={`${new Date().toLocaleString()} -Schedule.csv`}
                    onClick={() => {}}
                >

                    <Button hidden={getPrint} size="small" type="default" >
                        <Row>
                            <DownloadOutlined title="Download CSV" /> CSV
                        </Row>
                    </Button>
                </CSVLink>
                <Button hidden={getPrint} onClick={() => convertToPDF()} size="small" type="default" >
                    <Row>
                        <DownloadOutlined title=" Download PDF" /> PDF
                    </Row>
                </Button>
            </>
        </div>,
    ];



    const dashboard = () => {
        return (
            <>
                <Col span={ screenWidth > 700 ? 4 : 24}>
                    <Card className="mt-2" bordered={true}>
                        <Statistic
                            title="Total Active Schedules"
                            value={getUsers && getUsers?.surgeon?._id ? Object.keys(filteredSurgeon).length :  Object.keys(activeSchedules).length}
                            valueStyle={{
                                color: '#3f8600',
                            }}
                            prefix={<ArrowUpOutlined />}
                            suffix={<small>{Object.keys(activeSchedules).length > 1 || Object.keys(surgeon).length > 1 ? "schedules" : "schedule"}</small>}
                        />
                    </Card>
                </Col>
                {
                    !getUsers && getUsers?.surgeon?._id ? (
                        unique?.map((item) => {
                            return (
                                <>
                                    <Col span={ screenWidth > 700 ? 4 : 24}>
                                        <Card className="mt-2" bordered={true}>
                                            <Statistic
                                                title={`${item?.surgeonName}`}
                                                value={activeSchedules?.filter(c => c.surgeon._id === item.surgeon._id)?.length}
                                                valueStyle={{
                                                    color: '#27869b',
                                                }}
                                                suffix={<small>{activeSchedules?.filter(c => c.surgeon._id === item.surgeon._id)?.length >1 ? "schedules" : "schedule"}</small>}
                                            />
                                        </Card>
                                    </Col>
                                </>
                            )
                        })
                    ) : []
                }
            </>
        )
    }


    return (

        <Section>
            <DashBoardFilter
                procedures={utilitiesProcedures}
                facilities={utilitiesFacilities}
                patients={utilitiesPatients}
                filter={filter}
                filterState={filterState}
                fetchFilteredPatients={fetchFilteredPatients}
                setFilterState={setFilterState}
                getTheSurgeon={getTheSurgeon}
                setTheSurgeon={setTheSurgeon}
                initialFilters={initialFilters}
                filters={filters}
                setFilters={setFilters}
                surgeons={utilitiesSurgeons}
                coSurgeons={utilitiesCoSurgeons}
            />

            <div className="text-right mr-3">
                <PrinterOutlined onMouseOver={showPrint} onMouseLeave={hidePrint}  onClick={handlePrint}/>
            </div>

            <br/>
            <div ref={componentRef}>
                {
                    screenWidth > 700 ? (
                        <Row gutter={16}>
                            {!viewChart ? monthlySchedule() : yearlySchedule()}
                        </Row>
                    ) : (
                        !viewChart ? monthlySchedule() : yearlySchedule()
                    )
                }
                {
                    !viewChart ? (
                        <DualAxes {...config} />
                    ) : (
                        <DualAxes {...configYear} />
                    )
                }
                <Row>
                    <Button type={!viewChart ? "primary" : ""} onClick={() => setViewChart(false)}>Month</Button>
                    <Button type={viewChart ? "primary" : ""} className="ml-1" onClick={() => setViewChart(true)}>Year</Button>
                </Row>

                <br/>
                {
                    screenWidth > 700 ? (
                        <Row gutter={16}>
                            {dashboard()}
                        </Row>
                    ) : (
                        dashboard()
                    )
                }
                <PageHeader
                    ghost={false}
                    title="Schedules"
                    subTitle={`List of ${getUsers && getUsers?.surgeon?._id ? filteredSurgeon.length : serverFilteredSchedules?.length ? serverFilteredSchedules.length : serverFilteredSchedules?.length ? serverFilteredSchedules.length : getActiveSchedules?.length ? getActiveSchedules.length : activeSchedules && activeSchedules.length ? activeSchedules.length : ""} schedules.`}
                    onBack={() => window.history.back()}
                    extra={headerButtons}
                >
                    <Table
                        pagination={!getPrint ? {pageSize: `${getPrint ? 1000 : 20}`, showSizeChanger: false} : false}
                        rowKey="_id"
                        bordered={false}
                        loading={!activeSchedules?.length ?  <Spin className="example"/> : ""}
                        dataSource={
                        getUsers && getUsers?.surgeon?._id ? filteredSurgeon
                            : serverFilteredSchedules?.length ? serverFilteredSchedules
                            : serverFilteredSchedules?.length ? serverFilteredSchedules
                                : getActiveSchedules?.length ? getActiveSchedules
                                    : activeSchedules && activeSchedules.length ? activeSchedules
                                        : []
                    }
                        columns={getPrint ? columns2 : columns1}
                        responsive={true}
                        scroll={
                            !getPrint ? (
                                screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }
                            ) : null
                        }
                    />
                </PageHeader>
            </div>
        </Section>
    )
}

export default Dashboard;