import {Constants} from '../../../config/constants';
import actions from './actions';
import {Requests as req} from "../../../utils/requests";

const { fetchRolesAction, fetchRoleAction, resetRoleFormAction } = actions;

export const addRole = (data, action) => {
    return async dispatch => {
        await req.postRequest({ url: Constants.USER_MANAGEMENT + 'roles', auth: 'bearer', body: data}, async (cb) => {
            await dispatch(fetchRoles());
            await dispatch(action);
        })
    };
};

export const fetchRoles = (queries) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.USER_MANAGEMENT + 'roles', queries, auth: 'bearer'}, (cb) => dispatch(fetchRolesAction(cb)))
    };
};

export const updateRole = (data, action) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.USER_MANAGEMENT + 'roles/' + data._id, auth: 'bearer', body: data},  async (cb) => {
            await dispatch(fetchRoles());
            await dispatch(action);
            // dispatch(fetchRoleAction(cb))
        })
    };
};

export const resetRoleForm = () => dispatch => dispatch(resetRoleFormAction());
export const fetchRole = (_id, action) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.USER_MANAGEMENT + 'roles/' + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchRoleAction(cb));
            await dispatch(action);
        })
    };
};

export const deleteRole = (id) => {
    return async dispatch => {
        await req.deleteRequest({ url: Constants.USER_MANAGEMENT + 'roles/' + id, auth: 'bearer'}, (cb) => dispatch(fetchRoles()))
    };
};
