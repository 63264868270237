import React, {Fragment, useRef, useState} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import FontAwesome from "react-fontawesome";
import FeatherIcon from "feather-icons-react";
import {Button, PageHeader, Row, Input, Space, Table, Typography, Tag, Spin} from "antd";
import {Alert} from "../../../utils/alert";
import {Scope} from "../../../services/scopeService";
import Highlighter from 'react-highlight-words';

const ProcedureList = ({showModal, isLoading, procedures, getProcedureInfo, deleteProcedure}) => {

    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //

    const columns = [
        {
            title: 'Surgery',
            ...getColumnSearchProps('surgery'),
            key: 'surgery',
            width:'10%',
            render: (procedure) => (
                <span className="antd-table-custom-class" ellipsis={{ rows: 2, expandable: true }}>
                    {procedure.surgery}
                </span>
            ),
        },
        {
            title: 'Case Time Hours',
            ...getColumnSearchProps("caseTimeHours"),
            width:'7%',
            render: (procedure) => (
                <span className="pl-5">
                    {`${procedure?.caseTimeHours ? procedure.caseTimeHours : ""} ${procedure.caseTimeHours === 1 || procedure.caseTimeHours < 1 ? "hr" : "hrs"}`}
                </span>
            ),
        },
        {
            title: 'In/Out',
            dataIndex: 'inOut',
            key: 'inOut',
            width:'5%',
            ...getColumnSearchProps('inOut'),
        },
        {
            title: 'Patient Packets',
            ...getColumnSearchProps('patientPackets'),
            key: 'patientPackets',
            width:'6%',
            render: (procedure) => (
                <span className="antd-table-custom-class" ellipsis={{ rows: 2, expandable: true }}>
                    {procedure.patientPackets}
                </span>
            ),
        },
        {
            title: 'Diagnosis',
            dataIndex: 'diagnosis',
            key: 'diagnosis',
            width:'7%',
        },
        {
            title: 'Action',
            dataIndex: '_id',
            width:'5%',
            key: '_id',
            render: (key) => <>
                {Scope.checkScopes(['records_procedures_update']) && (
                    <FontAwesome
                        onClick={() => getProcedureInfo(key)}
                        name={"edit"}
                        className="color-info"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['records_procedures_delete']) && (
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deleteProcedure(key)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
            </>
        },
    ];

    const headerButtons = [
        <div key="1" className="page-header-action">
            {Scope.checkScopes(['records_procedures_create']) && (
                <Button size="small" type="primary" onClick={() => showModal('primary')}>
                    <Row>
                        <FeatherIcon icon="plus" size={14}/>
                        Add New
                    </Row>
                </Button>
            )}
        </div>
    ]

    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Procedures"
                subTitle={`List of all ${procedures?.length} procedures.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                <Table
                    pagination={{pageSize: 50, showSizeChanger: false}}
                    rowKey="_id"
                    bordered={true}
                    loading={!procedures?.length ? <Spin  className="example"/> : ""}
                    dataSource={procedures}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
}

export default ProcedureList;
