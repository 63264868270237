import React from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Modal, Select} from "antd";
import {Constants} from "../../../config/constants";

const RepresentativeForm = ({
                                form,
                                state,
                                getUtilityProcedureInfo,
                                procedures,
                                supportive,
                                addRepresentative,
                                updateRepresentative,
                                isLoading,
                                handleOk
                            }) => {
    const dispatch = useDispatch()
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}}

    const validateMessages = {
        required: '${label} is required!',
    };

    const handleSubmit = async (data) => {
        if (supportive?._id) {
            Object.assign(data, {_id: supportive._id});
            await dispatch(updateRepresentative(data, handleOk, form.setFields))
        } else
            await dispatch(addRepresentative(data, handleOk, form.setFields));
    };

    const getSupportiveArea = async (data) => {
        form.setFieldsValue({procedureFieldName: []})
        await dispatch(getUtilityProcedureInfo({supportiveType: data}))
    }

    const footerButtons = [
        <Button
            form="myForm"
            key="submit"
            htmlType="submit"
            type="primary"
            disabled={isLoading}
            size="small">
            {isLoading ? 'Loading...' : 'Submit'}
        </Button>
    ];

    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Representative Form"
                visible={state.visible}
                onCancel={() => handleOk()}
                footer={footerButtons}
                width={800}
            >
                <Form
                    {...layout}
                    name={'RepresentativeForm'}
                    form={form}
                    id={'myForm'}
                    validateMessages={validateMessages}
                    onFinish={handleSubmit}>
                    <Col md={22}>
                        <br/>
                        <Form.Item
                            name="supportType"
                            rules={[{required: true}]}
                            initialValue={supportive?.supportType ? supportive.supportType : null}
                            label="Support Type"
                        >
                            <Select>
                                {Constants.SUPPORT_TYPE.map((code, ci) => (
                                    <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="supportingArea"
                            rules={[{required: true}]}
                            initialValue={supportive?.supportingArea ? supportive.supportingArea : null}
                            label="Supporting Area"
                        >
                            <Select onSelect={getSupportiveArea}>
                                {Constants.PROCEDURE_STATUS.map((code, ci) => (
                                    <Select.Option key={ci} value={code.value}>{code.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/*<Form.Item*/}
                        {/*    name="procedureFieldName"*/}
                        {/*    rules={[{required: true}]}*/}
                        {/*    initialValue={supportive?.procedureFieldName ? supportive.procedureFieldName : []}*/}
                        {/*    label="Surgery Name"*/}
                        {/*>*/}
                        {/*    <Select mode="multiple">*/}
                        {/*        {procedures?.map((procedure) => (*/}
                        {/*            <Select.Option key={procedure._id} value={procedure._id}>{procedure.surgery}</Select.Option>*/}
                        {/*        ))}*/}
                        {/*    </Select>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            name="repFirstName"
                            initialValue={supportive?.repFirstName ? supportive.repFirstName : null}
                            rules={[{required: true}]}
                            label="First Name">
                            <Input type="text" placeholder="First Name"/>
                        </Form.Item>
                        <Form.Item
                            name="repLastName"
                            initialValue={supportive?.repLastName ? supportive.repLastName : null}
                            rules={[{required: true}]}
                            label="Last Name">
                            <Input type="text" placeholder="Last Name"/>
                        </Form.Item>
                        <Form.Item
                            name="repEmail"
                            initialValue={supportive?.repEmail ? supportive.repEmail : null}
                            rules={[{required: true}]}
                            label="Email">
                            <Input type="email" placeholder="Email"/>
                        </Form.Item>
                        <Form.Item
                            name="repCellPhone"
                            initialValue={supportive?.repCellPhone ? supportive.repCellPhone : null}
                            rules={[{required: true}]}
                            label="Cell Phone">
                            <Input type="text" placeholder="Cell Phone"/>
                        </Form.Item>
                        <Form.Item
                            name="repCellServiceProvider"
                            initialValue={supportive?.repCellServiceProvider ? supportive.repCellServiceProvider : null}
                            rules={[{required: true}]}
                            label="Cell Service Provider">
                            <Input type="text" placeholder="Cell Service Provider"/>
                        </Form.Item>
                        <Form.Item
                            name="repCompany"
                            initialValue={supportive?.repCompany ? supportive.repCompany : null}
                            rules={[{required: true}]}
                            label="Rep Company">
                            <Input type="text" placeholder="Rep Company"/>
                        </Form.Item>
                        <Form.Item
                            name="repNotificationPriority"
                            initialValue={supportive?.repNotificationPriority ? supportive.repNotificationPriority : ''}
                            rules={[{required: true}]}
                            label="Priority">
                            <Select>
                                <Select.Option value="">Select</Select.Option>
                                <Select.Option value="one">1</Select.Option>
                                <Select.Option value="two">2</Select.Option>
                                <Select.Option value="three">3</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="surgeryCenterGroupId"
                            initialValue={supportive?.surgeryCenterGroupId ? supportive.surgeryCenterGroupId : null}
                            rules={[{required: true}]}
                            label="SCG ID">
                            <Input type="text" placeholder="Surgery Center Group ID"/>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            initialValue={supportive?.status ? supportive.status : ''}
                            rules={[{required: true}]}
                            label="Status">
                            <Select>
                                <Select.Option value="">Status</Select.Option>
                                <Select.Option value="active">Active</Select.Option>
                                <Select.Option value="inactive">Inactive</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Form>
            </Modal>

        </Col>

    )
}

export default RepresentativeForm;