const actions = {
    FETCH_SURGEONS: 'FETCH_SURGEONS',
    FETCH_SURGEON: 'FETCH_SURGEON',
    RESET_SURGEON: 'RESET_SURGEON',

    fetchSurgeonsAction: (data) => {
        return {
            type: actions.FETCH_SURGEONS,
            data: data
        };
    },
    resetSurgeonFormAction: () => {
        return {
            type: actions.RESET_SURGEON,
            data: null
        };
    },
    fetchSurgeonAction: (data) => {
        return {
            type: actions.FETCH_SURGEON,
            data: data
        };
    },
}

export default actions;
