import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Modal } from "antd";
import FileBase from "react-file-base64";
import { Alert } from "../../../utils/alert";

const ImageForm = ({
    state,
    postData,
    setPost,
    surgeon,
    uploadImage,
    handleOk,
}) => {
    const dispatch = useDispatch();
    const [fileInputValue, setFileInputValue] = useState("");

    const validateImage = (imageBase64, maxSize) => {
        const match = imageBase64.match(/^data:image\/([a-zA-Z+]+);base64,/);
        const imageType = match ? match[1].toLowerCase() : undefined;

        const allowedImageTypes = ["jpeg", "jpg", "png"];

        // Check if the image type is in the allowed types
        if (!imageType || !allowedImageTypes.includes(imageType)) {
            Alert.warning({
                title: "Invalid image type. Please upload a valid image.",
            });
            return false;
        }

        // Get the base64 data (excluding the data URL prefix)
        const base64Data = imageBase64.replace(
            /^data:image\/[a-zA-Z]+\;base64,/,
            ""
        );

        // Convert base64 to buffer
        const buffer = Buffer.from(base64Data, "base64");

        // Check if the image size exceeds the maximum size
        const imageSize = buffer.length / 1024; // in KB
        if (imageSize > maxSize) {
            Alert.warning({ title: `Image exceeded ${maxSize}KB` });
            return false;
        }

        return true;
    };

    const handleImageUpload = async (image, type) => {
        if (image) {
            if (validateImage(image, 2000)) {
                const payload = {
                    ...postData,
                    [type]: image,
                };

                await dispatch(uploadImage(surgeon._id, payload));

                // Clear file input value
                setFileInputValue("");
            }
        }
    };


    const resetPostData = () => {
        setPost({ selectedFile: "", logo: "", signature: "" });
    };

    useEffect(() => {
        if (!state.visible) {
            resetPostData();
        }
    }, [state.visible]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const imageTypes = ["selectedFile", "logo", "signature"];

        // Iterate through the image types and log information
        for (const type of imageTypes) {
            handleImageUpload(postData[type], type);
        }

        // Additional logic or actions after uploading all three images
        handleOk();

        // Clear all image data in the local state
        setPost({ selectedFile: "", logo: "", signature: "" });
    };


    const footerButtons = [<br />];

    return (
        <Col md={12}>
            <Modal
                type={state.modalType}
                title="Image Form"
                visible={state.visible}
                footer={footerButtons}
                onCancel={() => {
                    resetPostData();
                    handleOk();
                }}
                width={500}
            >
                <form onSubmit={handleSubmit}>
                    <row>
                        Profile :{" "}
                        <small style={{ color: "red" }}>(png, jpg, jpeg)</small>{" "}
                        <small style={{ color: "red" }}>max: 2MB</small>
                        <FileBase
                            type="file"
                            placeholder="Profile"
                            value={fileInputValue}
                            onDone={({ base64 }) => {
                                if (validateImage(base64, 2000)) {
                                    setPost({ ...postData, selectedFile: base64 });
                                }
                            }}
                            onChange={(event) => setFileInputValue(event.target.value)}
                        />
                        <br />
                        <br />
                        Logo :{" "}
                        <small style={{ color: "red" }}>(png, jpg, jpeg)</small>{" "}
                        <small style={{ color: "red" }}>max: 2MB</small>
                        <FileBase
                            type="logo"
                            multiple={false}
                            placeholder="Logo"
                            value={fileInputValue}
                            onDone={({ base64 }) => {
                                if (validateImage(base64, 2000)) {
                                    setPost({ ...postData, logo: base64 });
                                }
                            }}
                            onChange={(event) => setFileInputValue(event.target.value)}
                        />
                        <br />
                        <br />
                        Signature :{" "}
                        <small style={{ color: "red" }}>(png, jpg, jpeg)</small>{" "}
                        <small style={{ color: "red" }}>max: 2MB</small>
                        <FileBase
                            type="signature"
                            multiple={false}
                            placeholder="Signature"
                            value={fileInputValue}
                            onDone={({ base64 }) => {
                                if (validateImage(base64, 2000)) {
                                    setPost({ ...postData, signature: base64 });
                                }
                            }}
                            onChange={(event) => setFileInputValue(event.target.value)}
                        />
                        {postData.selectedFile !== "" ||
                        postData.logo !== "" ||
                        postData.signature !== "" ? (
                            <button
                                style={{ marginTop: 10 }}
                                className="btn btn-outline-secondary btn-sm float-right"
                                type="submit"
                            >
                                Upload
                            </button>
                        ) : (
                            ""
                        )}
                    </row>
                </form>
            </Modal>
        </Col>
    );
};

export default ImageForm;
