import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../../components/layout/Section";
import SurgeonForm from "./form";
import SurgeonList from "./list";
import {Form} from "antd";
import {
    addSurgeon,
    deleteSurgeon,
    fetchSurgeons,
    fetchSurgeon,
    updateSurgeon,
    resetSurgeonForm
} from "../../../redux/patient-management/surgeons/actionCreator";
import {fetchUsersAsSurgeons, getEmailInfo} from "../../../redux/userManagement/users/actionCreator";

const SurgeonData = () => {
    const usersAsSurgeons = useSelector(state => state.umUsers.users_surgeons);
    const surgeons = useSelector(state => state.pmSurgeons.surgeons);
    const surgeon = useSelector(state => state.pmSurgeons.surgeon);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });
    const [coState, setCoState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchSurgeons());
            await dispatch(fetchUsersAsSurgeons())
        };
        fetchData().then(r => {
        });
    }, []);

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };

    const showCoModalEdit = async () => {
        await setCoState({
            ...coState,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };

    const getSurgeonInfo = (_id) => dispatch(fetchSurgeon(_id, showModalEdit));
    const getCoSurgeonInfo = (_id) => dispatch(fetchSurgeon(_id, showCoModalEdit));

    const showModal = async type => {
        dispatch(resetSurgeonForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showCoModal = async type => {
        dispatch(resetSurgeonForm());
        await form.resetFields();
        await setCoState({
            ...coState,
            visible: true,
            modalType: type,
        });
    };


    const handleOk = async () => {
        await dispatch(resetSurgeonForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };

    const coHandleOk = async () => {
        await dispatch(resetSurgeonForm());
        setCoState({
            ...coState,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };

    return (
        <Section>
            <SurgeonList
                state={state}
                setState={setState}
                showModal={showModal}
                showCoModal={showCoModal}
                isLoading={'isLoading'}
                surgeons={surgeons}
                getSurgeonInfo={getSurgeonInfo}
                getCoSurgeonInfo={getCoSurgeonInfo}
                deleteSurgeon={deleteSurgeon}
            />
            <SurgeonForm
                state={state}
                coState={coState}
                form={form}
                usersAsSurgeons={usersAsSurgeons}
                surgeon={surgeon}
                surgeons={surgeons}
                getEmailInfo={getEmailInfo}
                addSurgeon={addSurgeon}
                updateSurgeon={updateSurgeon}
                handleOk={handleOk}
                coHandleOk={coHandleOk}
            />
        </Section>
    )
}

export default SurgeonData;