import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import FontAwesome from "react-fontawesome";
import FeatherIcon from "feather-icons-react";
import {Button, PageHeader, Tag, Row, Input, Space, Table, Spin} from "antd";
import {Alert} from "../../../utils/alert";
import {Scope} from "../../../services/scopeService";
import Highlighter from "react-highlight-words";

const RepresentativeList = ({showModal, isLoading, representatives,  facilities, getRepresentativeInfo, deleteRepresentative}) => {
    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //
    const columns = [
        {
            title: 'Personal Info',
            ...getColumnSearchProps('repFirstName'),
            render: (representative) => (
                <Row>
                    {representative?.repFirstName || representative?.repFirstName ? representative.repFirstName :" "}
                    {` ${representative?.repLastName ? representative.repLastName : ""}`}
                </Row>
            )
        },
        {
            title: 'Email',
            dataIndex: 'repEmail',
            key: 'repEmail',
            ...getColumnSearchProps('repEmail'),
        },
        {
            title: 'repCellPhone',
            dataIndex: 'repCellPhone',
            key: 'repCellPhone',
            ...getColumnSearchProps('repCellPhone'),
        },
        {
            title: 'Supportive Area',
            dataIndex: 'supportingArea',
            key: 'supportingArea',
            ...getColumnSearchProps('supportingArea'),
        },
        {
            title: 'Priority',
            ...getColumnSearchProps('repNotificationPriority'),
            render: (representative) => (
                <Row>
                    {
                        representative?.repNotificationPriority === "one" ?  (
                            <Tag color="green">
                                {representative?.repNotificationPriority}
                            </Tag>
                        ) : (
                            <Tag color="">
                                {representative?.repNotificationPriority}
                            </Tag>
                        )
                    }
                </Row>
            )
        },
        {
            title: 'Status',
            ...getColumnSearchProps('status'),
            render: (representative) => (
                <Row>
                    {
                        representative?.status === "active" ?  (
                            <Tag color="blue">
                                {representative?.status}
                            </Tag>
                        ) : (
                            <Tag color="red">
                                {representative?.status}
                            </Tag>
                        )
                    }
                </Row>
            )
        },

        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (key) => <>
                {Scope.checkScopes(['records_representatives_update']) && (
                    <FontAwesome
                        onClick={() => getRepresentativeInfo(key)}
                        name={"edit"}
                        className="color-info"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['records_representatives_delete']) && (
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deleteRepresentative(key)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
            </>
        },
    ];

    const headerButtons = [
        <div key="1" className="page-header-actions">
            {Scope.checkScopes(['records_representatives_create']) && (
            <Button size="small" type="primary" onClick={() => showModal('primary')}>
                <Row>
                    <FeatherIcon icon="plus" size={14}/>
                    Add New
                </Row>
            </Button>
            )}
        </div>,
    ];
    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Representatives"
                subTitle={`List of ${representatives.length} representatives.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                <Table
                    pagination={{pageSize: 50, showSizeChanger: false}}
                    rowKey="_id"
                    bordered={false}
                    className="table-responsive"
                    loading={!representatives?.length ? <Spin  className="example"/> : ""}
                    dataSource={representatives? representatives : ""}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
}

export default RepresentativeList;