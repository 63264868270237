import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Section from "../../components/layout/Section";
import ServiceForm from "./form";
import ServiceList from "./list";
import {Form} from "antd";
import {
    addService,
    deleteService,
    fetchServices,
    fetchService,
    updateService,
    resetServiceForm
} from "../../redux/service/actionCreator";
import ViewData from "./view";

const ServiceData = () => {
    const services = useSelector(state => state.pmServices.services);
    const service = useSelector(state => state.pmServices.service);
    const [form] = Form.useForm();
    const [viewForm] = Form.useForm();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    const [viewState, setViewState] = useState({
        visible: false,
    })

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(fetchServices());
        };
        fetchData().then(r => {
        });
    }, []);

    const getServiceInfo = (_id) => dispatch(fetchService(_id, showModalEdit));
    const viewServiceInfo = (_id) => dispatch(fetchService(_id, showModalView));

    const showModal = async type => {
        dispatch(resetServiceForm());
        await form.resetFields();
        await setState({
            ...state,
            visible: true,
            modalType: type,
        });
    };

    const showModalView = async () => {
        await setViewState({
            ...viewState,
            visible: true,
        });
        await form.resetFields();
    }

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };


    const handleOk = async () => {
        await dispatch(resetServiceForm());
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
        form.resetFields()
    };

    const viewHandleOk = async () => {
        await dispatch(resetServiceForm());
        setViewState({
            ...viewState,
            visible: false,
        });
        form.resetFields()
    };
    return (
        <Section>
            <ServiceList
                state={state}
                setState={setState}
                showModal={showModal}
                viewServiceInfo={viewServiceInfo}
                isLoading={'isLoading'}
                services={services}
                getServiceInfo={getServiceInfo}
                deleteService={(deleteService)}
            />
            <ServiceForm
                state={state}
                form={form}
                service={service}
                addService={addService}
                updateService={updateService}
                handleOk={handleOk}
            />
            <ViewData
                service={service}
                viewState={viewState}
                viewHandleOk={viewHandleOk}
            />
        </Section>
    )
}

export default ServiceData;