import React, {Fragment} from "react";
import {useDispatch} from "react-redux";
import {Button, Col, Form, Input, Modal, Row, Select, Tabs, Timeline} from "antd";
import moment from "moment";
import {resetZipCodesForm} from "../../../redux/patient-management/patients/actionCreator";
import {Alert} from "../../../utils/alert";

const PatientForm = (
    {
        myForm,
        state,
        states,
        getPatient,
        getChartIdInfo,
        cities,
        zipCodes,
        insuredCities,
        insuredZipCodes,
        patient,
        activeTab,
        changeTab,
        setActiveTab,
        isLoading,
        addPatient,
        updatePatient,
        getStateInfo,
        checkPatient,
        checkPatientInfo,
        getCityInfoWithZipCodes,
        getCityInfo,
        getInsuredStateInfo,
        getInsuredCityInfoWithZipCodes,
        getInsuredCityInfo,
        handleOk,
        nextHandleOk,
    }
) => {
    const dispatch = useDispatch();
    const layout = {labelCol: {span: 8}, wrapperCol: {span: 16}};

    const [form] = Form.useForm();
    const patientForm = myForm ? myForm : form;
    const {TabPane} = Tabs;
    const {Option} = Select;
    const validateMessages = {
        required: '${label} is required!',
    };

    const handleSubmit = async (data) => {
        const setDate = new Date(data.dob)
        const setInsuredDate = new Date(data.insuredDateOfBirth)
        const setSecondaryDate = new Date(data.secondaryInsuredDateOfBirth)
        const addADay = setDate.getTime() + 24 * 60 * 60 * 1000
        const addInsuredADay = setInsuredDate.getTime() + 24 * 60 * 60 * 1000
        const addSecondaryInsuredADay = setSecondaryDate.getTime() + 24 * 60 * 60 * 1000
        const d = new Date();
        let diff = d.getTimezoneOffset();
        let dayAdd = null ;
        let insuredDayAdd = null;
        let secondaryInsuredDayAdd = null;
        diff > 0 ? dayAdd = addADay : dayAdd = data.dob
        diff > 0 ? insuredDayAdd =  addInsuredADay : insuredDayAdd = data.insuredDateOfBirth
        diff > 0 ? secondaryInsuredDayAdd = addSecondaryInsuredADay : secondaryInsuredDayAdd = data.secondaryInsuredDateOfBirth

        const filtered = data?.zip?.length ? data?.zip.filter(n => n) : null;
        const inArray =[]
        inArray.push(filtered  !== null ? filtered[0] : null)

        const insuredFiltered = data?.insuredZipCode?.length ? data?.insuredZipCode.filter(n => n) : null;
        const insuredInArray =[]
        insuredInArray.push(insuredFiltered  !== null ? insuredFiltered[0] : null)
        Object.assign(data, {insuredZipCode: insuredInArray});
        if (patient?._id) {
            Object.assign(data, {zip: inArray, dob: dayAdd, patientDOB: data.dob, insuredDateOfBirth: insuredDayAdd, secondaryInsuredDateOfBirth: secondaryInsuredDayAdd});
            Object.assign(data, {_id: patient._id});
            await dispatch(updatePatient({...data, stateId: data.cityId}, handleOk, myForm.setFields, async (event) => {
                await patientForm.setFields(event)
                setActiveTab("1");
            }))
            await patientForm.resetFields();
            setActiveTab("1");
        } else {
            Object.assign(data, {zip: inArray, dob: dayAdd, patientDOB: data.dob, insuredDateOfBirth: insuredDayAdd, secondaryInsuredDateOfBirth: secondaryInsuredDayAdd});
            await dispatch(addPatient({...data, stateId: data.cityId}, handleOk, myForm.setFields, async (event) => {
                await patientForm.setFields(event)
                setActiveTab("1");
            }));
        }
    };

    const checkChartId = async  () => {
        const chartId = myForm.getFieldsValue().chartId ?  myForm.getFieldsValue().chartId : null
        if (patient?._id) {
            await dispatch(getChartIdInfo( {chartId: chartId ? chartId : null, _id: patient?._id}))
        } else {
            await dispatch(getChartIdInfo( {chartId: chartId ? chartId : null, _id: null}))
        }
    }

    const onStateChange = async (_id) => {
        if (_id) {
            await dispatch(getStateInfo(_id))
            patientForm.setFieldsValue({cityId: ""});
            patientForm.setFieldsValue({zip: ""})
        }
    }

    const onCityChange = async (_id) => {
        if (_id) {
            await dispatch(getCityInfo(_id, (data) => {
                if (data?.zip) {
                    patientForm.setFieldsValue({zip: data.zip[0]})
                } else {
                    patientForm.setFieldsValue({zip: ""})
                    dispatch(getCityInfoWithZipCodes(_id))
                }
            }))
        }
    }

    const onInsuredStateChange = async (_id) => {
        if (_id) {
            await dispatch(getInsuredStateInfo(_id))
            patientForm.setFieldsValue({insuredCity: ""});
            patientForm.setFieldsValue({insuredZipCode: ""})
        }
    }

    const onInsuredCityChange = async (_id) => {
        dispatch(resetZipCodesForm());
        if (_id) {
            await dispatch(getInsuredCityInfo(_id, (data) => {
                if (data?.zip) {
                    patientForm.setFieldsValue({insuredZipCode: data.zip[0]})
                } else {
                    patientForm.setFieldsValue({insuredZipCode: ""})
                    dispatch(getInsuredCityInfoWithZipCodes(_id))
                }
            }))
        }
    }
    const getValidated = async (event) => {
        if (!myForm.getFieldsValue().firstName) Alert.warning({title: "Please insert Firstname!"})
        if (!myForm.getFieldsValue().lastName) Alert.warning({title: "Please insert Lastname!"})
        if (!myForm.getFieldsValue().dob) Alert.warning({title: "Please insert Date of Birth!"})
        if (!myForm.getFieldsValue().phone) Alert.warning({title: "Please insert Phone!"})
        if (
            !patient?._id &&
            myForm.getFieldsValue().firstName &&
            myForm.getFieldsValue().lastName &&
            myForm.getFieldsValue().dob &&
            myForm.getFieldsValue().phone) {
            const data = {
                firstName: myForm.getFieldsValue().firstName,
                lastName: myForm.getFieldsValue().lastName,
                dob: myForm.getFieldsValue().dob,
                phone: myForm.getFieldsValue().phone,
            }
            await dispatch(checkPatientInfo(data));
        }
    }

    const footerButtons = [
        activeTab === "1" || activeTab === "2" || activeTab === "3" ? (
            <Button
                form="patientForm"
                key="submit"
                htmlType="submit"
                type="primary"
                disabled={isLoading}
                size="small">
                {isLoading ? 'Loading...' : 'Submit'}
            </Button>
        ) : null
    ];
    const screenWidth = window.innerWidth;
    const patientInformation = [
        <Fragment>
            <Col md={screenWidth < 1500 ? 20 : 11}>
                <Form.Item
                    name="firstName"
                    rules={[{required: true}]}
                    initialValue={patient?.firstName ? patient.firstName : ''}
                    label="First Name"
                >
                    <Input placeholder="First Name"/>
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[{required: true}]}
                    initialValue={patient?.lastName ? patient.lastName : null}
                    label="Last Name"
                >
                    <Input placeholder="Last Name"/>
                </Form.Item>
                <Form.Item
                    name="dob"
                    initialValue={patient?.dob ? moment(patient.dob).format('YYYY-MM-DD') : ""}
                    rules={[{required: true}]}
                    label="Date of Birth"
                >
                    <Input type="date"/>
                </Form.Item>
                <Form.Item
                    name="phone"
                    initialValue={patient?.phone ? patient.phone : ""}
                    rules={[{required: true}]}
                    label={
                        <>
                            <label>Phone</label>
                        </>
                    }>
                    <Input
                        placeholder="Phone"
                    />
                </Form.Item>
                <Form.Item
                    name="gender"
                    rules={[{required: true}]}
                    initialValue={patient?.gender ? patient.gender : null}
                    label="Gender"
                >
                    <Select>
                        <Select.Option value={null}>Select</Select.Option>
                        <Select.Option value="male">Male</Select.Option>
                        <Select.Option value="female">Female</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="chartId"
                    initialValue={patient?.chartId ? patient.chartId : ""}
                    rules={[{required: false}]}
                    label={"Chart ID"}
                >
                    <Input
                        onBlur={checkChartId}
                        placeholder="Chart ID"
                    />
                </Form.Item>
                <Form.Item
                    name="email"
                    initialValue={patient?.email ? patient.email : ""}
                    rules={[{required: false}]}
                    label="Email"
                >
                    <Input
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item
                    name="address"
                    rules={[{required: false}]}
                    initialValue={patient?.address ? patient.address : ''}
                    label="Address">
                    <Input placeholder="Address"/>
                </Form.Item>
                <Form.Item
                    name="stateId"
                    initialValue={patient?.city?.state?._id ? patient.city.state._id : ""}
                    rules={[{required: false}]}
                    label="State"
                >
                    <Select
                        showSearch
                        onChange={onStateChange}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                        {states?.map((state, ci) => (
                            <Select.Option key={ci} value={state._id}>{state.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="cityId"
                    initialValue={patient?.city?._id ? patient.city._id : ""}
                    rules={[{required: false}]}
                    label="City"
                >
                    <Select
                        showSearch
                        onChange={onCityChange}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    >
                        {cities?.map((city, ci) => (
                            <Select.Option key={ci} value={city._id}>{city.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="zip"
                    initialValue={patient?.zip?.length  && patient?.zip?.[0] !== null ? patient?.zip : patient?.city?.zip}
                    // initialValue={patient?.zip ? patient.zip : ""}
                    rules={[{required: false}]}
                    label="Zip"
                >
                    <Select mode="tags">
                        {zipCodes.zip?.map((zi, ci) => (
                            <Select.Option key={ci} value={zi}>{zi}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="emergencyContactName"
                    rules={[{required: false}]}
                    initialValue={patient?.emergencyContactName ? patient.emergencyContactName : ''}
                    label="Emerg Contact Name">
                    <Input placeholder="Emergency Contact"/>
                </Form.Item>
                <Form.Item
                    name="emergencyContactPhone"
                    rules={[{required: false}]}
                    initialValue={patient?.emergencyContactPhone ? patient.emergencyContactPhone : ''}
                    label="Emerg Contact Phone">
                    <Input placeholder="Emergency Contact Phone"/>
                </Form.Item>
                <Form.Item
                    name="ethnicity"
                    rules={[{required: false}]}
                    initialValue={patient?.ethnicity ? patient.ethnicity : ""}
                    label="Ethnicity"
                >
                    <Select>
                        <Select.Option value="">Select</Select.Option>
                        <Select.Option value="ethnicity-1">ethnicity-1</Select.Option>
                        <Select.Option value="ethnicity-2">ethnicity-2</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="referredBy"
                    rules={[{required: false}]}
                    initialValue={patient?.referredBy ? patient.referredBy : ''}
                    label="Referred By">
                    <Input placeholder="Referred By"/>
                </Form.Item>
                <Form.Item
                    name="contactPreferences"
                    rules={[{required: false}]}
                    initialValue={patient?.contactPreferences ? patient.contactPreferences : ""}
                    label="Contact Preferences"
                >
                    <Select>
                        <Select.Option value="">Select</Select.Option>
                        <Select.Option value="postalEmail">Postal Mail</Select.Option>
                        <Select.Option value="overPhone">Over Phone</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="patientMaritalStatus"
                    rules={[{required: false}]}
                    initialValue={patient?.patientMaritalStatus ? patient.patientMaritalStatus : ""}
                    label="Marital Status"
                >
                    <Select>
                        <Select.Option value="">Select</Select.Option>
                        <Select.Option value="unmarried">unmarried</Select.Option>
                        <Select.Option value="married">married</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="patientEmploymentStatus"
                    rules={[{required: false}]}
                    initialValue={!!patient?.patientEmploymentStatus}
                    label="Employment Status"
                >
                    <Select>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="patientJobTitle"
                    rules={[{required: false}]}
                    initialValue={patient?.patientJobTitle ? patient.patientJobTitle : ''}
                    label="Job Title">
                    <Input placeholder="Job Title"/>
                </Form.Item>
                <Form.Item
                    name="patientEmployer"
                    rules={[{required: false}]}
                    initialValue={patient?.patientEmployer ? patient.patientEmployer : ''}
                    label="Patient Employer">
                    <Input placeholder="Patient Employer Name"/>
                </Form.Item>
            </Col>
            <Col md={screenWidth < 1500 ? 20 : 11}>
                {/*<Form.Item*/}
                {/*    name="primaryInsurancePlanName"*/}
                {/*    initialValue={patient?.lastInsurance?.primaryInsurancePlanName ? patient.lastInsurance.primaryInsurancePlanName : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Primary Ins. Plan Name">*/}
                {/*    <Input placeholder="Primary Insurance Plan Name"/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="insuredProvider"
                    rules={[{required: false}]}
                    initialValue={patient?.lastInsurance?.insuredProvider ? patient.lastInsurance.insuredProvider : ""}
                    label="Insurance Provider">
                    <Input placeholder="Insurance Provider"/>
                </Form.Item>
                <Form.Item
                    name="insuredName"
                    rules={[{required: false}]}
                    initialValue={patient?.lastInsurance?.insuredName ? patient.lastInsurance.insuredName : ""}
                    label="Insured Name">
                    <Input placeholder="Insured Name"/>
                </Form.Item>
                <Form.Item
                    name="insuredPolicyNumber"
                    rules={[{required: false}]}
                    initialValue={patient?.lastInsurance?.insuredPolicyNumber ? patient.lastInsurance.insuredPolicyNumber : ""}
                    label="Insured Policy Number">
                    <Input placeholder="Insured Policy"/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="insuredEmployer"*/}
                {/*    rules={[{required: false}]}*/}
                {/*    initialValue={patient?.lastInsurance?.insuredEmployer ? patient.lastInsurance.insuredEmployer : ""}*/}
                {/*    label="Insured Employer">*/}
                {/*    <Input placeholder="Insured Employer"/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="insuredPatientRelationship"
                    rules={[{required: false}]}
                    initialValue={patient?.lastInsurance?.insuredPatientRelationship ? patient.lastInsurance.insuredPatientRelationship : ""}
                    label="Relationship">
                    <Input placeholder="Relationship"/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="insuredStreetAddress"*/}
                {/*    rules={[{required: false}]}*/}
                {/*    initialValue={patient?.lastInsurance?.insuredStreetAddress ? patient.lastInsurance.insuredStreetAddress : ""}*/}
                {/*    label="Street Address">*/}
                {/*    <Input placeholder="Insured Street Address"/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="insuredDateOfBirth"
                    initialValue={patient?.lastInsurance?.insuredDateOfBirth ? moment(patient.lastInsurance.insuredDateOfBirth).format('YYYY-MM-DD') : ""}
                    rules={[{required: false}]}
                    label="Insured Date of Birth"
                >
                    <Input type="date"/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="insuredGender"*/}
                {/*    rules={[{required: false}]}*/}
                {/*    initialValue={patient?.lastInsurance?.insuredGender ? patient.lastInsurance.insuredGender : null}*/}
                {/*    label="Insured Gender"*/}
                {/*>*/}
                {/*    <Select>*/}
                {/*        <Select.Option value={null}>Select</Select.Option>*/}
                {/*        <Select.Option value="male">Male</Select.Option>*/}
                {/*        <Select.Option value="female">Female</Select.Option>*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    name="insuredPhone"*/}
                {/*    initialValue={patient?.lastInsurance?.insuredPhone ? patient.lastInsurance.insuredPhone : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Insured Phone">*/}
                {/*    <Input placeholder="Insured Phone"/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    name="insuredState"*/}
                {/*    initialValue={patient?.insuredCityInfo?.state?._id ? patient.insuredCityInfo.state._id : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Insured State"*/}
                {/*>*/}
                {/*    <Select*/}
                {/*        showSearch*/}
                {/*        onChange={onInsuredStateChange}*/}
                {/*        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                {/*        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}*/}
                {/*    >*/}
                {/*        {states?.map((state, ci) => (*/}
                {/*            <Select.Option key={ci} value={state._id}>{state.name}</Select.Option>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    name="insuredCity"*/}
                {/*    initialValue={patient?.insuredCityInfo?._id ? patient.insuredCityInfo._id : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Insured City"*/}
                {/*>*/}
                {/*    <Select*/}
                {/*        showSearch*/}
                {/*        onChange={onInsuredCityChange}*/}
                {/*        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}*/}
                {/*        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}*/}
                {/*    >*/}
                {/*        {insuredCities?.map((city, ci) => (*/}
                {/*            <Select.Option key={ci} value={city._id}>{city.name}</Select.Option>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    name="insuredZipCode"*/}
                {/*    initialValue={patient?.lastInsurance?.insuredZipCode ? patient.lastInsurance.insuredZipCode : ""}*/}
                {/*    // initialValue={patient?.zip ? patient.zip : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Insured Zip Code"*/}
                {/*>*/}
                {/*    <Select mode="tags">*/}
                {/*        {insuredZipCodes?.zip?.map((zi, ci) => (*/}
                {/*            <Select.Option key={ci} value={zi}>{zi}</Select.Option>*/}
                {/*        ))}*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="secondaryInsuranceProvider"
                    initialValue={patient?.lastInsurance?.secondaryInsuranceProvider ? patient.lastInsurance.secondaryInsuranceProvider : ""}
                    rules={[{required: false}]}
                    label="Sec. Insurance Provider">
                    <Input placeholder="Secondary Insurance Provider"/>
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="secondaryInsurancePlanName"*/}
                {/*    initialValue={patient?.lastInsurance?.secondaryInsurancePlanName ? patient.lastInsurance.secondaryInsurancePlanName : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Secondary Ins. Plan Name">*/}
                {/*    <Input placeholder="Secondary Insurance Plan Name"/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="secondaryInsuredName"
                    initialValue={patient?.lastInsurance?.secondaryInsuredName ? patient.lastInsurance.secondaryInsuredName : ""}
                    rules={[{required: false}]}
                    label="Sec. Insured Name">
                    <Input placeholder="Secondary Insured Name"/>
                </Form.Item>
                <Form.Item
                    name="secondaryInsuredPolicyNumber"
                    initialValue={patient?.lastInsurance?.secondaryInsuredPolicyNumber ? patient.lastInsurance.secondaryInsuredPolicyNumber : ""}
                    rules={[{required: false}]}
                    label="Sec. Insured Policy">
                    <Input placeholder="Secondary Insured Policy Number"/>
                </Form.Item>
                <Form.Item
                    name="secondaryInsuredDateOfBirth"
                    initialValue={patient?.lastInsurance?.secondaryInsuredDateOfBirth ? moment(patient.lastInsurance.secondaryInsuredDateOfBirth).format('YYYY-MM-DD') : ""}
                    rules={[{required: false}]}
                    label="Sec. Insured DOB"
                >
                    <Input
                        type="date"
                        value={patient?.secondaryInsuredDateOfBirth ? moment(patient.secondaryInsuredDateOfBirth).format('MM-DD-YYYY') : ""}
                    />
                </Form.Item>
                {/*<Form.Item*/}
                {/*    name="secondaryInsuredGender"*/}
                {/*    rules={[{required: false}]}*/}
                {/*    initialValue={patient?.lastInsurance?.secondaryInsuredGender ? patient.lastInsurance.secondaryInsuredGender : null}*/}
                {/*    label="Secondary Insured Gender"*/}
                {/*>*/}
                {/*    <Select>*/}
                {/*        <Select.Option value={null}>Select</Select.Option>*/}
                {/*        <Select.Option value="male">Male</Select.Option>*/}
                {/*        <Select.Option value="female">Female</Select.Option>*/}
                {/*    </Select>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item*/}
                {/*    name="secondaryInsuredEmployer"*/}
                {/*    initialValue={patient?.lastInsurance?.secondaryInsuredEmployer ? patient.lastInsurance.secondaryInsuredEmployer : ""}*/}
                {/*    rules={[{required: false}]}*/}
                {/*    label="Secondary Insured Employer">*/}
                {/*    <Input placeholder="Secondary Insured Employer"/>*/}
                {/*</Form.Item>*/}
                <Form.Item
                    name="authorization"
                    rules={[{required: false}]}
                    initialValue={patient?.lastInsurance?.authorization ? patient.lastInsurance.authorization : ""}
                    label="Authorization"
                >
                    <Select>
                        <Select.Option value="">Select authorization</Select.Option>
                        <Select.Option value="pending">Pending</Select.Option>
                        <Select.Option value="notNeeded">Not Needed</Select.Option>
                    </Select>
                </Form.Item>
            </Col>
        </Fragment>

    ]
    return (
        <Fragment>
            <Row md={12}>
                <Modal
                    type={state.modalType}
                    title="Patient Information Form"
                    visible={state.visible}
                    onCancel={() => handleOk()}
                    footer={footerButtons}
                    width={screenWidth < 1500 ? 1000 : 1150}
                >
                    <Form
                        {...layout}
                        name={'demographics'}
                        form={patientForm}
                        id={'patientForm'}
                        validateMessages={validateMessages}
                        onFinish={handleSubmit}>
                        <div key="1" style={{paddingBottom: "8px"}} className="page-header-action">
                        </div>
                        <Tabs activeKey={activeTab} onChange={changeTab} type="card">
                            <TabPane tab="Patient Information" key="1">
                                {
                                    screenWidth < 1500 ? (
                                        <>
                                            {patientInformation}
                                        </>
                                    ) : (
                                        <Row md={24}>
                                            {patientInformation}
                                        </Row>
                                    )
                                }

                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </Row>
            <Row md={12}>
                <Modal
                    type={getPatient.modalType}
                    title="View Patient"
                    visible={getPatient.visible}
                    onCancel={() => nextHandleOk()}
                    footer={footerButtons}
                    width={800}
                >
                    <Form
                        {...layout}
                        name={'View'}
                        form={form}
                        id={'myForm'}
                        validateMessages={validateMessages}
                        onFinish={handleSubmit}>
                        <Tabs style={{paddingLeft: "8px", paddingRight: "8px", paddingBottom: "8px", paddingTop: "5px"}}
                              activeKey={activeTab} onChange={changeTab} type="card">
                            <TabPane tab="Patient Information" key="4">
                                <Row>
                                    <Col md={10}>
                                        <Timeline>
                                            <Timeline.Item><b>{`Patient Name: `}</b>
                                                {` ${patient?.firstName ? patient.firstName : ""}`}
                                                {` ${patient?.lastName ? patient.lastName : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Chart ID: `}</b>
                                                {` ${patient?.chartId ? patient.chartId : "Not Available"}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Phone: `}</b>
                                                {` ${patient?.phone ? patient.phone : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Email: `}</b>
                                                {` ${patient?.email ? patient.email : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Gender: `}</b>
                                                {` ${patient?.gender ? patient.gender : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Date of Birth: `}</b>
                                                {` ${patient?.dob ? moment(patient.dob).format('MM-DD-YYYY') : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Referred By: `}</b>
                                                {` ${patient?.referredBy ? patient.referredBy : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Ethnicity: `}</b>
                                                {` ${patient?.ethnicity ? patient.ethnicity : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Address: `}</b>
                                                {` ${patient?.address ? patient.address : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`State: `}</b>
                                                {` ${patient?.city?.state?.name ? patient.city.state.name : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`City: `}</b>
                                                {` ${patient?.city?.name ? patient.city.name : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Zip: `}</b>
                                                {` ${patient?.zip?.length  && patient?.zip?.[0] !== null ? patient?.zip : patient?.city?.zip ? patient?.city?.zip : ""}`}
                                            </Timeline.Item>
                                        </Timeline>
                                    </Col>
                                    <Col md={10}>
                                        <Timeline>
                                            <Timeline.Item><b>{`Contact Preferences: `}</b>
                                                {` ${patient?.contactPreferences ? patient.contactPreferences : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Emergency Contact Name: `}</b>
                                                {` ${patient?.emergencyContactName ? patient.emergencyContactName : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Emergency Contact Phone: `}</b>
                                                {` ${patient?.emergencyContactPhone ? patient.emergencyContactPhone : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Employment Status: `}</b>
                                                {` ${patient?.patientEmploymentStatus ? "Yes" : "No"}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Patient Employer: `}</b>
                                                {` ${patient?.patientEmployer ? patient.patientEmployer : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Job Title: `}</b>
                                                {` ${patient?.patientJobTitle ? patient.patientJobTitle : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Marital Status: `}</b>
                                                {` ${patient?.patientMaritalStatus ? patient.patientMaritalStatus : ""}`}
                                            </Timeline.Item>
                                        </Timeline>
                                    </Col>
                                </Row>
                                <div style={{float: 'right'}}>
                                    <Button onClick={() => changeTab("5")}>Next</Button>
                                </div>
                            </TabPane>
                            <TabPane tab="Insurance Information" key="5">
                                <Row>
                                    <Col md={10}>
                                        <Timeline>
                                            <Timeline.Item><b>{`Insured Name: `}</b>
                                                {` ${patient?.lastInsurance?.insuredName ? patient.lastInsurance.insuredName : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Insured Provider: `}</b>
                                                {` ${patient?.lastInsurance?.insuredProvider ? patient.lastInsurance.insuredProvider : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Phone: `}</b>
                                                {` ${patient?.lastInsurance?.insuredPhone ? patient.lastInsurance.insuredPhone : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Date of Birth: `}</b>
                                                {` ${patient?.lastInsurance?.insuredDateOfBirth ? moment(patient.lastInsurance.insuredDateOfBirth).format('MM-DD-YYYY') : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Employer: `}</b>
                                                {` ${patient?.lastInsurance?.insuredEmployer ? patient.lastInsurance.insuredEmployer : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Gender: `}</b>
                                                {` ${patient?.lastInsurance?.insuredGender ? patient.lastInsurance.insuredGender : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Policy Number: `}</b>
                                                {` ${patient?.lastInsurance?.insuredPolicyNumber ? patient.lastInsurance.insuredPolicyNumber : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Relationship with patient: `}</b>
                                                {` ${patient?.lastInsurance?.insuredPatientRelationship ? patient.lastInsurance.insuredPatientRelationship : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Address: `}</b>
                                                {` ${patient?.lastInsurance?.insuredStreetAddress ? patient.lastInsurance.insuredStreetAddress : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`State: `}</b>
                                                {` ${patient?.insuredCityInfo?.state?.name ? patient.insuredCityInfo.state.name : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`City: `}</b>
                                                {` ${patient?.insuredCityInfo?.name ? patient.insuredCityInfo.name : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Zip Code: `}</b>
                                                {` ${patient?.lastInsurance?.insuredZipCode ? patient.lastInsurance.insuredZipCode : ""}`}
                                            </Timeline.Item>
                                        </Timeline>
                                    </Col>
                                    <Col md={10}>
                                        <Timeline>
                                            <Timeline.Item><b>{`secondary Insured Name: `}</b>
                                                {` ${patient?.lastInsurance?.secondaryInsuredName ? patient.lastInsurance.secondaryInsuredName : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Secondary Insurance: `}</b>
                                                {` ${patient?.lastInsurance?.secondaryInsurancePlanName ? patient.lastInsurance.secondaryInsurancePlanName : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Secondary Insured DOB: `}</b>
                                                {` ${patient?.lastInsurance?.secondaryInsuredDateOfBirth ? moment(patient.lastInsurance.secondaryInsuredDateOfBirth).format('MM-DD-YYYY') : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Secondary Insured Employer: `}</b>
                                                {` ${patient?.lastInsurance?.secondaryInsuredEmployer ? patient.lastInsurance.secondaryInsuredEmployer : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Secondary Insured Gender: `}</b>
                                                {` ${patient?.lastInsurance?.secondaryInsuredGender ? patient.lastInsurance.secondaryInsuredGender : ""}`}
                                            </Timeline.Item>
                                            <Timeline.Item><b>{`Secondary Insured Gender: `}</b>
                                                {` ${patient?.lastInsurance?.authorization ? patient.lastInsurance.authorization : ""}`}
                                            </Timeline.Item>
                                        </Timeline>
                                    </Col>
                                </Row>
                                <div style={{float: 'right'}}>
                                    <Row>
                                        <Button onClick={() => changeTab("4")}>Back</Button>
                                    </Row>
                                </div>
                            </TabPane>
                        </Tabs>
                    </Form>
                </Modal>
            </Row>
        </Fragment>
    )
};

export default PatientForm;
