import React, {Fragment, useRef} from "react";
import Day from "./Day";
import dayjs from "dayjs";
export default function Month({month, getPrint, monthIndex, surgeon, componentRef, viewScheduleInfo, showModal, doctorAvailability, getDoctorAvailabilities, availabilities}) {
    const screenWidth = window.innerWidth;

    return (
        <Fragment>
            {
                !getPrint ? (
                    <div style={{ minWidth: "70px"}} className={`flex-1 grid grid-cols-${screenWidth > 700 ? 7 : 1} grid-rows-0`}>
                        {month.map((row, i) => (
                            <React.Fragment key={i}>
                                {row.map((day, idx) => (
                                    <Day availabilities={availabilities} getDoctorAvailabilities={getDoctorAvailabilities} doctorAvailability={doctorAvailability} showModal={showModal} surgeon={surgeon} viewScheduleInfo={viewScheduleInfo} day={day} key={idx} rowIdx={i}/>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                ) : (
                    <div ref={componentRef}>
                        <h2 className="ml-4 text-xl text-gray-500 font-bold">
                            {dayjs(new Date(dayjs().year(), monthIndex)).format(
                                "MMMM YYYY"
                            )}
                        </h2>
                        <div  className={`flex-1 grid grid-cols-${screenWidth > 700 ? 7 : 1} grid-rows-0`}>
                            {month.map((row, i) => (
                                <React.Fragment key={i}>
                                    {row.map((day, idx) => (
                                        <Day availabilities={availabilities} getDoctorAvailabilities={getDoctorAvailabilities} doctorAvailability={doctorAvailability} showModal={showModal} getThePrint={getPrint} surgeon={surgeon} viewScheduleInfo={viewScheduleInfo} day={day} key={idx} rowIdx={i}/>
                                    ))}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                )
            }
        </Fragment>
    );
}
