import actions from "./actions";

const {
    FETCH_SERVICES,
    FETCH_SERVICE,
    RESET_SERVICE,
} = actions;

const initState = {
    services: [],
    service: {},
};

const ServiceReducer = (state = initState, action) => {
    const {type, data} = action;
    switch (type) {
        case FETCH_SERVICES:
            return {
                ...state,
                services: data
            };
        case RESET_SERVICE:
            return {
                ...state,
                service: initState.service,
            };
        case FETCH_SERVICE:
            return {
                ...state,
                service: data,
            };
        default:
            return state;
    }
}

export default ServiceReducer;
