const actions = {
    FETCH_ACTIVITIES: 'FETCH_ACTIVITIES',
    FETCH_ACTIVITY: 'FETCH_ACTIVITY',
    RESET_ACTIVITY: 'RESET_ACTIVITY',

    fetchActivitiesAction: (data) => {
        return {
            type: actions.FETCH_ACTIVITIES,
            data: data
        };
    },
    fetchActivityAction: (data) => {
        return {
            type: actions.FETCH_ACTIVITY,
            data: data
        };
    },
    resetActivityFormAction: () => {
        return {
            type: actions.RESET_ACTIVITY,
            data: null
        };
    },
   
}

export default actions;
