import { Constants } from "../../../config/constants";
import { Requests as req } from "../../../utils/requests";
import actions from './actions';

const { fetchSurgeonsAction, fetchSurgeonAction, resetSurgeonFormAction } = actions;

export const addSurgeon = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({ url: Constants.SURGEONS, auth: 'bearer', body: data, validate}, async (cb) => {
            await dispatch(fetchSurgeons());
            await dispatch(action);
        })
    };
};

export const fetchSurgeons = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.SURGEONS,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSurgeonsAction(cb)))
    };
};

export const fetchSurgeonsForScheduler = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.SURGEONS_FOR_SCHEDULER,
            auth: 'bearer'
        }, (cb) => dispatch(fetchSurgeonsAction(cb)))
    };
};

export const fetchSurgeonForProfile = (_id) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.SURGEONS_USERS + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchSurgeonAction(cb));
        })
    };
};

export const uploadImage = (id, postData) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.SURGEONS_IMAGE + id, auth: 'bearer', body: {file: postData.selectedFile, logo: postData.logo, signature: postData.signature}},  async (cb) => {
            // window.location.reload();
            await dispatch(resetSurgeonFormAction())
            await dispatch(fetchSurgeonForProfile(id));
        })
    };
};
export const updateSurgeon = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.SURGEONS + data._id, auth: 'bearer', body: data, validate},  async (cb) => {
            dispatch(fetchSurgeons(cb))
            await dispatch(action);
        })
    };
};

export const updateSurgeonProfile = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({ url: Constants.SURGEONS + data._id, auth: 'bearer', body: data, validate},  async (cb) => {
            await dispatch(action);
        })
    };
};

export const resetSurgeonForm = () => dispatch => dispatch(resetSurgeonFormAction());

export const fetchSurgeon = (_id, action) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.SURGEONS + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchSurgeonAction(cb));
            await dispatch(action);
        })
    };
};


export const fetchSurgeonProfile = (_id, action) => {
    return async dispatch => {
        await req.getRequest({ url: Constants.SURGEONS_PROFILE + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchSurgeonAction(cb));
            await dispatch(action);
        })
    };
};

export const deleteSurgeon = (id) => {
    return async dispatch => {
        await req.deleteRequest({ url: Constants.SURGEONS + id, auth: 'bearer'}, (cb) => dispatch(fetchSurgeons()))
    };
};
