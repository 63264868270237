const actions = {
    FETCH_SURGEON_SCHEDULES: 'FETCH_SURGEON_SCHEDULES',
    FETCH_CANCELLED_SURGEON_SCHEDULES: 'FETCH_CANCELLED_SURGEON_SCHEDULES',
    FETCH_All_SURGEON_SCHEDULES: 'FETCH_All_SURGEON_SCHEDULES',
    FETCH_SCHEDULES: 'FETCH_SCHEDULES',
    FETCH_WEEKLY_SCHEDULES: 'FETCH_WEEKLY_SCHEDULES',
    FETCH_WEEKLY_ACTIVE_SCHEDULES: 'FETCH_WEEKLY_ACTIVE_SCHEDULES',
    FETCH_SCHEDULE: 'FETCH_SCHEDULE',
    RESET_SCHEDULE: 'RESET_SCHEDULE',
    RESET_SERVER_SITE_FILTER_SCHEDULE: 'RESET_SERVER_SITE_FILTER__SCHEDULE',
    FETCH_SERVER_SITE_FILTER_SCHEDULES: 'FETCH_SERVER_SITE_FILTER_SCHEDULES',
    FETCH_AVAILABILITIES: 'FETCH_AVAILABILITIES',

    fetchSurgeonSchedulesAction: (data) => {
        return {
            type: actions.FETCH_SURGEON_SCHEDULES,
            data: data
        };
    },

    fetchAvailabilitiesAction: (data) => {
        return {
            type: actions.FETCH_AVAILABILITIES,
            data: data
        };
    },
    fetchSurgeonCancelledScheduleAction: (data) => {
        return {
            type: actions.FETCH_CANCELLED_SURGEON_SCHEDULES,
            data: data
        };
    },
    fetchSurgeonAllScheduleAction: (data) => {
        return {
            type: actions.FETCH_All_SURGEON_SCHEDULES,
            data: data
        };
    },
    fetchSchedulesAction: (data) => {
        return {
            type: actions.FETCH_SCHEDULES,
            data: data
        };
    },
    fetchWeeklySchedulesAction: (data) => {
        return {
            type: actions.FETCH_WEEKLY_SCHEDULES,
            data: data
        };
    },
    fetchWeeklyActiveSchedulesAction: (data) => {
        return {
            type: actions.FETCH_WEEKLY_ACTIVE_SCHEDULES,
            data: data
        };
    },
    fetchServerSiteFilteredSchedulesAction: (data) => {
        return {
            type: actions.FETCH_SERVER_SITE_FILTER_SCHEDULES,
            data: data
        };
    },
    resetScheduleFormAction: () => {
        return {
            type: actions.RESET_SCHEDULE,
            data: null
        };
    },
    resetServerFilteredScheduleFormAction: () => {
        return {
            type: actions.RESET_SERVER_SITE_FILTER_SCHEDULE,
            data: null
        };
    },
    fetchScheduleAction: (data) => {
        return {
            type: actions.FETCH_SCHEDULE,
            data: data
        };
    },
}

export default actions;
