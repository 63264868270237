import {Constants} from '../../../config/constants';
import actions from './actions';
import {Requests as req} from "../../../utils/requests";

const {fetchUsersAction, fetchUsersAsSurgeonsAction, resetUserFormAction, fetchUserAction} = actions;

export const addUser = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({url: Constants.USERS, auth: 'bearer', body: data,  validate: validate}, async (cb) => {
            await dispatch(fetchUsers());
            await dispatch(action);
        })
    };
};

export const updateUser = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.USERS + data._id,
            auth: 'bearer',
            body: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchUsers());
            await dispatch(action);
        })
    };
};
export const updatePass = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.USERS_PASS + data._id,
            auth: 'bearer',
            body: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchUsers());
            await dispatch(action);
        })
    };
};


export const resetUserForm = () => dispatch => dispatch(resetUserFormAction());
export const fetchUser = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.USERS + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchUserAction(cb));
            await dispatch(action);
        })
    };
};

export const getEmailInfo = (_id,validate, action) => {
    return async dispatch => {
        await resetUserForm();
        await req.getRequest({url: Constants.USERS_CHECK + _id, auth: 'bearer', validate: validate}, async (cb) => {
            await dispatch(fetchUserAction(cb));
            await action(cb);
        })
    };
};

export const deleteUser = (id) => {
    return async dispatch => {
        await req.deleteRequest({
            url: Constants.USERS + id,
            auth: 'bearer'
        }, (cb) => dispatch(fetchUsers()))
    };
};

export const fetchUsers = (params, queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.USERS,
            auth: 'bearer',
            queries: queries
        }, async (cb) => {
            await dispatch(fetchUsersAction(cb));
        })
    }
};

export const fetchUsersAsSurgeons = (params, queries) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.USERS_SURGEONS_FOR_PROFILE,
            auth: 'bearer',
            queries: queries
        }, async (cb) => {
            await dispatch(fetchUsersAsSurgeonsAction(cb));
        })
    }
};

// export const changePassword = (data, action) => {
//     return async dispatch => {
//         await req.putRequest({ url: Constants.USERS_PASS, auth: 'bearer', body: data}, async (cb) => {
//             // await dispatch(action)
//         })
//     };
// };
