import {Constants} from "../../../config/constants";
import {Requests as req} from "../../../utils/requests";
import actions from './actions';
import * as ics from "ics";
import moment from "moment";
import {Alert} from "../../../utils/alert";

const {
    fetchSchedulesAction,
    fetchWeeklySchedulesAction,
    fetchSurgeonCancelledScheduleAction,
    fetchSurgeonAllScheduleAction,
    fetchWeeklyActiveSchedulesAction,
    fetchServerSiteFilteredSchedulesAction,
    fetchSurgeonSchedulesAction,
    fetchScheduleAction,
    fetchAvailabilitiesAction,
    resetScheduleFormAction,
    resetServerFilteredScheduleFormAction
} = actions;

export const addSchedule = (data, action, validate) => {
    return async dispatch => {
        await req.postRequest({
            url: Constants.SCHEDULES,
            auth: 'bearer',
            body: data,
            validate: validate
        }, async (cb) => {
            if (cb?._id) {
                const d = new Date();
                let diff = d.getTimezoneOffset();

                const printDate = diff > 0 ?
                    moment(`${cb?.date.substring(0, 10)}`).subtract(1, "days").format('MM-DD-YYYY') :
                    moment(`${cb?.date.substring(0, 10)}`).format('MM-DD-YYYY')

                const start = diff > 0 ?
                    moment(`${cb?.date.substring(0, 10)} ${cb?.startTime}`).subtract(1, "days").format('YYYY-M-D-H-m') :
                    moment(`${cb?.date.substring(0, 10)} ${cb?.startTime}`).format('YYYY-M-D-H-m')
                // const start = moment(`${cb?.date.substring(0, 10)} ${cb?.startTime}`).format('YYYY-M-D-H-m')

                // console.log((start.split("-")).map(x => parseInt(x)))
                // console.log((start.split("-")).map(x => Number(x)))
                // console.log((start.split("-")).map(x => +x))
                // const startTimeHours = parseInt(cb?.startTime?.slice(0, 2));
                // const startTimeMinutes = parseInt(cb?.endTime?.slice(-2));
                const abc = cb?.procedure?.map((get)=> get.caseTimeHours).reduce((partialSum, a) => +partialSum + +a)

                const event = {
                    start: (start.split("-")).map(x => parseInt(x)),
                    duration: {
                        minutes: abc*60
                    },
                    title: `${cb?.procedure?.length ? cb?.procedure?.map((val, i) => val.surgery).join(", ") : []}`,
                    description: `Surgeon: ${cb?.surgeon?.firstName + " " + cb?.surgeon?.lastName} | patient : ${cb?.patient?.firstName + " " + cb?.patient?.lastName}`,
                    location: `${cb?.facility?.name}`,
                    // url: 'http://www.bolderboulder.com/',
                    // geo: { lat: 40.0095, lon: 105.2669 },
                    // categories: ['10k races', 'Memorial Day Weekend', 'Boulder CO'],
                    // status: 'CONFIRMED',
                    // busyStatus: 'BUSY',
                    // organizer: { name: 'Admin', email: 'Race@BolderBOULDER.com' },
                    // attendees: [
                    //     { name: 'Adam Gibbons', email: 'adam@example.com', rsvp: true, partstat: 'ACCEPTED', role: 'REQ-PARTICIPANT' },
                    //     { name: 'Brittany Seaton', email: 'brittany@example2.org', dir: 'https://linkedin.com/in/brittanyseaton', role: 'OPT-PARTICIPANT' }
                    // ]
                }

                ics.createEvent(event, (error, value) => {
                    if (error) {
                        console.log(error)
                    }
                    // console.log(value)
                    // window.open( "data:text/calendar;charset=utf8," + escape( value ) );

                    var link = document.createElement("a");
                    link.href = 'data:text/calendar;charset=utf8,' + encodeURIComponent(value);
                    link.download = `${printDate + "-" + cb?.procedure?.map((val, i) => val.surgery).join(", ")}.ics`;
                    link.click();
                })
            }
            await dispatch(fetchSchedules());
            await dispatch(action);
        })
    };
};

export const checkSchedule = (data, validate) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.CHECK_SCHEDULES,
            auth: 'bearer',
            queries: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchSchedulesAction(cb));
        })
    };
};

export const fetchSchedules = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.SCHEDULES,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSchedulesAction(cb))
        })
    };
};

export const fetchWeekSchedules = () => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.WEEK_SCHEDULES,
            auth: 'bearer'
        }, (cb) => {
           if(cb?.length) {
               dispatch(fetchWeeklySchedulesAction(cb))
               dispatch(fetchWeeklyActiveSchedulesAction(cb))
           }
        })
    };
};

export const fetchFilteredSchedules = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.WEEK_SCHEDULES}filtered`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            if(cb?.length) {
                dispatch(fetchServerSiteFilteredSchedulesAction(cb))
            } else {
                dispatch(resetServerFilteredScheduleFormAction())
                Alert.warning({title: "Schedule not found!"})
            }
        })
    };
};

export const getServerFilteredMonth = (queries) => {
    return async dispatch => {
        await req.getRequest({
            url: `${Constants.WEEK_SCHEDULES}bar/filtered`,
            queries,
            auth: 'bearer'
        }, (cb) => {
            if(cb?.length) {
                dispatch(fetchServerSiteFilteredSchedulesAction(cb))
            } else {
                dispatch(resetServerFilteredScheduleFormAction())
                Alert.warning({title: "Schedule not found!"})
            }
        })
    };
};


export const fetchWeekSchedulesForSurgeons = (id) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.WEEK_SCHEDULES_FOR_SURGEONS + id,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSurgeonSchedulesAction(cb))
        })
    };
};

export const fetchSurgeonAllSchedule = (id) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.ALL_SCHEDULES_FOR_SURGEONS + id,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSurgeonAllScheduleAction(cb))
        })
    };
};

export const fetchSurgeonCancelledSchedule = (id) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.CANCELLED_SCHEDULES_FOR_SURGEONS + id,
            auth: 'bearer'
        }, (cb) => {
            dispatch(fetchSurgeonCancelledScheduleAction(cb))
        })
    };
};

export const updateSchedule = (data, action, validate) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.SCHEDULES + data._id,
            auth: 'bearer',
            body: data,
            validate: validate
        }, async (cb) => {
            await dispatch(fetchSchedules());
            await dispatch(action);
        })
    };
};

export const updateOneSchedule = (data) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.OTHER_PROCEDURE + data._id,
            auth: 'bearer',
            body: data,
        }, async (cb) => {})
    };
};

export const doctorAvailability = (day, action) => {
    return async dispatch => {
        await req.putRequest({
            url: Constants.DOCTOR_AVAILABILITY,
            auth: 'bearer',
            body: {day},
        }, async (cb) => {
            if (action) action(cb)
        })
    };
};

export const getDoctorAvailabilities = (action) => {
    return async dispatch => {
        await req.getRequest({
            url: Constants.DOCTOR_AVAILABILITY,
            auth: 'bearer'
        }, (cb) => {
          if (cb?.length)  dispatch(fetchAvailabilitiesAction(cb))
        })
    };
};

export const resetScheduleForm = () => dispatch => dispatch(resetScheduleFormAction());
export const resetServerFilteredScheduleForm = () => dispatch => dispatch(resetServerFilteredScheduleFormAction());

export const fetchSchedule = (_id, action) => {
    return async dispatch => {
        await req.getRequest({url: Constants.SCHEDULES + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchScheduleAction(cb));
            await dispatch(action);
        })
    };
};

export const getPdfInfoNVH = (_id, hospitalCode) => {
    return async dispatch => {
        await req.getRequest({url: Constants.SCHEDULES_PDF + _id, auth: 'bearer', queries:{hospitalCode}}, async (cb) => {
            await window.open(Constants.BASE_ENDPOINT + `pdf/temp-pdfs/${cb}`)
        })
    };
};

export const getHospitalInfoInPDF = (pdfOrderInfo) => {
    return async dispatch => {
        await req.getRequest({url: Constants.SCHEDULES_PDF_INFO, auth: 'bearer', queries:{_id:pdfOrderInfo._id, hospitalCode: pdfOrderInfo.hospitalCode, procedureValue: pdfOrderInfo.procedureValue}}, async (cb) => {
            if (cb) window.open(Constants.BASE_ENDPOINT + `pdf/temp-pdfs/${cb}`)
        })
    };
};

export const getPdfPatientPacket = (pdfPackets) => {
    return async dispatch => {
        await req.getRequest({url: Constants.SCHEDULES_PDF_INFO, auth: 'bearer', queries: {_id:pdfPackets._id, patientPackets: pdfPackets.patientPackets, procedureValue: pdfPackets.procedureValue}}, async (cb) => {
            if (cb) window.open(Constants.BASE_ENDPOINT + `pdf/temp-pdfs/${cb}`)
        })
    };
};

export const sendRepEmail = (_id, data) => {
    return async dispatch => {
        await req.putRequest({url: Constants.REPRESENTATIVE_EMAIL + _id, auth: 'bearer', body:data}, async (cb) => {
            await dispatch(fetchSchedules());
        })
    };
};

export const sendRepEmailId = (data) => {
    return async dispatch => {
        await req.putRequest({url: Constants.REPRESENTATIVE_EMAIL_UNIQUE, auth: 'bearer', body:data}, async (cb) => {
            await dispatch(fetchSchedules());
        })
    };
};

export const cancelTheSchedule = (_id) => {
    return async dispatch => {
        await req.putRequest({url: Constants.SCHEDULES_CANCEL + _id, auth: 'bearer'}, async (cb) => {
            await dispatch(fetchSchedules());
        })
    };
};

export const deleteSchedule = (_id, data) => {
    return async dispatch => {
        await req.putRequest({url: Constants.SCHEDULES_CANCEL + _id, auth: 'bearer', body: data}, async (cb) => {
            await dispatch(fetchSchedules());
        })
    };
};

// export const deleteSchedule = (_id, action) => {
//     return async dispatch => {
//         await req.deleteRequest({
//             url: Constants.SCHEDULES + _id,
//             auth: 'bearer'
//         }, async(cb) => {
//             dispatch(fetchSchedules(cb))
//             await dispatch(action);
//         })
//     };
// };

